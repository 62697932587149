import { VFC } from 'react';
import { useHistory } from 'react-router';
import useDialog from 'global/components/dialog/use-dialog';
import Dialog from 'global/components/dialog/Dialog';
import useNavigationBarItemsSetting from 'global/hooks/use-navigation-bar-items-setting';
import ConfirmPhoneNumberPage from 'features/forget-email/confirm-phone-number/pages/ConfirmPhoneNumberPage';
import usePhoneNumberRegistration from 'features/sign-up/phone-number/registration/hooks/use-phone-number-registration';
import { PhoneNumberRegistrationRepositoryImpl } from 'features/sign-up/phone-number/registration/data/repositories/phone-number-registration-repository';

const PhoneNumberReRegistrationContainer: VFC = () => {
  const history = useHistory();
  const { isOpen, requestShowing, onClose } = useDialog();
  useNavigationBarItemsSetting('電話番号の再登録', {
    isCloseButtonVisible: false,
    isMenuButtonVisible: false,
    isNotificationButtonVisible: false,
    isBackButtonVisible: false,
  });

  const { submitErrorMessage, submitted } = usePhoneNumberRegistration(
    new PhoneNumberRegistrationRepositoryImpl(),
    (saveResult) => {
      if (saveResult.isSuccess()) {
        history.push('/sign_up/phone_number/authentication', {
          phoneNumber: saveResult.value,
        });
      }
      if (saveResult.isFailure()) {
        requestShowing();
      }
    },
  );

  return (
    <>
      <Dialog
        title="エラー"
        description={submitErrorMessage}
        primaryButton={{ text: 'OK' }}
        isOpen={isOpen}
        onClose={onClose}
      />

      {/* これ以上style長くなりそうならcssファイルの切り出し検討する */}
      <div style={{ paddingTop: 60, paddingBottom: 150 }}>
        <ConfirmPhoneNumberPage submitted={submitted} />
      </div>
    </>
  );
};

export default PhoneNumberReRegistrationContainer;
