import { VFC } from 'react';
import styles from './ReferralCodeNoticeSectionList.module.css';

type ReferralCodeNoticeSection = {
  title: string,
  details: string[],
}

type Props = {
  sections: ReferralCodeNoticeSection[],
};

const ReferralCodeNoticeSectionList: VFC<Props> = ({
  sections
}) => (
    <>
      {sections.map(section => (
        <div className={styles.section}>
          <div className={styles.title}>
            {section.title}
          </div>
          <div className={styles.body}>
            <ul>
              {section.details.map((detail) => (
                <li>{detail}</li>
              ))}
            </ul>
          </div>
        </div>
      ))}
    </>
);

export default ReferralCodeNoticeSectionList;
