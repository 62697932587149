import { VFC } from 'react';
import { useHistory } from 'react-router';
import useNavigationBarItemsSetting from 'global/hooks/use-navigation-bar-items-setting';
import EmailAuthenticationPage from '../pages/EmailAuthenticationPage';

const EmailAuthenticationContainer: VFC = () => {
  const history = useHistory();

  useNavigationBarItemsSetting('認証メール送信の完了', {
    isCloseButtonVisible: true,
    isMenuButtonVisible: false,
    isNotificationButtonVisible: false,
    isBackButtonVisible: false,
  });

  return (
    <>
      <div style={{ paddingTop: 60, paddingBottom: 150 }}>
        <EmailAuthenticationPage
          email={(history.location.state as { email: string }).email}
        />
      </div>
    </>
  );
};

export default EmailAuthenticationContainer;
