import EntryCampaignViewData from 'features/entry-campaign/view-data/entry-campaign-view-data';
import { VFC } from 'react';
import styles from './TopEntryCampaign.module.css';

type Props = {
  entryCampaign: EntryCampaignViewData;
  onBannerTapped: () => void;
};

const TopEntryCampaign: VFC<Props> = ({ entryCampaign, onBannerTapped }) => (
  <button
    type="button"
    className={styles.bannerWrapper}
    onClick={onBannerTapped}
  >
    <img src={entryCampaign.bannerImagePath} alt={entryCampaign.title} />
  </button>
);

export default TopEntryCampaign;
