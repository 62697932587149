import { useQuery } from 'react-query';
import ReferralCodeRepository, {
  ReferralCodeRepositoryImpl,
} from 'features/refer-a-friend/data/repositories/referral-code-repository';
import { isErrorDTO } from 'data/dto/error-dto';
import Result from 'global/utilities/result';
import createResult from 'global/utilities/create-result-from-query-result';
import { GENERAL_REQUEST_ERROR_MESSAGE } from 'global/constants';
import { FriendReferralCode } from '../view-data/referral-code-view-data';
import ReferralCodeDto from '../data/dto/referral-code-dto';

type ReturnType = {
  fetchResult: Result<FriendReferralCode, Error>;
};

const useReferralCode = (
  repository: ReferralCodeRepository = new ReferralCodeRepositoryImpl(),
): ReturnType => {
  const convertReferralCodeDtoToViewData = (
    dto: ReferralCodeDto,
  ): FriendReferralCode => dto.myReferralCode;

  const queryResult = useQuery<FriendReferralCode, Error>(
    ['/refer_a_friend/referral_code'],
    async () => {
      const dto = await repository.fetch().catch((error) => {
        if (isErrorDTO(error)) {
          throw Error(error.error.message);
        }
        throw Error(GENERAL_REQUEST_ERROR_MESSAGE);
      });

      return convertReferralCodeDtoToViewData(dto);
    },
  );

  const fetchResult = createResult(queryResult);

  return {
    fetchResult,
  };
};

export default useReferralCode;
