/* eslint-disable react/jsx-props-no-spreading */
import { VFC } from 'react';
import { useHistory } from 'react-router';
import Button from 'global/components/button/Button';
import styles from './WelcomeSecondPage.module.css';

const WelcomeSecondPage: VFC = () => {
  const imageUrl = `${process.env.PUBLIC_URL}/welcome/welcome_page_image.png`;
  const history = useHistory();

  return (
    <>
      <div className={styles.container}>
        <div className={styles.contentsArea}>
          <div className={styles.title}>毎日の健康状態をスコアで表示</div>
          <div className={styles.imageContainer}>
            <div
              className={styles.imageArea}
              style={{ backgroundImage: `url(${imageUrl})`}}
            />
          </div>
          <div className={styles.description}>
            あなたの健康増進を表すスコア「ライフスタッツ」を確認して、
            それぞれのカテゴリーのバランスをチェックしましょう。
          </div>
          </div>
        <div className={styles.buttonArea}>
          <div className={styles.buttonContainer}>
            <Button
              text='スコアUPのヒント'
              type='primary'
              size='large'
              onClick={() => { history.push('/top', { isTutorial: true }); }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default WelcomeSecondPage;
