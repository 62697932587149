import { VFC, FC } from 'react';
import styles from './FormContainer.module.css';

export type FormTitleSize = 'large' | 'medium';

const FormContainer: FC = ({ children }) => (
  <div className={styles.formContainer}>{children}</div>
);

type FormTitleProps = {
  text: string;
  size?: FormTitleSize;
  color?: string;
};

const classNameForFormTitle = (size?: FormTitleSize): string => {
  switch (size) {
    case 'large':
      return styles.formTitleLarge;
    case 'medium':
      return styles.formTitleMedium;
    default:
      return styles.formTitleMedium;
  }
};

export const FormTitle: VFC<FormTitleProps> = ({ text, size, color }) => (
  <div
    style={{ color }}
    className={[styles.formTitle, classNameForFormTitle(size)].join(' ')}
  >
    {text}
  </div>
);

export const FormTitlePrimaryColor: VFC<FormTitleProps> = ({
  text,
  size,
  color,
}) => (
  <div
    style={{ color }}
    className={[styles.formTitlePrimaryColor, classNameForFormTitle(size)].join(
      ' ',
    )}
  >
    {text}
  </div>
);

export default FormContainer;
