import { VFC } from 'react';
import useNavigationBarItemsSetting from 'global/hooks/use-navigation-bar-items-setting';
import WelcomeSecondPage from '../pages/WelcomeSecondPage';

const WelcomeSecondContainer: VFC = () =>{
  useNavigationBarItemsSetting(
    { type: 'logo' },
    {
      isCloseButtonVisible: false,
      isMenuButtonVisible: false,
      isNotificationButtonVisible: false,
      isBackButtonVisible: false,
    },
  );

  return (
    <>
      <WelcomeSecondPage />
    </>
  );
};

export default WelcomeSecondContainer;
