import { VFC } from 'react';
import Button from 'global/components/button/Button';
import requestWebCommand, { CloseFitStatsView } from 'global/utilities/web-command';
import styles from './WithdrawalCompletedPage.module.css';

const WithdrawalCompletedPage: VFC = () => (
  <div className={styles.container}>
    <div className={styles.message}>退会が完了しました</div>
    <div className={styles.button}>
      <Button
        type="primary"
        size="large"
        text="OK"
        onClick={() => requestWebCommand(new CloseFitStatsView())}
      />
    </div>
  </div>
);

export default WithdrawalCompletedPage;
