import { useHistory, useLocation } from 'react-router';
import { isErrorDTO } from 'data/dto/error-dto';
import { useCallback } from 'react';
import { GENERAL_REQUEST_ERROR_MESSAGE } from 'global/constants';
import IntroductionProgressRepository, {
  IntroductionProgressRepositoryImpl,
} from '../data/repositories/introduction-progress-repository';

type ReturnType = {
  redirectToRecoveryPoint: (
    doIfFinished: boolean,
    authorizationCode?: string,
    afterAuthorization?: boolean,
  ) => void;
};

export const INTRODUCTION_PROGRESS_QUERY_KEY =
  '/users/me/introduction_progress';

// FIXME: このhookは進行度合いをもとに「何か」をさせるべきだと思うが
// 現状リダイレクトのためだけに存在している。
const useIntroductionProgress = (
  repository: IntroductionProgressRepository = new IntroductionProgressRepositoryImpl(),
): ReturnType => {
  const history = useHistory();
  const location = useLocation();

  const redirectToRecoveryPoint = useCallback(
    (doIfFinished: boolean, authorizationCode?: string) => {
      if (
        process.env.NODE_ENV === 'development' &&
        history.location.pathname === '/debug'
      ) {
        return;
      }

      // 新規登録フローでpartners→fitstatsに戻ってきたときは途中復帰を行わない
      // 既に進捗がある=過去に作成したユーザーに切り替わっている、なので、ここではなく
      // useUserSwitchCheckで専用の処理を行う
      if (location.pathname === '/oauth/token') {
        return;
      }

      repository
        .fetch()
        .then((dto) => {
          switch (dto.state) {
            case 'no_progress':
              if (authorizationCode) {
                history.push({ pathname: '/oauth/token', search: `?code=${authorizationCode}`});
              } else {
                history.push({ pathname: '/' });
              }
              break;
            case 'email_verified':
              history.push('/sign_up/phone_number/registration');
              break;
            case 'registered':
              history.push('/sign_up/profile/new');
              break;
            case 'profile_saved':
              history.push('/top', { isTutorial: true });
              break;
            case 'tutorial_finished':
              if (doIfFinished) {
                history.push('/top');
              }
              break;
            default:
              break;
          }
        })
        .catch((error) => {
          if (isErrorDTO(error)) {
            throw Error(error.error.message);
          }
          throw Error(GENERAL_REQUEST_ERROR_MESSAGE);
        });
    },
    // historyは毎描画で生成されるため、無限ループ化を防ぐ
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return {
    redirectToRecoveryPoint,
  };
};

export default useIntroductionProgress;
