import Result from 'global/utilities/result';
import { GENERAL_REQUEST_ERROR_MESSAGE } from 'global/constants';
import createResult from 'global/utilities/create-result-from-query-result';
import { isErrorDTO } from 'data/dto/error-dto';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router';
import { EntryCampaignRepositoryImpl } from '../data/repositories/entry-campaign-repository';
import EntryCampaignViewData from '../view-data/entry-campaign-view-data';
import EntryCampaignDto from '../data/dto/entry-campaign-dto';

type ReturnType = {
  fetchResult: Result<EntryCampaignViewData[] | null, Error>;
  entryCampaignBannerTapped: (code: string) => () => void;
};

const convertToViewData = (dto: EntryCampaignDto): EntryCampaignViewData => dto;

const useEntryCampaigns = (): ReturnType => {
  const history = useHistory();
  const repository = new EntryCampaignRepositoryImpl();
  const queryResult = useQuery<EntryCampaignViewData[] | null, Error>(
    ['/entry_campaigns'],
    async () => {
      const dto = await repository.fetch().catch((error) => {
        if (isErrorDTO(error)) {
          throw Error(error.error.message);
        }
        throw Error(GENERAL_REQUEST_ERROR_MESSAGE);
      });

      // 空responseの場合はエントリーキャンペーンが存在しないものとみなす
      if (dto.entryCampaigns === undefined) {
        return null;
      }

      return dto.entryCampaigns.map( (entryCampaign) => convertToViewData(entryCampaign));
    },
  );

  const fetchResult = createResult(queryResult);

  const entryCampaignBannerTapped = (code: string) => () => {
      history.push(`/entry_campaign/${code}`);
    };

  return {
    fetchResult,
    entryCampaignBannerTapped,
  };
};

export default useEntryCampaigns;
