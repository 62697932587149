import OpenExternalWeb from 'global/components/open-external-web/OpenExternalWeb';
import { Divider } from '@chakra-ui/react';
import { useEffect, VFC } from 'react';
import { TutorialLocationState } from 'features/tutorial/hooks/use-tutorial-navigation';
import ClientCompanyDetailViewData from '../view-data/client-company-detail-view-data';
import styles from './ClientCompanyDetailPage.module.css';

type FieldProps = {
  title: string;
  value: string;
};

const FieldContainer: VFC<FieldProps> = ({ title, value }) => (
  <>
    <div className={styles.fieldContainer}>
      <div className={styles.fieldName}>{title}</div>
      <div className={styles.fieldBody}>{value}</div>
    </div>
  </>
);

type Props = {
  clientCompany: ClientCompanyDetailViewData;
  locationState?: TutorialLocationState | undefined;
};

const ClientCompanyDetailPage: VFC<Props> = ({
  clientCompany,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  locationState,
}) => {
  // ページが描画されたら先頭までスクロール
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <img src={clientCompany.companyImageUrl} alt="" />
      <div className={styles.companyName}>{clientCompany.companyName}</div>

      <FieldContainer
        title="データ利用目的と提供する便益"
        value={clientCompany.majorBrands}
      />
      <div className={styles.dividerWrapper}>
        <Divider orientation="horizontal" />
      </div>

      <FieldContainer
        title="上場区分"
        value={clientCompany.listedClassification}
      />
      <div className={styles.dividerWrapper}>
        <Divider orientation="horizontal" />
      </div>

      <FieldContainer title="業種" value={clientCompany.industry} />
      <div className={styles.dividerWrapper}>
        <Divider orientation="horizontal" />
      </div>

      <FieldContainer
        title="プライバシーマーク"
        value={clientCompany.privacyMark ? '有' : '無'}
      />
      <div className={styles.dividerWrapper}>
        <Divider orientation="horizontal" />
      </div>

      <FieldContainer
        title="ISMS/ISO(情報セキュリティ)認証"
        value={clientCompany.ismsIsoCertification ? '有' : '無'}
      />
      <div className={styles.dividerWrapper}>
        <Divider orientation="horizontal" />
      </div>

      <FieldContainer
        title="企業説明"
        value={clientCompany.companyIntroduction}
      />

      <div className={styles.dividerWrapper}>
        <Divider orientation="horizontal" />
      </div>

      <div className={styles.externalWebContainer}>
        <div className={styles.externalWeb}>
          <OpenExternalWeb url={clientCompany.companyHomepageUrl}>
            公式サイト
          </OpenExternalWeb>
        </div>
        <div className={styles.externalWeb}>
          <OpenExternalWeb url={clientCompany.privacyPolicyUrl}>
            プライバシーポリシー
          </OpenExternalWeb>
        </div>
      </div>
    </>
  );
};

export default ClientCompanyDetailPage;
