import { ProfileRepositoryImpl } from 'features/profile/data/repositories/profile-repository';
import Dialog from 'global/components/dialog/Dialog';
import useDialog from 'global/components/dialog/use-dialog';
import Loading from 'global/components/Loading/Loading';
import useNavigationBarItemsSetting from 'global/hooks/use-navigation-bar-items-setting';
import { VFC } from 'react';
import ErrorContainer from 'global/components/ErrorContainer/ErrorContainer';
import requestWebCommand, {
  OpenExternalWeb,
} from 'global/utilities/web-command';
import { reveiveFitStatsPointPage } from 'features/questionnaire/containers/show';
import { PersonalDataRepositoryImpl } from '../data/repositories/personal-data-repository';
import usePersonalDataEdit from '../hooks/use-personal-data-profile-edit';
import PersonalDataEditPage from '../pages/PersonalDataEditPage';
import { PopupInfoDetailViewData } from '../../../profile/view-data/popup-info-view-data';

const PersonalDataEditContainer: VFC = () => {
  const { isOpen, dialogProps, requestShowing, onClose } = useDialog();
  useNavigationBarItemsSetting('基本データ');

  let popupInfo: PopupInfoDetailViewData[] = [];

  const showPopup = (setPopupInfoCount: (count: number) => void): void => {
    const info = popupInfo.pop();
    if (info?.title && info?.description) {
      requestShowing({
        title: info.title,
        description: info.description,
        primaryButton: {
          text: 'OK',
          onClick: () =>
            popupInfo.length === 0
              ? setTimeout(() => {
                  setPopupInfoCount(popupInfo.length);
                  requestShowing();
                }, 100)
              : setTimeout(() => {
                  setPopupInfoCount(popupInfo.length);
                  showPopup(setPopupInfoCount);
                }, 100),
        },
      });
    }
  };

  const {
    fetchResult,
    submitAction,
    submitted,
    storageData,
    closeButtonTapped,
    popupInfoCount,
    setPopupInfoCount,
  } = usePersonalDataEdit(
    new PersonalDataRepositoryImpl(),
    new ProfileRepositoryImpl(),
    (saveResult) => {
      if (saveResult.isSuccess()) {
        popupInfo = saveResult.value.popupInfo;
        if (popupInfo.length !== 0) {
          showPopup(setPopupInfoCount);
        } else {
          requestShowing();
        }
      }
      if (saveResult.isFailure()) {
        requestShowing();
      }
    },
  );

  if (fetchResult.isSuccess()) {
    return (
      <>
        {/* eslint-disable-next-line no-nested-ternary */}
        {popupInfoCount === 0 ? (
          <Dialog
            title={submitAction?.title}
            description={submitAction?.description}
            primaryButton={{
              text: submitAction?.primaryButtonText || 'OK',
              onClick: submitAction?.primaryButtonAction,
            }}
            isOpen={isOpen}
            onClose={onClose}
          />
        ) : (
          <Dialog
            title={dialogProps?.title}
            description={dialogProps?.description}
            imageSrc="/finc_landing_page/content_2-1.png"
            imageSize="large"
            primaryButton={{
              text: '今すぐチェック！',
              onClick: () => {
                requestWebCommand(
                  new OpenExternalWeb(reveiveFitStatsPointPage),
                );
              },
            }}
            isOpen={isOpen}
            onClose={onClose}
          />
        )}
        <PersonalDataEditPage
          result={fetchResult.value}
          submitted={submitted}
          storageData={storageData}
          closeButtonTapped={closeButtonTapped}
        />
      </>
    );
  }

  if (fetchResult.isFailure()) {
    return <ErrorContainer>{fetchResult.error.message}</ErrorContainer>;
  }

  return <Loading />;
};

export default PersonalDataEditContainer;
