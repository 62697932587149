import Result from "global/utilities/result";
import { useQuery } from "react-query";
import { isErrorDTO } from "data/dto/error-dto";
import { GENERAL_REQUEST_ERROR_MESSAGE } from "global/constants";
import createResult from "global/utilities/create-result-from-query-result";
import FitstatsPointDto from "../data/dto/fitstats-point-dto";
import FitstatsPointRepository, { FitstatsPointRepositoryImpl } from "../data/repositories/fitstats-point-repository";
import FitstatsPointViewData from "../view-data/fitstats-point-view-data";

type ReturnType = {
  fitstatsPointResult: Result<FitstatsPointViewData, Error>;
};

const convertDtoToViewData = (dto: FitstatsPointDto): FitstatsPointViewData =>
  dto;

const useFitstatsPoint = (
  repository: FitstatsPointRepository = new FitstatsPointRepositoryImpl(),
): ReturnType => {
  const fetchFitstatsPoint = useQuery<FitstatsPointViewData, Error>(
    ['/users/me/fit_stats_point/reports'],
    async () => {
      const dto = await repository.fetch().catch((error) => {
        if (isErrorDTO(error)) {
          throw Error(error.error.message);
        }
        throw Error(GENERAL_REQUEST_ERROR_MESSAGE);
      });

      return convertDtoToViewData(dto);
    },
  );

  const fitstatsPointResult = createResult(fetchFitstatsPoint);

  return {
    fitstatsPointResult,
  };
};

export default useFitstatsPoint;
