import { VFC, useState } from 'react';
import useDialog from 'global/components/dialog/use-dialog';
import Loading from 'global/components/Loading/Loading';
import Dialog from 'global/components/dialog/Dialog';
import TermsOfUseAndPrivacyPolicyPage from 'global/pages/terms-of-use-and-privacy-policy/TermsOfUseAndPrivacyPolicyPage';
import { useHistory } from 'react-router';
import useNavigationBarItemsSetting from 'global/hooks/use-navigation-bar-items-setting';
import ErrorContainer from 'global/components/ErrorContainer/ErrorContainer';
import usePrivacyPolicy from '../hooks/use-privacy-policy';
import { PrivacyPolicyRepositoryImpl } from '../data/repositories/privacy-policy-repository';

const PrivacyPolicyContainer: VFC = () => {
  const history = useHistory();
  const { isOpen, requestShowing, onClose } = useDialog();
  const [isDisabled, setIsDisabled] = useState(false);
  useNavigationBarItemsSetting('個人情報の取り扱いについて', {
    isCloseButtonVisible: true,
    isMenuButtonVisible: false,
    isNotificationButtonVisible: false,
    isBackButtonVisible: false,
  });

  const { fetchResult, submitErrorMessage, submitted } = usePrivacyPolicy(
    new PrivacyPolicyRepositoryImpl(),
    (saveResult) => {
      if (saveResult.isSuccess()) {
        history.push('/top');
      }
      if (saveResult.isFailure()) {
        requestShowing();
      }
      setIsDisabled(false);
    },
  );

  if (fetchResult.isSuccess()) {
    return (
      <>
        <Dialog
          title="エラー"
          description={submitErrorMessage}
          primaryButton={{ text: 'OK' }}
          isOpen={isOpen}
          onClose={onClose}
        />

        <TermsOfUseAndPrivacyPolicyPage
          name="個人情報の取り扱い"
          content={fetchResult.value.content}
          submitted={submitted}
          isDisabled={isDisabled}
          setIsDisabled={setIsDisabled}
        />
      </>
    );
  }

  if (fetchResult.isFailure())
    return <ErrorContainer>{fetchResult.error.message}</ErrorContainer>;

  return <Loading />;
};

export default PrivacyPolicyContainer;
