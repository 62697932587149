import { VFC } from 'react';
import { Switch, Route } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import EntryCampaignDetailContainer from './containers/show';

const EntryCampaignRouter: VFC<RouteComponentProps> = ({ match }) => (
  <Switch>
    <Route path={`${match.path}/:entryCampaignCode`}>
      <EntryCampaignDetailContainer />
    </Route>
  </Switch>
);

export default EntryCampaignRouter;
