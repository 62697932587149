import { Flex } from '@chakra-ui/react';
import { FC } from 'react';
import styles from './PopOver.module.css';

export type Props = {
  enabled: boolean;
  onClick: () => void;
  buttonText?: string;
  direction?: 'upward' | 'downward'
  beforeLeft?: number | string;
};

const PopOver: FC<Props> = ({ children, enabled, onClick, buttonText, direction = 'upward', beforeLeft = '50%'}) =>
  enabled ? (
    <div
      className={`${styles.popOverContainer} ${direction === 'downward' ? styles.downward : ''}`}
      style={
        typeof beforeLeft === 'number'
          ? { '--before-left': `${beforeLeft}px` } as React.CSSProperties
          : {}
      }
    >
      <Flex alignItems="flex-start" justifyContent="center">
        <div className={styles.popOverIcon} />
        <div className={styles.popOverDescription}>
          {children}
          <div className={styles.popOverButtonWrapper}>
            <button
              type="button"
              className={styles.popOverButton}
              onClick={onClick}
            >
              {buttonText || 'OK'}
            </button>
          </div>
        </div>
      </Flex>
    </div>
  ) : (
    <></>
  );
export default PopOver;
