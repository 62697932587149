import { useCallback, useRef } from 'react';
import { DialogButton } from 'global/components/dialog/Dialog';
import { isErrorDTO } from 'data/dto/error-dto';
import { useHistory } from 'react-router';
import RegistrationCompleteRepository, {
  RegistrationCompleteRepositoryImpl
} from 'features/tutorial/data/repositories/registration-complete-repository';

type ReturnType = {
  pointEventFailedAction: DialogActionType | undefined;
  pointEventSuccessedAction: SuccessedDialogActionType | undefined;
  startTutorial: (
    openTutorialDialog: OpenTutorialDialog,
    openErrorDialog: OpenErrorDialog,
  ) => void;
};

type DialogActionType = {
  title: string;
  description: string;
  primaryButton: DialogButton;
  onClose: () => void;
};

type SuccessedDialogActionType = {
  title: string;
  description: string;
};

type OpenTutorialDialog = () => void;
type OpenErrorDialog = () => void;

const registrationCompleteRepository: RegistrationCompleteRepository =
  new RegistrationCompleteRepositoryImpl();

const useTopTutorial = (): ReturnType => {
  const history = useHistory();

  const pointEventFailedAction =
    useRef<DialogActionType | undefined>(undefined);

  const pointEventSuccessedAction =
    useRef<SuccessedDialogActionType | undefined>(undefined);

  const startTutorial = useCallback(
    (
      openTutorialDialog: OpenTutorialDialog,
      openErrorDialog: OpenErrorDialog,
    ) => {
      registrationCompleteRepository
        .createEvent()
        .then((dto) => {
          pointEventSuccessedAction.current = {
            title: `${dto.amount}pt 獲得!`,
            description: "新規登録が完了しましたので、\nFitStatsポイントを獲得しました。",
          };

          openTutorialDialog();
        })
        .catch((error) => {
          if (isErrorDTO(error) && error.error.status === 'ALREADY_EXISTS') {
            pointEventSuccessedAction.current = {
              title: 'FitStatsポイント獲得！',
              description:
                '新規登録が完了しましたので、FitStatsポイントを獲得済です。',
            };

            openTutorialDialog();

            return;
          }

          pointEventFailedAction.current = {
            title: 'エラー',
            description:
              '新規登録のプレゼントが受け取れませんでした。恐れ入りますが再度お試しください。',
            primaryButton: {
              text: 'OK',
              onClick: () => history.go(0), // reload
            },
            onClose: () => history.go(0),
          };

          openErrorDialog();
        });
    },
    [history],
  );

  return {
    pointEventFailedAction: pointEventFailedAction.current,
    pointEventSuccessedAction: pointEventSuccessedAction.current,
    startTutorial,
  };
};

export default useTopTutorial;
