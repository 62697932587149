import { useCallback, useEffect, useRef, useState, VFC } from 'react';
import Button from 'global/components/button/Button';
import Container from 'global/components/Container/Container';
import { useForm } from 'react-hook-form';
import AttentionContainer from 'global/components/AttentionContainer/AttentionContainer';
import SquareCheckboxWithLabel from 'global/components/square-checkbox-with-label/SquareCheckboxWithLabel';

import {
  defaultParentalConsentStatus,
  isNeedParentalConsent,
} from 'features/profile/utils/function-for-profile-view-data';
import TopContainer from 'global/components/TopContainer/TopContainer';
import ProgressBar from 'features/sign-up/components/ProgressBar/ProgressBar';
import Dialog from 'global/components/dialog/Dialog';
import useDialog from 'global/components/dialog/use-dialog';
import RegistrationPage from 'features/sign-up/email/registration/pages/EmailRegistrationPage';
import PasswordPage from 'features/sign-up/password/pages/PasswordPage';
import PhoneNumberFormPage from 'features/sign-up/phone-number/registration/pages/PhoneNumberFormPage';
import { Link } from 'react-router-dom';
import ReferralCodePage from 'features/sign-up/referral-code/pages/ReferralCodePage';
import { ProfileSinglePageViewData } from '../../../profile/view-data/profile-view-data';
import styles from './ProfilePage.module.css';
import agreementStyles from '../../agreement/pages/AgreementPage.module.css';
import { InputValue, UserInputRawData } from '../../../profile/user-input-item';
import ProfileNew from '../components/ProfileNew';

type Props = {
  result: ProfileSinglePageViewData;
  submitted: (userInput: UserInputRawData) => void;
  termsOfUseDocVersion: string | undefined;
  privacyPolicyDocVersion: string | undefined;
};

type Profile = {
  sex: InputValue;
  year: InputValue;
  month: InputValue;
  day: InputValue;
  birthday: InputValue;
  prefecture: InputValue;
};

type Account = {
  email: InputValue;
  password: InputValue;
  phoneNumber: InputValue;
  profile: Profile;
  privacyPolicyVersion: InputValue;
  termsOfUseVersion: InputValue;
  referralCode: InputValue | undefined;
};

const ProfileBasicPage: VFC<Props> = ({
  result,
  submitted,
  termsOfUseDocVersion,
  privacyPolicyDocVersion,
}) => {
  const { isOpen, requestShowing, onClose } = useDialog();
  const pageTopRef = useRef<HTMLDivElement>(null);
  const scrollToTop = useCallback(() => {
    pageTopRef.current?.scrollIntoView({
      behavior: 'auto',
      block: 'start',
    });
  }, []);

  // ページが描画されたら先頭までスクロール
  useEffect(() => {
    scrollToTop();
  }, [scrollToTop]);

  // プロフィールの入力フォーム
  const {
    register,
    watch,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    setError,
    clearErrors,
    getValues,
  } = useForm<UserInputRawData>({
    mode: 'onBlur',
    defaultValues: result.userInputItems?.reduce(
      (obj, item) => ({ ...obj, [item.name]: item.value }),
      {},
    ),
  });

  const [hasParentalConsent, setParentalConsent] = useState(
    defaultParentalConsentStatus(result.userInputItems),
  );

  const [currentAge, setAge] = useState(0);

  const ageChanged = (age: number): void => {
    setAge(age);
  };

  const isBirthdayValid = (): boolean => {
    if (watch('year') && watch('month') && watch('day') && !errors.birthday) {
      return true;
    }

    return false;
  };

  const [termsOfUseChecked, setTermsOfUseChecked] = useState(false);
  const [privacyPolicyChecked, setPrivacyPolicyChecked] = useState(false);

  const [errorMessage, setErrorMessage] = useState('');

  const handleClick = () => {
    if (termsOfUseChecked && privacyPolicyChecked) {
      setTermsOfUseChecked(false);
      setPrivacyPolicyChecked(false);
      setErrorMessage('');
    } else {
      setErrorMessage('利用規約と個人情報の取り扱いについてに同意してください');
    }
  };

  // SMS認証完了時にDNP基盤に登録およびアカウント登録画面以降で
  // 離脱した場合の復元対応のため入力内容をlocalStorageで保持する
  // TODO: Page内で定義せずにContainerに定義する
  const saveAccount = (): void => {
    // TODO: AccountをexportしてlocalStorageのキー名を安全に使いまわせるようにする。リファクタリング。
    const account: Account = {
      email: getValues('email'),
      password: getValues('password'),
      phoneNumber: getValues('phoneNumber'),
      profile: {
        sex: getValues('sex'),
        year: getValues('year'),
        month: getValues('month'),
        day: getValues('day'),
        birthday: `${getValues('year') as string}-${
          getValues('month') as string
        }-${getValues('day') as string}`,
        prefecture: getValues('prefecture'),
      },
      termsOfUseVersion: termsOfUseDocVersion,
      privacyPolicyVersion: privacyPolicyDocVersion,
      referralCode: getValues('referralCode'),
    };
    const json: string = JSON.stringify(account);

    localStorage.setItem('input_account', json);
  };

  const fetchAccount = useCallback(() => {
    const json: string | null = localStorage.getItem('input_account');

    const account = json !== null ? (JSON.parse(json) as Account) : null;

    return account;
  }, []);

  const onSetValue = useCallback(
    (account: Account) => {
      if (account !== null) {
        setValue('sex', account.profile.sex);
        setValue('year', account.profile.year);
        setValue('month', account.profile.month);
        setValue('day', account.profile.day);
        setValue('prefecture', account.profile.prefecture);
        setValue('email', account.email);
        setValue('phoneNumber', account.phoneNumber);
        setValue('referralCode', account.referralCode);
      }
    },
    [setValue],
  );

  const recoveryForm = useCallback(() => {
    const account = fetchAccount();
    if (account !== null) {
      setTimeout(() => {
        onSetValue(account);
      }, 1000);
      setTermsOfUseChecked(false);
      setPrivacyPolicyChecked(false);
    }
  }, [fetchAccount, onSetValue, setPrivacyPolicyChecked, setTermsOfUseChecked]);

  // 画面遷移時にlocalStorageにキーが存在する場合、各フォームに保存内容を復元する
  useEffect(() => {
    recoveryForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // 規約リンクから戻る場合も入力内容を復元する
  window.onpopstate = () => {
    recoveryForm();
  };

  return (
    <>
      <TopContainer>
        <ProgressBar max={2} value={1} />
      </TopContainer>
      <div className={styles.profilePageWrapper} ref={pageTopRef}>
        <form>
          <Container marginTop="m" marginBottom="m">
            <div className={styles.profilePageDescription}>
              FitStatsは大日本印刷が運営する健康サービス(無料)です。
            </div>
            <h4 className={styles.sectionHeader}>FitStats会員情報の登録</h4>
          </Container>
          <ProfileNew
            inputProfileItems={result}
            register={register}
            errors={errors}
            watch={watch}
            ageChanged={ageChanged}
            setValue={setValue}
            setError={setError}
            clearErrors={clearErrors}
          />
          {isBirthdayValid() && isNeedParentalConsent(currentAge) && (
            <Container marginTop="m" marginBottom="m">
              <AttentionContainer>
                <Container marginLeft={6}>
                  <div className={styles.profilePageAttentionDescription}>
                    FitStatsでは、お客様の個人情報を取り扱うため、16歳未満の方は親権者の同意が必要になります。
                  </div>
                </Container>
                <Container marginTop={10}>
                  <div className={agreementStyles.checkbox}>
                    <div>
                      <SquareCheckboxWithLabel
                        name="parental_consent"
                        value="consented"
                        label=""
                        checked={hasParentalConsent}
                        onClick={() => setParentalConsent(!hasParentalConsent)}
                      />
                    </div>
                    <div className={styles.profilePageAttentionLabel}>
                      親権者の同意を得ている
                    </div>
                  </div>
                </Container>
              </AttentionContainer>
            </Container>
          )}

          <Container marginTop="m" marginBottom="m">
            <RegistrationPage register={register} errors={errors} />
          </Container>

          <Container marginTop="m" marginBottom="m">
            <PasswordPage
              register={register}
              errors={errors}
              getValues={getValues}
              setError={setError}
              clearErrors={clearErrors}
            />
          </Container>

          <Container marginTop="m" marginBottom="m">
            <PhoneNumberFormPage register={register} errors={errors} />
          </Container>

          {localStorage.getItem('refer_a_friend') === 'true' && (
            <Container marginTop="m" marginBottom="m">
              <ReferralCodePage
                register={register}
                errors={errors}
                setValue={setValue}
              />
            </Container>
          )}

          <Container marginTop="m" marginBottom="m">
            <Container>
              <Container marginTop={10} marginBottom={10}>
                <div className={agreementStyles.checkbox}>
                  <div>
                    <SquareCheckboxWithLabel
                      name="checkbox"
                      value=""
                      label=""
                      checked={termsOfUseChecked}
                      onClick={() => setTermsOfUseChecked(!termsOfUseChecked)}
                    />
                  </div>
                  <div className={styles.profilePageAttentionLabel}>
                    <Link
                      to="/sign_up/agreement/terms_of_use"
                      onClick={() => saveAccount()}
                    >
                      利用規約
                    </Link>
                    に同意する
                  </div>
                </div>
              </Container>
              <Container marginTop={10} marginBottom={10}>
                <div className={agreementStyles.checkbox}>
                  <div>
                    <SquareCheckboxWithLabel
                      name="checkbox"
                      value=""
                      label=""
                      checked={privacyPolicyChecked}
                      onClick={() =>
                        setPrivacyPolicyChecked(!privacyPolicyChecked)
                      }
                    />
                  </div>
                  <div className={styles.profilePageAttentionLabel}>
                    <Link
                      to="/sign_up/agreement/privacy_policy"
                      onClick={() => saveAccount()}
                    >
                      個人情報の取り扱いについて
                    </Link>
                    に同意する
                  </div>
                </div>
              </Container>
              <Container
                marginTop={0}
                marginBottom={0}
                marginLeft="s"
                marginRight="s"
              >
                {(!termsOfUseChecked || !privacyPolicyChecked) && (
                  <p className={agreementStyles.errorMessage}>{errorMessage}</p>
                )}
              </Container>
            </Container>
          </Container>

          <Container marginTop="m">
            <AttentionContainer>
              <div className={styles.attentionText}>
                ・パスワードは第三者による不正ログイン防止のため、他社サービスでご利用のパスワードとは異なる文字列を推奨いたします。
                <br />
                ・本人確認のため会員情報入力後、上記メールアドレスに認証メールと携帯番号へSMSで認証コードをお送りいたします。
              </div>
            </AttentionContainer>
          </Container>
        </form>
        <Container marginTop="m" marginBottom="m">
          <Button
            text="入力して次へ"
            type="primary"
            size="medium"
            disabledType="dark"
            disabled={
              !isValid ||
              (isNeedParentalConsent(currentAge)
                ? !hasParentalConsent
                : false) ||
              !termsOfUseChecked ||
              !privacyPolicyChecked
            }
            onClick={() => {
              handleClick();
              saveAccount();
              requestShowing();
            }}
          />
        </Container>
      </div>
      <Dialog
        title="登録したメールアドレスに認証メールを送信します"
        description={
          '本人確認のため、登録メールアドレスに認証メールを送信します。メールに記載されている確認用URLをタップして、FitStatsの登録を進めてください。\n\n​送信後しばらくしてメールが届かない場合は、再度この画面でメールアドレスを入力してください。'
        }
        primaryButton={{
          text: '送信する',
          onClick: handleSubmit(submitted),
        }}
        isOpen={isOpen}
        onClose={onClose}
      />
    </>
  );
};

export default ProfileBasicPage;
