import ApiClient from 'data/api-client/api-client';
import RequestConfig, { URL } from 'data/request-configs/request-config';

const deleteRequestConfig = (
  callReason?: string,
): RequestConfig => ({
  endpoint: {
    baseUrl: URL.FIT_STATS,
    path: '/users/me/introduction_progress',
  },
  method: 'delete',
  parameters: {
    call_reason: callReason,
  },
});

interface DeleteIntroductionProgressRepository {
  delete: (callReason?: string) => Promise<void>;
}

export class DeleteIntroductionProgressRepositoryImpl
  implements DeleteIntroductionProgressRepository
{
  delete = async (
    callReason?: string,
  ): Promise<void> =>
    new ApiClient().connect(deleteRequestConfig(callReason));
}

export default DeleteIntroductionProgressRepositoryImpl;
