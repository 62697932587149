import { VFC } from 'react';
import styles from './ReferralCodeStepList.module.css';

type Step = {
  instruction: string,
  image: string,
  alt: string,
}

type Props = {
  steps: Step[],
};

const ReferralCodeStepList: VFC<Props> = ({
  steps
}) => (
    <>
      <div className={styles.stepList}>
      {steps.map((step, index) => (
        <div className={styles.step}>
          <div className={styles.title}>
            Step {index + 1}
          </div>
          <div className={styles.instruction}>
            {step.instruction}
          </div>
          <img className={styles.image}
            src={step.image}
            alt={step.alt}
          />
        </div>
      ))}
      </div>
    </>
);

export default ReferralCodeStepList;
