import { useQuery } from 'react-query';
import { isErrorDTO } from 'data/dto/error-dto';
import Result from 'global/utilities/result';
import createResult from 'global/utilities/create-result-from-query-result';
import { GENERAL_REQUEST_ERROR_MESSAGE } from 'global/constants';
import SelfCheckRepository, {
  SelfCheckRepositoryImpl,
} from '../data/repositories/self-check-repository';
import SelfCheckViewData from '../view-data/self-check-view-data';
import SelfCheckDto from '../data/dto/self-check-dto';

type ReturnType = {
  fetchResult: Result<SelfCheckViewData, Error>;
};

const useSelfCheck = (
  repository: SelfCheckRepository = new SelfCheckRepositoryImpl(),
): ReturnType => {
  const convertQuestionnaireTopDtoToViewData = (
    dto: SelfCheckDto,
  ): SelfCheckViewData => ({
    categories: dto.categories.map((category) => ({
      ...category,
      answeredQuestionsCount:
        category.totalQuestionsCount - category.unansweredQuestionsCount,
    })),
  });

  const queryResult = useQuery<SelfCheckViewData, Error>(
    ['/questionnaires'],
    async () => {
      const dto = await repository.fetch().catch((error) => {
        if (isErrorDTO(error)) {
          throw Error(error.error.message);
        }
        throw Error(GENERAL_REQUEST_ERROR_MESSAGE);
      });

      return convertQuestionnaireTopDtoToViewData(dto);
    },
  );

  const fetchResult = createResult(queryResult);

  return {
    fetchResult,
  };
};

export default useSelfCheck;
