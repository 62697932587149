import { useCallback, useState, useRef } from 'react';
import { DialogButton, ImageSize } from './Dialog';

type UseDialogReturn = {
  isOpen: boolean;
  dialogProps?: DialogProps;
  requestShowing: (dialogProps?: DialogProps) => void;
  onClose: () => void;
};

type DialogProps = {
  title?: string;
  description?: string;
  imageSrc?: string;
  imageSize?: ImageSize;
  primaryButton: DialogButton;
  destructiveButton?: DialogButton;
  showCloseButton?: boolean;
  closeOnOverlayClick?: boolean;
  allowedOnClose?: boolean;
  alignDescriptionLeft?: boolean;
};

const useDialog = (): UseDialogReturn => {
  const [isOpen, setIsOpen] = useState(false);
  const dialogPropsRef = useRef<DialogProps | undefined>();

  const requestShowing = useCallback((dialogProps?: DialogProps) => {
    dialogPropsRef.current = dialogProps;
    setIsOpen(true);
  }, []);

  const onClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  return {
    isOpen,
    dialogProps: dialogPropsRef.current,
    requestShowing,
    onClose,
  };
};

export default useDialog;
