export class Success<T, E> {
  constructor(readonly value: T) {
    this.value = value;
  }

  isSuccess = (): this is Success<T, E> => true;
  isFailure = (): this is Failure<T, E> => false;
  isInProgress = (): this is InProgress<T, E> => false;

  getValue = (): T | null => this.value;
}

export class Failure<T, E> {
  constructor(readonly error: E) {
    this.error = error;
  }

  isSuccess = (): this is Success<T, E> => false;
  isFailure = (): this is Failure<T, E> => true;
  isInProgress = (): this is InProgress<T, E> => false;

  getValue = (): T | null => null;
}

export class InProgress<T, E> {
  isSuccess = (): this is Success<T, E> => false;
  isFailure = (): this is Failure<T, E> => false;
  isInProgress = (): this is InProgress<T, E> => true;

  getValue = (): T | null => null;
}

type Result<T, E> = Success<T, E> | Failure<T, E> | InProgress<T, E>;

export default Result;
