import { VFC, useEffect } from 'react';
import { useLocation } from 'react-router';
import queryString from 'query-string';
import { URL } from 'data/request-configs/request-config';

const EmailRegistrationAuth : VFC = () => {
  const { search } = useLocation();
  const qs = queryString.parse(search);
  const emailAuthCode = qs.emailAuthCode as string;
  const uuidHash = qs.uuidHash as string;

  useEffect(() => {
    window.location.assign(`${URL.FIT_STATS}/users/registration/email/auth?emailAuthCode=${emailAuthCode}&uuidHash=${uuidHash}`);
  });

  return <></>;
};

export default EmailRegistrationAuth;
