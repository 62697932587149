import Container from 'global/components/Container/Container';
import FormContainer, {
  FormTitle,
} from 'global/components/FormContainer/FormContainer';
import InputPassword, {
  CHARACTER_TYPES,
  PASSWORD_MESSAGES,
  PASSWORD_PATTERN,
} from 'global/components/InputPassword/InputPassword';
import { useRef, VFC } from 'react';
import {
  FieldValues,
  FieldErrors,
  UseFormRegister,
  UseFormGetValues,
  UseFormSetError,
  UseFormClearErrors,
} from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import ErrorDTO from 'data/dto/error-dto';
import useDialog from 'global/components/dialog/use-dialog';
import Dialog from 'global/components/dialog/Dialog';
import PasswordValidityCheckRepository, {
  PasswordValidityCheckRepositoryImpl,
} from 'global/data/repositories/password-validity-check-repository';
import styles from './PasswordPage.module.css';

type Props = {
  register: UseFormRegister<FieldValues>;
  errors: FieldErrors<FieldValues>;
  getValues: UseFormGetValues<FieldValues>;
  setError: UseFormSetError<FieldValues>;
  clearErrors: UseFormClearErrors<FieldValues>;
};

type ValidityCheckedPassword = {
  value: string;
  available: boolean;
};

const PasswordPage: VFC<Props> = ({
  register,
  errors,
  getValues,
  setError,
  clearErrors,
}) => {
  const { isOpen, requestShowing, onClose } = useDialog();
  const checkedPassword = useRef<ValidityCheckedPassword>({
    value: '',
    available: false,
  });

  return (
    <>
      <div>
        <Container marginTop="m">
          <FormContainer>
            {[
              <FormTitle size="large" text="パスワード" />,
              <InputPassword
                name="password"
                size="small"
                placeholder="8桁以上、大小英数記号から2種類以上"
                visibleButtonNoColor={false}
                register={register('password', {
                  required: PASSWORD_MESSAGES.REQUIRED_MSG,
                  minLength: {
                    value: 8,
                    message: PASSWORD_MESSAGES.MIN_LENGTH_MSG,
                  },
                  maxLength: {
                    value: 64,
                    message: PASSWORD_MESSAGES.MAX_LENGTH_MSG,
                  },
                  pattern: {
                    value: new RegExp(PASSWORD_PATTERN),
                    message: PASSWORD_MESSAGES.INVALID_PATTERN_MSG,
                  },
                  validate: (value: string) => {
                    // 英大文字、英小文字、数字、記号の４種類から、２種類以上を使う必要がある
                    const numberOfKind = [
                      new RegExp(`[${CHARACTER_TYPES.UPPER_CASE_PATTERN}]`),
                      new RegExp(`[${CHARACTER_TYPES.LOWER_CASE_PATTERN}]`),
                      new RegExp(`[${CHARACTER_TYPES.NUMBER_PATTERN}]`),
                      new RegExp(`[${CHARACTER_TYPES.SYMBOL_PATTERN}]`),
                    ].filter((regexp) => regexp.test(value)).length;
                    if (numberOfKind < 2) {
                      return PASSWORD_MESSAGES.INVALID_PATTERN_MSG;
                    }

                    const repository: PasswordValidityCheckRepository =
                      new PasswordValidityCheckRepositoryImpl();

                    if (checkedPassword.current.value === value) {
                      return checkedPassword.current.available
                        ? true
                        : PASSWORD_MESSAGES.NOT_RECOMMENDABLE_PATTERN_MSG;
                    }
                    checkedPassword.current = {
                      value: '',
                      available: false,
                    };

                    return repository
                      .fetch(value)
                      .then((response) => {
                        if (!response.available) {
                          checkedPassword.current = {
                            value,
                            available: false,
                          };

                          return PASSWORD_MESSAGES.NOT_RECOMMENDABLE_PATTERN_MSG;
                        }
                        checkedPassword.current = {
                          value,
                          available: true,
                        };

                        // onBlurでバリデーションを実施しているため、
                        // passwordのほうを変更した結果一致した場合にpasswordConfirmationのエラーが消えない
                        // 同様にpasswordを変更した結果不一致になった場合にエラーが表示されなかった
                        // 一致しない場合にはpasswordConfirmationのエラーとしてsetErrorをして、一致する場合にはエラーを消すようにした
                        if (
                          value !== getValues('passwordConfirmation') &&
                          getValues('passwordConfirmation') !== ''
                        ) {
                          setError('passwordConfirmation', {
                            type: 'manual',
                            message:
                              PASSWORD_MESSAGES.NOT_MATCH_CONFIRMATION_MSG,
                          });

                          return true;
                        }
                        clearErrors('passwordConfirmation');

                        return true;
                      })
                      .catch((_: ErrorDTO) => {
                        requestShowing();

                        return false;
                      });
                  },
                })}
              />,
            ]}
          </FormContainer>
          <ErrorMessage
            errors={errors}
            name="password"
            render={({ message }) => {
              const sentences =
                message == null
                  ? ''
                  : message.split('\n').map((sentence) => (
                      <>
                        {sentence}
                        <br />
                      </>
                    ));

              return <p className={styles.errorMessageLarge}>{sentences}</p>;
            }}
          />
        </Container>

        <Container marginTop="m">
          <FormContainer>
            {[
              <FormTitle size="large" text="パスワードの再確認" />,
              <InputPassword
                name="passwordConfirmation"
                size="small"
                placeholder="8桁以上、大小英数記号から2種類以上"
                visibleButtonNoColor={false}
                register={register('passwordConfirmation', {
                  validate: (value) =>
                    value === getValues('password') ||
                    'パスワードが一致していません。',
                })}
              />,
            ]}
          </FormContainer>
          <ErrorMessage
            errors={errors}
            name="passwordConfirmation"
            render={({ message }) => (
              <p className={styles.errorMessageLarge}>{message}</p>
            )}
          />
        </Container>
        <Dialog
          title="エラー"
          primaryButton={{ text: 'OK' }}
          description="FitStatsに不具合が起きてます。一度FitStatsを終了させて再度お試しください。"
          isOpen={isOpen}
          onClose={onClose}
          alignDescriptionLeft
        />
      </div>
    </>
  );
};

export default PasswordPage;
