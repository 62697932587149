import { VFC } from 'react';
import { useForm } from 'react-hook-form';
import Container from 'global/components/Container/Container';
import FormContainer, {
  FormTitle,
} from 'global/components/FormContainer/FormContainer';
import InputTel from 'global/components/InputText/InputText';
import Button from 'global/components/button/Button';
import AttentionContainer from 'global/components/AttentionContainer/AttentionContainer';
import styles from './ConfirmPhoneNumberPage.module.css';

type Props = {
  submitted: (phoneNumber: string) => void;
};

type FormData = {
  phoneNumber: string;
};

const formTitle = <FormTitle size="large" text="登録している電話番号" />;

const ConfirmPhoneNumberPage: VFC<Props> = ({ submitted }) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty, isValid },
  } = useForm<FormData>({ mode: 'onBlur' });

  const onSubmit = (data: FormData) => {
    submitted(data.phoneNumber);
  };

  const name = 'phoneNumber';
  const options = {
    required: '電話番号は入力必須の項目です。',
    pattern: {
      value: /^0[0-9]{1}0[0-9]{8}$/,
      message: '電話番号が間違っています。',
    },
  };

  const registration = register(name, options);

  const inputTel = (
    <InputTel
      name={name}
      size="large"
      placeholder="携帯電話の電話番号"
      register={registration}
    />
  );

  return (
    <>
      <Container marginLeft="s" marginRight="s">
        <p className={styles.topMessage}>電話番号の入力</p>
        <p className={styles.message}>
          お持ちの携帯電話の電話番号を入力してください。
          <br />
          入力した電話番号に、ご登録のメールアドレスを送ります。
        </p>
        <Container marginTop="s">
          <FormContainer>{[formTitle, inputTel]}</FormContainer>
          <p className={styles.errorMessage}>{errors?.phoneNumber?.message}</p>
        </Container>

        <Container marginTop="m">
          <AttentionContainer>
            上記の電話番号にSMSでご登録のメールアドレスを送ります。
            <br />
            しばらくしてもSMSが届かない場合は、入力した電話番号に誤りがないかご確認ください。
          </AttentionContainer>
        </Container>
      </Container>

      <Container marginTop="l" marginBottom="s" marginLeft="s" marginRight="s">
        <Button
          text="メールアドレスを確認する"
          type="primary"
          size="large"
          disabled={!isDirty || !isValid}
          onClick={handleSubmit(onSubmit)}
        />
      </Container>
    </>
  );
};

export default ConfirmPhoneNumberPage;
