/* eslint-disable react/jsx-props-no-spreading */
import { VFC } from 'react';
import AttentionContainer from 'global/components/AttentionContainer/AttentionContainer';
import ProgramCarousel from '../component/ProgramCarousel';
import { ProgramTopViewState } from '../view-data/program-top-view-data';
import ProgramListCell from '../component/ProgramListCell';
import styles from './ProgramTopPage.module.css';
import ProgramId from '../data/common/program-id';

type Props = {
  notJoinedState: ProgramTopViewState;
  joinedState: ProgramTopViewState;
  onTapCarouselItem: (id: ProgramId) => void;
};

const ProgramTopPage: VFC<Props> = ({
  notJoinedState,
  joinedState,
  onTapCarouselItem,
}) => (
  <>
    <div className={styles.container}>
      <AttentionContainer>
        <div className={styles.topNoticeText}>
          健康増進につながるおすすめの行動を定期的にお届けします。お悩みに合わせてチャレンジしてみましょう。
        </div>
      </AttentionContainer>
      {notJoinedState.kind === 'exist' && (
        <>
          <div className={styles.contentsTitle}>参加可能なプログラム</div>
          <ProgramCarousel
            onClickItem={(index) =>
              onTapCarouselItem(notJoinedState.contents[index].id)
            }
          >
            {notJoinedState.contents.map((viewObject, index) => (
              <div key={viewObject.id.value}>
                <ProgramListCell
                  {...viewObject}
                  isLastElement={
                    notJoinedState.contents.length !== 1 &&
                    index === notJoinedState.contents.length - 1
                  }
                />
              </div>
            ))}
          </ProgramCarousel>
        </>
      )}
      <br />
      <div className={styles.contentsTitle}>参加中のプログラム</div>
      <>
        {joinedState.kind === 'exist' && (
          <>
            <ProgramCarousel
              onClickItem={(index) =>
                onTapCarouselItem(joinedState.contents[index].id)
              }
            >
              {joinedState.contents.map((viewObject, index) => (
                <div key={viewObject.id.value}>
                  <ProgramListCell
                    {...viewObject}
                    isLastElement={
                      joinedState.contents.length !== 1 &&
                      index === joinedState.contents.length - 1
                    }
                  />
                </div>
              ))}
            </ProgramCarousel>
          </>
        )}
        {joinedState.kind === 'empty' && (
          <div className={styles.emptyJoinedProgram}>
            参加中のプログラムはありません
          </div>
        )}
      </>
    </div>
  </>
);

export default ProgramTopPage;
