import { VFC } from 'react';
import { useForm } from 'react-hook-form';
import Container from 'global/components/Container/Container';
import FormContainer, {
  FormTitle,
} from 'global/components/FormContainer/FormContainer';
import InputText from 'global/components/InputText/InputText';
import Button from 'global/components/button/Button';
import styles from './InputEmailPage.module.css';

type Props = {
  submitted: (email: string) => void;
  isDisabled: boolean;
  setIsDisabled: React.Dispatch<React.SetStateAction<boolean>>;
};

type FormData = {
  email: string;
};

const InputEmailPage: VFC<Props> = ({ submitted, isDisabled, setIsDisabled }) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty, isValid },
  } = useForm<FormData>({ mode: 'onBlur' });

  const onSubmit = (data: FormData) => {
    setIsDisabled(true);
    submitted(data.email);
  };

  return (
    <>
      <Container marginLeft="s" marginRight="s">
        <div className={styles.body}>
          <p className={styles.topMessage}>メールアドレスの入力</p>
          <p className={styles.message}>
            ご登録のメールアドレスを入力してください。
            <br />
            入力したメールアドレスに、パスワードの再設定に
            <br />
            必要な認証コードを送ります。
          </p>
          <Container marginTop="m">
            <FormContainer>
              {[
                <FormTitle size="large" text="登録しているメールアドレス" />,
                <InputText
                  name="email"
                  placeholder="example@stats.com"
                  size="large"
                  register={register('email', {
                    required: 'メールアドレスは入力必須の項目です。',
                    pattern: {
                      value: /.+@.+/,
                      message: '正しいメールアドレスを入力してください。',
                    },
                    maxLength: {
                      value: 50,
                      message: '50文字以下で入力してください。',
                    },
                  })}
                />,
              ]}
            </FormContainer>
            <p className={styles.errorMessage}>{errors?.email?.message}</p>
          </Container>
        </div>
      </Container>
      <Container marginTop="4xl" marginLeft="s" marginRight="s">
        <Button
          text="認証コードを受け取る"
          type="primary"
          size="large"
          disabled={!isDirty || !isValid || isDisabled}
          onClick={handleSubmit(onSubmit)}
        />
      </Container>
    </>
  );
};

export default InputEmailPage;
