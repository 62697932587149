import { VFC, useRef } from 'react';
import { useForm } from 'react-hook-form';
import Container from 'global/components/Container/Container';
import FormContainer, {
  FormTitle,
} from 'global/components/FormContainer/FormContainer';
import InputText from 'global/components/InputText/InputText';
import Button from 'global/components/button/Button';
import AttentionContainer from 'global/components/AttentionContainer/AttentionContainer';
import { EMAIL_VALIDATION_MESSAGES, GENERAL_INTERNAL_SERVER_ERROR_MESSAGE } from 'global/constants';
import { EmailValidityCheckRepositoryImpl } from 'global/data/repositories/email-validity-check-repository';
import useDialog from 'global/components/dialog/use-dialog';
import { ValidityCheckedEmail } from 'global/data/dto/email-validity-check-dto';
import ErrorDTO from 'data/dto/error-dto';
import Dialog from 'global/components/dialog/Dialog';
import styles from './EmailResettingsPage.module.css';

type Props = {
  submitted: (currentEmail: string, newEmail: string) => void;
  isDisabled: boolean;
  setIsDisabled: React.Dispatch<React.SetStateAction<boolean>>;
};

type FormData = {
  currentEmail: string;
  newEmail: string;
  newEmailConfirmation: string;
};

const EmailResettingsPage: VFC<Props> = ({ submitted, isDisabled, setIsDisabled }) => {
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors, isDirty, isValid },
  } = useForm<FormData>({ mode: 'onBlur' });

  const onSubmit = (data: FormData) => {
    setIsDisabled(true);
    submitted(data.currentEmail, data.newEmail);
  };

  const { isOpen, requestShowing, onClose } = useDialog();
  const checkedEmail = useRef<ValidityCheckedEmail>({
    value: '',
    result: false,
  });

  return (
    <>
      <Container marginLeft="s" marginRight="s">
        <div className={styles.body}>
          <Container marginTop="m">
            <FormContainer>
              {[
                <FormTitle size="large" text="現在のメールアドレス" />,
                <InputText
                  name="currentEmail"
                  placeholder="example@stats.com"
                  size="large"
                  register={register('currentEmail', {
                    required: '現在のメールアドレスは入力必須の項目です。',
                    pattern: {
                      value: /.+@.+/,
                      message: EMAIL_VALIDATION_MESSAGES.INPUT_EMAIL_IS_INVALID_PATTERN,
                    },
                    maxLength: {
                      value: 50,
                      message: EMAIL_VALIDATION_MESSAGES.INPUT_EMAIL_LENGTH_IS_ABOVE,
                    },
                  })}
                />,
              ]}
            </FormContainer>
            <p className={styles.errorMessage}>
              {errors?.currentEmail?.message}
            </p>
          </Container>
          <Container marginTop="m">
            <FormContainer>
              {[
                <FormTitle size="large" text="新しいメールアドレス" />,
                <InputText
                  name="newEmail"
                  placeholder="new@stats.com"
                  size="large"
                  register={register('newEmail', {
                    required: '新しいメールアドレスは入力必須の項目です。',
                    pattern: {
                      value: /.+@.+/,
                      message: EMAIL_VALIDATION_MESSAGES.INPUT_EMAIL_IS_INVALID_PATTERN,
                    },
                    maxLength: {
                      value: 50,
                      message: EMAIL_VALIDATION_MESSAGES.INPUT_EMAIL_LENGTH_IS_ABOVE,
                    },
                    validate: (value) => {
                      // 重複エラー
                      if (value === getValues('currentEmail')) {
                        return EMAIL_VALIDATION_MESSAGES.NEW_EMAIL_IS_DUPLICATED;
                      }

                      // ドメイン妥当性エラー
                      const repository = new EmailValidityCheckRepositoryImpl();

                      if (checkedEmail.current.value === value) {
                        return checkedEmail.current.result
                          ? true
                          : EMAIL_VALIDATION_MESSAGES.INPUT_EMAIL_DOMAIN_IS_INVALID;
                      }
                      checkedEmail.current = {
                        value: '',
                        result: false,
                      };

                      const result = repository
                        .fetch(value)
                        .then((response) => {
                          const isEmailValid = response.result === 'valid';
                          if (!isEmailValid) {
                            checkedEmail.current = {
                              value,
                              result: false,
                            };

                            return EMAIL_VALIDATION_MESSAGES.INPUT_EMAIL_DOMAIN_IS_INVALID;
                          }
                          checkedEmail.current = {
                            value,
                            result: true,
                          };

                          return true;
                        })
                        .catch((_: ErrorDTO) => {
                          requestShowing();

                          return false;
                        });

                      return result;
                    },
                  })}
                />,
              ]}
            </FormContainer>
            <p className={styles.errorMessage}>{errors?.newEmail?.message}</p>
          </Container>
          <Container marginTop="m">
            <FormContainer>
              {[
                <FormTitle size="large" text="新しいメールアドレスの確認" />,
                <InputText
                  name="newEmailConfirmation"
                  placeholder="new@stats.com"
                  size="large"
                  register={register('newEmailConfirmation', {
                    validate: (value) =>
                      value === getValues('newEmail') ||
                      '新しいメールアドレスと一致しません。',
                  })}
                />,
              ]}
            </FormContainer>
            <p className={styles.errorMessage}>
              {errors?.newEmailConfirmation?.message}
            </p>
          </Container>
        </div>
      </Container>
      <Container marginTop="xl" marginLeft="s" marginRight="s">
        <AttentionContainer>
          ここで入力したメールアドレスに認証コードをお送りします。送信後しばらくして認証番号が届かない場合は再度この画面でメールアドレスを入力してください。
        </AttentionContainer>
      </Container>
      <Container marginTop="4xl" marginLeft="s" marginRight="s">
        <Button
          text="認証コードを受け取る"
          type="primary"
          size="large"
          disabled={!isDirty || !isValid || isDisabled}
          onClick={handleSubmit(onSubmit)}
        />
      </Container>
      <Dialog
          title="エラー"
          primaryButton={{ text: 'OK' }}
          description={GENERAL_INTERNAL_SERVER_ERROR_MESSAGE}
          isOpen={isOpen}
          onClose={onClose}
          alignDescriptionLeft
        />
    </>
  );
};

export default EmailResettingsPage;
