import ApiClient from 'data/api-client/api-client';
import RequestConfig, { URL } from 'data/request-configs/request-config';
import UserAnnouncementModalsDto from '../dto/user-announcement-modal-dto';

const createUserAnnouncementModalDtoRequestConfig = (referAFriendFlag: boolean): RequestConfig => ({
  endpoint: {
    baseUrl: URL.FIT_STATS,
    path: '/users/me/announcement_modal/',
  },
  method: 'post',
  parameters: {refer_a_friend_flag: referAFriendFlag},
});

const updateUserAnnouncementModalDtoRequestConfig = (code: string): RequestConfig => ({
  endpoint: {
    baseUrl: URL.FIT_STATS,
    path: '/users/me/announcement_modal/',
  },
  method: 'patch',
  parameters: {code},
});

interface UserAnnouncementModalRepository {
  fetch: (referAFriendFlag: boolean) => Promise<UserAnnouncementModalsDto>;
}

export class UserAnnouncementModalRepositoryImpl
  implements UserAnnouncementModalRepository {
  fetch = async (referAFriendFlag: boolean): Promise<UserAnnouncementModalsDto> =>
    new ApiClient().connect(createUserAnnouncementModalDtoRequestConfig(referAFriendFlag));

  save = async (code: string): Promise<UserAnnouncementModalsDto> =>
      new ApiClient().connect(updateUserAnnouncementModalDtoRequestConfig(code));
}

export default UserAnnouncementModalRepository;
