import { VFC } from 'react';
import { useHistory, useParams } from 'react-router';
import Dialog from 'global/components/dialog/Dialog';
import useNavigationBarItemsSetting from 'global/hooks/use-navigation-bar-items-setting';
import ErrorContainer from 'global/components/ErrorContainer/ErrorContainer';
import useProgramDetailDialog from '../hooks/util/use-program-detail-dialog';
import Loading from '../../../global/components/Loading/Loading';
import useProgramDetail from '../hooks/use-program-detail';
import ProgramDetailPage from '../pages/ProgramDetailPage';
import ProgramId from '../data/common/program-id';

const ProgramDetailContainer: VFC = () => {
  const history = useHistory();
  const { programId } = useParams<{ programId: string }>();
  const {
    isOpen,
    dialogTitle,
    dialogDescription,
    imageSrc,
    imageSize,
    primaryButton,
    destructiveButton,
    requestShowing,
    onClose,
  } = useProgramDetailDialog();
  useNavigationBarItemsSetting('プログラム内容');

  const {
    fetchResult,
    joinProgramButtonTapped,
    leaveProgramButtonTapped,
    actionButtonTapped,
  } = useProgramDetail(
    ProgramId.fromString(programId),
    (onTapDecided) => {
      requestShowing(
        `FitStatsを離れて、FiNCの記録画面へ移動します。`,
        `よろしいですか？`,
        undefined,
        undefined,
        { text: 'はい', onClick: onTapDecided },
        { text: 'いいえ' },
      );
    },
    (viewData, onTapDecided) => {
      requestShowing(
        `【${viewData.title}】の参加をキャンセルしますか？`,
        `キャンセルしたプログラムは、いつでも参加し直すことができます。`,
        undefined,
        undefined,
        { text: 'はい', onClick: onTapDecided },
        { text: 'いいえ' },
      );
    },
    (viewData) => {
      if (viewData.joinedState.kind === 'notJoined') {
        requestShowing(
          undefined,
          `${viewData.title}の参加をキャンセルしました。`,
          undefined,
          undefined,
          { text: 'OK', onClick: () => history.push('/program') },
        );
      } else if (viewData.pointStatus === 'shipped') {
        requestShowing(
          `${viewData.pointAmount}ポイント獲得！`,
          `【${viewData.title}】に参加しました。`,
          '/offer/offer_achive.svg',
        );
      } else if (viewData.pointStatus === 'error') {
        requestShowing(
          undefined,
          `【${viewData.title}】に参加しました。\n獲得したポイントは後日付与されます。\nポイント獲得履歴は「ポイント」からご確認ください。`,
        );
      } else {
        requestShowing(undefined, `【${viewData.title}】に参加しました。`);
      }
    },
    (viewData) => {
      requestShowing(
        'アクション達成！',
        `${viewData.reward.titleText}を${viewData.reward.valueText}獲得しました。`,
        '/program/actionclear@3x.png',
        'large',
      );
    },
    (error) => requestShowing('エラー', `${error.message}`),
  );

  if (fetchResult.isSuccess()) {
    return (
      <>
        <Dialog
          title={dialogTitle}
          description={dialogDescription}
          imageSrc={imageSrc}
          imageSize={imageSize}
          primaryButton={primaryButton}
          destructiveButton={destructiveButton}
          isOpen={isOpen}
          onClose={onClose}
        />

        <ProgramDetailPage
          viewData={fetchResult.value}
          joinProgramButtonTapped={joinProgramButtonTapped}
          leaveProgramButtonTapped={leaveProgramButtonTapped}
          actionButtonTapped={actionButtonTapped}
        />
      </>
    );
  }

  if (fetchResult.isFailure()) {
    return <ErrorContainer>{fetchResult.error.message}</ErrorContainer>;
  }

  return <Loading />;
};

export default ProgramDetailContainer;
