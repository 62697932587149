import FormContainer, {
  FormTitle,
} from 'global/components/FormContainer/FormContainer';
import InputText from 'global/components/InputText/InputText';
import { VFC, useRef } from 'react';
import { FieldValues, FieldErrors, UseFormRegister, } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { EmailValidityCheckRepositoryImpl } from 'global/data/repositories/email-validity-check-repository';
import useDialog from 'global/components/dialog/use-dialog';
import ErrorDTO from 'data/dto/error-dto';
import Container from 'global/components/Container/Container';
import Dialog from 'global/components/dialog/Dialog';
import { EMAIL_VALIDATION_MESSAGES, GENERAL_INTERNAL_SERVER_ERROR_MESSAGE } from 'global/constants';
import { ValidityCheckedEmail } from 'global/data/dto/email-validity-check-dto';
import styles from './EmailRegistrationPage.module.css';

type Props = {
  register: UseFormRegister<FieldValues>;
  errors: FieldErrors<FieldValues>;
};

const RegistrationPage: VFC<Props> = ({
  register,
  errors,
}) => {
  const { isOpen, requestShowing, onClose } = useDialog();
  const checkedEmail = useRef<ValidityCheckedEmail>({
    value: '',
    result: false,
  });

  return (
    <>
      <div className={styles.contentArea}>
        <Container marginTop="m">
          <FormContainer>
            {[
              <div className={styles.formTitleWrapper}>
                <FormTitle size="large" text="メールアドレス" />
                <p className={styles.remark}>
                  ※ログインの際に使用します
                </p>
              </div>,
              <InputText
                name="email"
                size="medium"
                placeholder="example@stats.com"
                register={register('email', {
                  required: EMAIL_VALIDATION_MESSAGES.EMAIL_IS_REQUIRED,
                  pattern: {
                    value: /.+@.+/,
                    message: EMAIL_VALIDATION_MESSAGES.INPUT_EMAIL_IS_INVALID_PATTERN,
                  },
                  validate: (value: string) => {
                    const repository = new EmailValidityCheckRepositoryImpl();

                    if (checkedEmail.current.value === value) {
                      return checkedEmail.current.result
                        ? true
                        : EMAIL_VALIDATION_MESSAGES.INPUT_EMAIL_DOMAIN_IS_INVALID;
                    }
                    checkedEmail.current = {
                      value: '',
                      result: false,
                    };

                    const result = repository
                      .fetch(value)
                      .then((response) => {
                        const isValid = response.result === 'valid';
                        if (!isValid) {
                          checkedEmail.current = {
                            value,
                            result: false,
                          };

                          return EMAIL_VALIDATION_MESSAGES.INPUT_EMAIL_DOMAIN_IS_INVALID;
                        }
                        checkedEmail.current = {
                          value,
                          result: true,
                        };

                        return true;
                      })
                      .catch((_: ErrorDTO) => {
                        requestShowing();

                        return false;
                      });

                    return result;
                  },
                })}
              />,
            ]}
          </FormContainer>
          <ErrorMessage
            errors={errors}
            name="email"
            render={({ message }) => (
              <p className={styles.errorMessage}>{message}</p>
            )}
          />
        </Container>
        <Dialog
          title="エラー"
          primaryButton={{ text: 'OK' }}
          description={GENERAL_INTERNAL_SERVER_ERROR_MESSAGE}
          isOpen={isOpen}
          onClose={onClose}
          alignDescriptionLeft
        />
      </div>
    </>
  );
};

export default RegistrationPage;
