import { VFC } from 'react';
import { useHistory } from 'react-router-dom';
import useDialog from 'global/components/dialog/use-dialog';
import Loading from 'global/components/Loading/Loading';
import Dialog from 'global/components/dialog/Dialog';
import useNavigationBarItemsSetting from 'global/hooks/use-navigation-bar-items-setting';
import ErrorContainer from 'global/components/ErrorContainer/ErrorContainer';
import HontoCouponPage from '../pages/HontoCouponPage';
import useHontoCoupon from '../hooks/use-honto-coupon';

const HontoCouponContainer: VFC = () => {
  const history = useHistory();
  const { isOpen, requestShowing, onClose, dialogProps } = useDialog();
  useNavigationBarItemsSetting('【honto】クーポン交換');

  const {
    fetchResult,
    onClosedNotice,
    isClosedNotice,
    formRegister,
    expectedExchangeCouponCountText,
    exchangeNeedCostText,
    exchangedResultRemainPointText,
    isInsufficientPoints,
    isExchangeDisable,
    onExchangeValueChanged,
    handleExchangeSubmit,
  } = useHontoCoupon(
    (confirmMessage, onTapDecided) => {
      requestShowing({
        title: 'ポイントを交換します。こちらの内容でよろしいですか？',
        description: confirmMessage,
        primaryButton: { text: '確定する', onClick: onTapDecided },
        destructiveButton: { text: '戻る' },
      });
    },
    (result) => {
      if (result.isSuccess()) {
        requestShowing({
          title: 'クーポンに交換しました',
          description: result.value.successMessage,
          primaryButton: {
            text: 'ポイントTOPへ',
            onClick: () => history.push('/use_points'),
          },
        });
      }
      if (result.isFailure()) {
        requestShowing({
          description: result.error.message,
          primaryButton: {
            text: '閉じる',
          },
        });
      }
    },
  );

  if (fetchResult.isSuccess()) {
    return (
      <>
        <Dialog
          title={dialogProps?.title}
          description={dialogProps?.description}
          primaryButton={dialogProps?.primaryButton ?? { text: '閉じる' }}
          destructiveButton={dialogProps?.destructiveButton}
          isOpen={isOpen}
          onClose={onClose}
        />

        <HontoCouponPage
          viewData={fetchResult.value}
          onClosedNotice={onClosedNotice}
          isClosedNotice={isClosedNotice}
          formRegister={formRegister}
          expectedExchangeCouponCountText={expectedExchangeCouponCountText}
          exchangeNeedCostText={exchangeNeedCostText}
          exchangedResultRemainPointText={exchangedResultRemainPointText}
          isInsufficientPoints={isInsufficientPoints}
          isExchangeDisable={isExchangeDisable}
          onExchangeValueChanged={onExchangeValueChanged}
          onSubmit={handleExchangeSubmit}
        />
      </>
    );
  }

  if (fetchResult.isFailure()) {
    return <ErrorContainer>{fetchResult.error.message}</ErrorContainer>;
  }

  return <Loading />;
};

export default HontoCouponContainer;
