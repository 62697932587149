import { VFC } from 'react';
import { Switch, Route } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import AuthenticationContainer from 'features/sign-up/email/authentication/containers';

const EmailRouter: VFC<RouteComponentProps> = ({ match }) => (
  <Switch>
    <Route path={`${match.path}/authentication`}>
      <AuthenticationContainer />
    </Route>
  </Switch>
);

export default EmailRouter;
