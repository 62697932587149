import { VFC } from 'react';
import { Switch, Route } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import StatsDescriptionContainer from 'features/stats-description/containers/index';

const StatsDescriptionRouter: VFC<RouteComponentProps> = ({ match }) => (
  <Switch>
    <Route exact path={`${match.path}`}>
      <StatsDescriptionContainer />
    </Route>
  </Switch>
);

export default StatsDescriptionRouter;
