import { useCallback, useEffect, useRef, VFC } from 'react';
import Button from 'global/components/button/Button';
import CopyToClipboardButton from 'global/components/CopyToClipboardButton/CopyToClipboardButton';
import { useToast } from '@chakra-ui/react';
import requestWebCommand, {
  ShowShareUi,
  OpenExternalWeb,
} from 'global/utilities/web-command';
import Dialog from 'global/components/dialog/Dialog';
import useDialog from 'global/components/dialog/use-dialog';
import { FriendReferralCode } from '../view-data/referral-code-view-data';
import styles from './ReferralCodePage.module.css';
import ReferralCodeNoticeSectionList from '../components/ReferralCodeNoticeSectionList';
import ReferralCodeStepList from '../components/ReferralCodeStepList';
import './toast.css'

type Props = {
  referralCode: FriendReferralCode;
};

const description = {
  title: '簡単ステップでポイントGET!',
  contents: {
    steps: [
      {
        instruction: 'このページにある「あなたの紹介コード」をコピーし、SNSでシェア',
        image: '/refer_a_friend/refer_a_friend_step1.png',
        alt: 'refer_a_friend_step1',
      },
      {
        instruction: '紹介された人があなたの紹介コードを使ってFitStatsに新規登録',
        image: '/refer_a_friend/refer_a_friend_step2.png',
        alt: 'refer_a_friend_step2',
      },
      {
        instruction: 'あなたも紹介された人もFitStatsポイント100ptゲット！',
        image: '/refer_a_friend/refer_a_friend_step3.png',
        alt: 'refer_a_friend_step3',
      },
    ],
    annotations: [
      '獲得したポイントはポイント履歴から確認できます'
    ],
  },
};

const notice = {
  title: '注意事項',
  contents: [
    {
      title: '友達紹介について',
      details: [
        '紹介する友達の人数に上限はありません。',
        '発行された紹介コードは変更しないでください。変更された場合、紹介ポイントが付与されません。',
        '弊社が不正行為と判断した場合、付与されたポイントを取り消しする場合があります。また、不正利用が発覚した場合、アカウント停止などの措置をとる場合があります。',
        '友達を紹介するには、FiNCアプリのバージョン9.24.0 以上が必要です。',
      ],
    },
    {
      title: 'ポイントの付与について',
      details: [
        '紹介コードを送っただけではポイント付与の対象となりません。紹介された友達がFitStatsに新規登録を完了した時点で、紹介した方・紹介された方の双方にポイントが自動的に付与されます。',
        '紹介完了人数、紹介された方等に関して個別にお問合せをいただいても、ご回答はできかねます。ポイントの進呈をもって紹介完了をご確認ください。',
      ],
    }
  ],
  annotations: [
    '事前の予告なく本紹介機能を停止したり、ポイント数などの内容を変更したりすることがあります。',
  ],
};

const snsShareText = (referralCode: string):string => "日々の健康状態をスコアで管理できる\n\n​" +
  "無料のヘルスケアサービス「FitStats」を使ってみよう！​\n\n\n\n" +
  `紹介コード【${referralCode}】を使って\n\n` +
  "新規登録すると100円分の\n\n​" +
  "FitStatsポイントがもらえます。\n\n\n​" +
  "▼詳細はこちら\n\n​" +
  "https://fitstats.jp/refer-friend/\n​";

const ReferAFriendPage: VFC<Props> = ({ referralCode }) => {
  const toast = useToast();
  const toastId = 'copied toast'
  const { isOpen, requestShowing, onClose } = useDialog();
  const rendered = useRef(false);

  const snsShare = useCallback(():void => {
    const text = snsShareText(referralCode);
    const encoded = encodeURIComponent(text);
    requestWebCommand(new ShowShareUi(encoded));
  }, [referralCode]);

  const redirectAppStore = ():void => {
    rendered.current = false;
    requestWebCommand(new OpenExternalWeb('https://bit.finc.com/hfl2dw'));
  };

  useEffect(() => {
    if(rendered.current){
      if(!isOpen){
        snsShare();
      }
    } else {
      rendered.current = true;
    }
  }, [isOpen, snsShare]);

  return (
      <>
        <img
          className={styles.bannerArea}
          src='/refer_a_friend/refer_a_friend_top.png?20231130'
          alt='refer_a_friend_top_banner'
        />

        <div className={styles.contentsArea}>
          <div className={styles.descriptionArea}>
            <div className={styles.title}>
              {description.title}
            </div>
            <div className={styles.contents}>
              <ReferralCodeStepList steps={description.contents.steps} />
              {description.contents.annotations.map(annotation => (
                <div className={styles.annotation}>
                  {annotation}
                </div>
              ))}
              <div className={styles.referralCodeArea}>
                <div className={styles.title}>
                  あなたの紹介コード
                </div>
                <div className={styles.referralCodeBox}>
                  <div className={styles.referralCode}>
                    { referralCode }
                  </div>
                  <div className={styles.copyButton}>
                    <CopyToClipboardButton
                      textToCopy={referralCode}
                      buttonLabel='コピー'
                      onCopy={ () => {
                        if(!toast.isActive(toastId)) {
                          toast({
                            id: toastId,
                            position: 'bottom',
                            duration: 3000,
                            render: () => (
                              <div className={styles.toast}>
                                <div className={styles.checkbox}/>
                                <span>紹介コードがコピーされました</span>
                              </div>
                            ),
                          });
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
              <Dialog
                title='FiNCアプリを最新バージョンに更新してください'
                description={
                  '紹介コードをSNSでシェアするには、FiNCアプリを最新バージョンに更新（アップデート）する必要があります。アプリのバージョンが9.24.0未満の場合には、アプリストアから更新をお願いいたします。\nFiNCアプリが最新の場合には、右上の「×」ボタンをタップして本ウインドウを閉じてください。'
                }
                primaryButton={{
                  text: 'アプリストアへ',
                  onClick: () => redirectAppStore(),
                }}
                isOpen={isOpen}
                onClose={onClose}
              />
              <Button
                text='SNSでシェア'
                type='primary'
                size='large'
                onClick={() => {
                  if(localStorage.getItem('app_store_modal') === 'false'){
                    snsShare();
                  } else {
                    localStorage.setItem('app_store_modal', 'false');
                    requestShowing();
                  }
                }}
              />
            </div>
          </div>

          <div className={styles.noticeArea}>
            <div className={styles.title}>
              {notice.title}
            </div>
            <div className={styles.contents}>
              <ReferralCodeNoticeSectionList
                sections={notice.contents}
              />
              {notice.annotations.map(annotation => (
                <div className={styles.annotation}>
                  {annotation}
                </div>
              ))}
            </div>
          </div>
        </div>
      </>
  )};

export default ReferAFriendPage;
