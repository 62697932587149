import ApiClient from 'data/api-client/api-client';
import AuthorizationDto from 'features/sign-up/privacy-policy/data/dto/authorization-dto';
import PartnersAuthorizationDto from 'features/sign-up/data/dto/partners-authorization-dto';
import RequestConfig from 'data/request-configs/request-config';

const createAuthorizationRequestConfig = (params: AuthorizationDto, onetimeToken: string): RequestConfig =>
{
  const matchResult = RegExp('^(https?://[^/]+)(.*)$').exec(params.partnersApiUrl);
  let baseUrl;
  let path
  if (matchResult !== null) {
    [, baseUrl, path] = matchResult;
  } else {
    throw new Error('Invalid endpoint url');
  }

  return {
    endpoint: { baseUrl, path },
    method: 'post',
    parameters: {
      onetime_token: onetimeToken,
      client_id: params.clientId,
      response_type: params.responseType,
      scope: params.scope.replaceAll('+', ' '),
      redirect_uri: params.redirectUri,
      code_challenge: params.codeChallenge,
      code_challenge_method: params.codeChallengeMethod,
    },
  }
};

class PartnersAuthorizationRepository {
  authorize = async (params: AuthorizationDto, onetimeToken: string): Promise<PartnersAuthorizationDto> =>
    new ApiClient().connect(createAuthorizationRequestConfig(params, onetimeToken));
}

export default PartnersAuthorizationRepository;

