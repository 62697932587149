import { useCallback, useState } from 'react';
import ClientCompanyRepository, {
  ClientCompanyRepositoryImpl,
} from 'features/my-data/client-company/data/repositories/client-company-repository';
import clientCompaniesPageLastLoadedAt from 'features/my-data/client-company/hooks/utils/last-page-load-at';
import queryClient from 'global/query-client';

const NUMBER_OF_NEW_CLIENT_COMPANIES_CACHE_KEY = '/number_of_new_client_companies';

type ReturnType = {
  fetchNumberOfNewClientCompanies: () => void;
  numberOfNewCompanies: number;
};

const useNumberOfNewClientCompanies = (
  repository: ClientCompanyRepository = new ClientCompanyRepositoryImpl(),
): ReturnType => {
  const [numberOfNewCompanies, setNumberOfNewCompanies] = useState(0);

  queryClient.setQueryDefaults(NUMBER_OF_NEW_CLIENT_COMPANIES_CACHE_KEY, {
    cacheTime: 86400000,
  });

  const fetchNumberOfNewClientCompanies = useCallback(() => {
    const cachedNumberOfNewClientCompanies: number | undefined =
      queryClient.getQueryData(NUMBER_OF_NEW_CLIENT_COMPANIES_CACHE_KEY);

    if (cachedNumberOfNewClientCompanies == null) {
      void repository.fetch().then((dto) => {
        const lastLoadedAt = clientCompaniesPageLastLoadedAt();
        if (lastLoadedAt == null) {
          setNumberOfNewCompanies(dto.clients.length);
          queryClient.setQueryData(NUMBER_OF_NEW_CLIENT_COMPANIES_CACHE_KEY, dto.clients.length);
        } else {
          const newClientCompanies = dto.clients.filter(
            (c) => c.registeredAt >= lastLoadedAt,
          );
          setNumberOfNewCompanies(newClientCompanies.length);
          queryClient.setQueryData(NUMBER_OF_NEW_CLIENT_COMPANIES_CACHE_KEY, newClientCompanies.length);
        }
      });
    } else {
        setNumberOfNewCompanies(cachedNumberOfNewClientCompanies);
    }

  }, [repository]);

  return { fetchNumberOfNewClientCompanies, numberOfNewCompanies };
};

export default useNumberOfNewClientCompanies;
