/* eslint-disable react/jsx-props-no-spreading */
import { VFC } from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';
import styles from './InputText.module.css';

export type InputTextSize = 'large' | 'medium' | 'small' | 'xs';

export type Props = {
  name: string;
  value?: string;
  size?: InputTextSize;
  placeholder?: string;
  register?: UseFormRegisterReturn;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  readOnly?: boolean;
  maxLength?: number;
};

const classNameForInputText = (size?: InputTextSize): string => {
  switch (size) {
    case 'large':
      return styles.inputTextLarge;
    case 'medium':
      return styles.inputTextMedium;
    case 'small':
      return styles.inputTextSmall;
    case 'xs':
      return styles.inputTextXS;
    default:
      return styles.inputTextXS;
  }
};

const InputText: VFC<Props> = ({
  name,
  value,
  size,
  placeholder,
  register,
  onChange,
  readOnly,
  maxLength,
}) => (
  <input
    name={name}
    type="text"
    value={value}
    placeholder={placeholder || '入力してください'}
    className={classNameForInputText(size)}
    {...register}
    onChange={onChange}
    readOnly={readOnly}
    maxLength={maxLength}
  />
);

export default InputText;
