import { useRef, useState, VFC } from 'react';
import { useHistory } from 'react-router';
import usePhoneNumberAuthentication from 'features/sign-up/phone-number/authentication/hooks/use-phone-number-authentication';
import usePasswordAndProfileRegistration from 'features/sign-up/phone-number/authentication/hooks/use-password-and-profile-registration';
import { PhoneNumberAuthenticationRepositoryImpl } from 'features/sign-up/phone-number/authentication/data/repositories/phone-number-authentication-repository';
import { PasswordAndProfileRegistrationRepositoryImpl } from 'features/sign-up/phone-number/authentication/data/repositories/password-and-profile-registration-repository';
import PhoneNumberAuthenticationPage from 'features/sign-up/phone-number/authentication/pages/PhoneNumberAuthenticationPage';
import useDialog from 'global/components/dialog/use-dialog';
import Dialog from 'global/components/dialog/Dialog';
import TopContainer from 'global/components/TopContainer/TopContainer';
import ProgressBar from 'features/sign-up/components/ProgressBar/ProgressBar';
import useNavigationBarItemsSetting from 'global/hooks/use-navigation-bar-items-setting';
import ErrorDTO from 'data/dto/error-dto';
import { InputValue } from 'features/profile/user-input-item';
import convertUserInputRawDataForRequest from 'features/my-data/personal-data/hooks/utils/convert-user-input-raw-data-for-request';
import useIntroductionProgress from 'features/introduction-progress/hooks/use-introduction-progress';

type DialogInfo = {
  description: string;
  primaryButtonAction: () => void;
};

type Profile = Record<'sex' | 'birthday' | 'prefecture', InputValue>;

type Account = {
  email: string;
  password: string;
  phoneNumber: string;
  profile: Profile;
  privacyPolicyVersion: string;
  termsOfUseVersion: string;
  referralCode: string;
};

const PhoneNumberAuthenticationContainer: VFC = () => {
  const history = useHistory();
  const { isOpen, requestShowing, onClose } = useDialog();
  const dialogInfoRef = useRef<DialogInfo | undefined>();
  const primaryButtonActionRef = useRef<() => void>(() => onClose());
  const [isDisabled, setIsDisabled] = useState(false);

  useNavigationBarItemsSetting('認証コード入力', {
    isCloseButtonVisible: false,
    isMenuButtonVisible: false,
    isNotificationButtonVisible: false,
    isBackButtonVisible: false,
  });

  const isPermissionDeniedError = (error: ErrorDTO | undefined) =>
    error && error.error.status === 'PERMISSION_DENIED';

  const fetchAccountFromLocalStorage = (): Account | null => {
    const inputAccount: string | null = localStorage.getItem('input_account');

    return inputAccount ? (JSON.parse(inputAccount) as Account) : null;
  };

  const account = fetchAccountFromLocalStorage();

  const { redirectToRecoveryPoint } = useIntroductionProgress();

  const { registrationSubmitted } = usePasswordAndProfileRegistration(
    new PasswordAndProfileRegistrationRepositoryImpl(),
    (result) => {
      if (result.isSuccess()) {
        const { deviceId } = result.value;
        if (deviceId) {
          localStorage.setItem('deviceId', deviceId);
        }

        localStorage.removeItem('input_account');

        history.push('/sign_up/welcome/first');
      }

      if (result.isFailure()) {
        const description =
          result.error?.error.message ===
          'パスワード作成の条件を満たしていません。'
            ? 'FitStatsで不具合が発生しています。お手数ですが、FitStatsのWEBサイトよりお問合せをお願いいたします。'
            : 'サーバーでエラーが発生しました';

        primaryButtonActionRef.current = () => {
          onClose();
          redirectToRecoveryPoint(false); // userのintroduction progressに応じて遷移先が変わる
        };
        dialogInfoRef.current = {
          description,
          primaryButtonAction: primaryButtonActionRef.current,
        };

        requestShowing();
      }
    },
  );

  const { submitted } = usePhoneNumberAuthentication(
    new PhoneNumberAuthenticationRepositoryImpl(),
    (authResult) => {
      if (authResult.isSuccess()) {
        if (account) {
          const {
            password,
            privacyPolicyVersion,
            termsOfUseVersion,
            referralCode,
          } = account;
          const { sex, birthday, prefecture } = account.profile;
          const filteredProfile: Profile = { sex, birthday, prefecture };
          const userProfile =
            convertUserInputRawDataForRequest(filteredProfile);
          registrationSubmitted(
            password,
            privacyPolicyVersion,
            termsOfUseVersion,
            userProfile,
            referralCode,
          );
        }
      }

      if (authResult.isFailure()) {
        const description = authResult.error
          ? authResult.error.error.message
          : 'SMS認証に失敗しました';

        if (isPermissionDeniedError(authResult.error)) {
          primaryButtonActionRef.current = () => {
            onClose();
            history.push('/');
          };
        }

        dialogInfoRef.current = {
          description,
          primaryButtonAction: primaryButtonActionRef.current,
        };

        requestShowing();
      }
      setIsDisabled(false);
    },
  );

  return (
    <>
      {dialogInfoRef.current && (
        <Dialog
          title="エラー"
          description={dialogInfoRef.current.description}
          primaryButton={{
            text: 'OK',
            onClick: dialogInfoRef.current.primaryButtonAction,
          }}
          isOpen={isOpen}
          onClose={() => undefined}
          showsCloseButton={false}
        />
      )}

      <TopContainer>
        <ProgressBar max={2} value={2} />
      </TopContainer>

      {/* これ以上style長くなりそうならcssファイルの切り出し検討する */}
      <div style={{ paddingTop: 60, paddingBottom: 150 }}>
        <PhoneNumberAuthenticationPage
          phoneNumber={
            (history.location.state as { phoneNumber: string }).phoneNumber
          }
          password={account?.password || ''}
          submitted={submitted}
          isDisabled={isDisabled}
          setIsDisabled={setIsDisabled}
        />
      </div>
    </>
  );
};

export default PhoneNumberAuthenticationContainer;
