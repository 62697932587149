import { useRef, VFC } from 'react';
import { useForm } from 'react-hook-form';
import Container from 'global/components/Container/Container';
import FormContainer, {
  FormTitle,
} from 'global/components/FormContainer/FormContainer';
import InputPassword, {
  CHARACTER_TYPES,
  PASSWORD_MESSAGES,
  PASSWORD_PATTERN,
} from 'global/components/InputPassword/InputPassword';
import Button from 'global/components/button/Button';
import PasswordAttention from 'global/components/PasswordAttention/PasswordAttention';
import ErrorDTO from 'data/dto/error-dto';
import useDialog from 'global/components/dialog/use-dialog';
import Dialog from 'global/components/dialog/Dialog';
import PasswordValidityCheckRepository, {
  PasswordValidityCheckRepositoryImpl,
} from 'global/data/repositories/password-validity-check-repository';
import styles from './NewPasswordPage.module.css';

type Props = {
  submitted: (newPassword: string, transactionId: string) => void;
  transactionId: string;
  isDisabled: boolean;
  setIsDisabled: React.Dispatch<React.SetStateAction<boolean>>;
};

type FormData = {
  newPassword: string;
  newPasswordConfirmation: string;
};

type ValidityCheckedPassword = {
  value: string;
  available: boolean;
};

const NewPasswordPage: VFC<Props> = ({
  submitted,
  transactionId,
  isDisabled,
  setIsDisabled,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isDirty },
    getValues,
    setError,
    clearErrors,
  } = useForm<FormData>({ mode: 'onBlur' });

  const validateInput = (value: string): string | undefined => {
    const numberOfKind = [
      new RegExp(`[${CHARACTER_TYPES.UPPER_CASE_PATTERN}]`),
      new RegExp(`[${CHARACTER_TYPES.LOWER_CASE_PATTERN}]`),
      new RegExp(`[${CHARACTER_TYPES.NUMBER_PATTERN}]`),
      new RegExp(`[${CHARACTER_TYPES.SYMBOL_PATTERN}]`),
    ].filter((regexp) => regexp.test(value)).length;

    if (numberOfKind < 2) {
      return PASSWORD_MESSAGES.INVALID_PATTERN_MSG;
    }

    return undefined;
  };

  const onSubmit = (data: FormData) => {
    setIsDisabled(true);
    submitted(data.newPassword, transactionId);
  };

  const { isOpen, requestShowing, onClose } = useDialog();
  const checkedPassword = useRef<ValidityCheckedPassword>({
    value: '',
    available: false,
  });

  return (
    <>
      <Container marginLeft="s" marginRight="s">
        <p className={styles.message}>新しいパスワードを設定してください。</p>
        <Container marginTop="s">
          <FormContainer>
            {[
              <FormTitle size="large" text="新しいパスワード" />,
              <InputPassword
                name="password"
                size="large"
                register={register('newPassword', {
                  required: PASSWORD_MESSAGES.REQUIRED_MSG,
                  minLength: {
                    value: 8,
                    message: PASSWORD_MESSAGES.MIN_LENGTH_MSG,
                  },
                  maxLength: {
                    value: 64,
                    message: PASSWORD_MESSAGES.MAX_LENGTH_MSG,
                  },
                  pattern: {
                    value: new RegExp(PASSWORD_PATTERN),
                    message: PASSWORD_MESSAGES.INVALID_PATTERN_MSG,
                  },
                  validate: (value) => {
                    const invalidMessage = validateInput(value);
                    if (invalidMessage !== undefined) {
                      return invalidMessage;
                    }

                    const repository: PasswordValidityCheckRepository =
                      new PasswordValidityCheckRepositoryImpl();

                    if (checkedPassword.current.value === value) {
                      return checkedPassword.current.available
                        ? true
                        : PASSWORD_MESSAGES.NOT_RECOMMENDABLE_PATTERN_MSG;
                    }
                    checkedPassword.current = {
                      value: '',
                      available: false,
                    };

                    return repository
                      .fetch(value)
                      .then((response) => {
                        if (!response.available) {
                          checkedPassword.current = {
                            value,
                            available: false,
                          };

                          return PASSWORD_MESSAGES.NOT_RECOMMENDABLE_PATTERN_MSG;
                        }
                        checkedPassword.current = {
                          value,
                          available: true,
                        };

                        if (
                          value !== getValues('newPasswordConfirmation') &&
                          getValues('newPasswordConfirmation') !== ''
                        ) {
                          setError('newPasswordConfirmation', {
                            type: 'manual',
                            message:
                              PASSWORD_MESSAGES.NOT_MATCH_CONFIRMATION_MSG,
                          });

                          return true;
                        }
                        clearErrors('newPasswordConfirmation');

                        return true;
                      })
                      .catch((_: ErrorDTO) => {
                        requestShowing();

                        return false;
                      });
                  },
                })}
              />,
            ]}
          </FormContainer>
          {errors?.newPassword && (
            <p className={styles.errorMessage}>
              {errors.newPassword.message?.split('\n').map((message) => (
                <>
                  {message}
                  <br />
                </>
              ))}
            </p>
          )}
        </Container>
        <Container marginTop="s">
          <FormContainer>
            {[
              <FormTitle size="large" text="新しいパスワードの再確認" />,
              <InputPassword
                name="passwordConfirmation"
                size="large"
                register={register('newPasswordConfirmation', {
                  validate: (value) =>
                    value === getValues('newPassword') ||
                    'パスワードが一致していません。',
                })}
              />,
            ]}
          </FormContainer>
          {errors?.newPasswordConfirmation && (
            <p className={styles.errorMessage}>
              {errors.newPasswordConfirmation.message}
            </p>
          )}
        </Container>
        <Container marginTop="m">
          <PasswordAttention />
        </Container>
        <Container marginTop="m">
          <Button
            text="パスワードを再設定する"
            type="primary"
            size="large"
            disabled={!isDirty || !isValid || isDisabled}
            onClick={handleSubmit(onSubmit)}
          />
        </Container>
      </Container>
      <Dialog
        title="エラー"
        primaryButton={{ text: 'OK' }}
        description="FitStatsに不具合が起きてます。一度FitStatsを終了させて再度お試しください。"
        isOpen={isOpen}
        onClose={onClose}
        alignDescriptionLeft
      />
    </>
  );
};

export default NewPasswordPage;
