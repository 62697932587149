import { useQuery } from 'react-query';
import Result, { Failure, Success } from 'global/utilities/result';
import createResult from 'global/utilities/create-result-from-query-result';
import { isErrorDTO } from 'data/dto/error-dto';
import DataConsentRepository, {
  ConsentCategory,
  ConsentItem,
  DataConsentRepositoryImpl,
} from 'features/my-data/data/repositories/data-consent-repository';
import { DateTime } from 'luxon';
import queryClient from 'global/query-client';
import { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router';
import { GENERAL_REQUEST_ERROR_MESSAGE } from 'global/constants';
import ClientCompanyRepository, {
  ClientCompanyRepositoryImpl,
} from '../data/repositories/client-company-repository';
import ClientCompanyViewData, {
  ConsentElement,
  StorageViewData,
} from '../view-data/client-company-view-data';
import convertDtoToClientCompanyViewData from './utils/convert-dto-to-client-company-view-data';
import updateConsentState from './utils/update-consent-state';
import buildConsentItems from './utils/build-consent-items';
import lastPageLoadAt, {
  LAST_PAGE_LOAD_AT_DATA_KEY,
} from './utils/last-page-load-at';
import PopupInfoViewData from '../../../profile/view-data/popup-info-view-data';
import PopupInfoViewDto from '../../../profile/data/dto/popup-info-dto';

const IS_NOTICE_CLOSED_DATA_KEY = '/client_company_notice_closed';
const CLIENT_COMPANY_CACHE_KEY = '/client_company';

export type ReturnType = {
  fetchResult: Result<ClientCompanyViewData, Error>;
  pageLoaded: () => void;
  storageData: StorageViewData;
  closeButtonTapped: () => void;
  didConsentChanged: (item: ConsentElement) => void;
  saveButtonTapped: () => void;
  isConsentStateChanged: boolean;
  popupInfoCount: number;
  setPopupInfoCount: (count: number) => void;
  submitAction: SubmitDialogActionType | undefined;
};

type AchieveActionDetailType = {
  actionId: number;
};

type SubmitDialogActionType = {
  title?: string;
  description?: string;
  primaryButton?: submitDialogButton;
};

type submitDialogButton = {
  text: string;
  onClick?: (() => void) | undefined;
};

const cachedClientCompanyData = (): ClientCompanyViewData | undefined =>
  queryClient.getQueryData(CLIENT_COMPANY_CACHE_KEY);

const useClientCompany = (
  clientCompanyRepository: ClientCompanyRepository = new ClientCompanyRepositoryImpl(),
  consentRepository: DataConsentRepository = new DataConsentRepositoryImpl(),
  saveResult: (result: Result<PopupInfoViewData, Error>) => void,
): ReturnType => {
  const history = useHistory();
  const savedConsentItems = useRef<ConsentItem[]>();
  const submitAction = useRef<SubmitDialogActionType | undefined>(undefined);

  useEffect(() => {
    const clearQuery = () => {
      queryClient.removeQueries({
        queryKey: CLIENT_COMPANY_CACHE_KEY,
      });
    };

    return clearQuery;
  }, []);

  const queryResult = useQuery<ClientCompanyViewData, Error>(
    CLIENT_COMPANY_CACHE_KEY,
    async () => {
      try {
        const clientCompanyDto = await clientCompanyRepository.fetch();
        const consentDto = await consentRepository.fetch(
          ConsentCategory.Company,
        );

        const clientCompanyViewData = convertDtoToClientCompanyViewData(
          clientCompanyDto,
          consentDto,
          lastPageLoadAt(),
        );

        savedConsentItems.current = buildConsentItems(clientCompanyViewData);

        return clientCompanyViewData;
      } catch (error) {
        if (isErrorDTO(error)) {
          throw Error(error.error.message);
        }
        throw Error(GENERAL_REQUEST_ERROR_MESSAGE);
      }
    },
  );

  const fetchResult = createResult(queryResult);

  const pageLoaded = () => {
    const now = DateTime.local();
    localStorage.setItem(LAST_PAGE_LOAD_AT_DATA_KEY, JSON.stringify(now));
  };

  const closeButtonTapped = () => {
    const formattedValue = JSON.stringify(true);

    localStorage.setItem(IS_NOTICE_CLOSED_DATA_KEY, formattedValue);
  };

  const isCloseButtonTapped = (): boolean => {
    const data: string | null = localStorage.getItem(IS_NOTICE_CLOSED_DATA_KEY);

    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const parsedData = data === null ? undefined : JSON.parse(data);

    return typeof parsedData === 'boolean' ? parsedData : false;
  };

  const didConsentChanged = (consentElement: ConsentElement): void => {
    const cachedData = cachedClientCompanyData();
    if (cachedData == null) {
      return;
    }

    const newClientCompanyData = updateConsentState(cachedData, consentElement);
    queryClient.setQueryData(CLIENT_COMPANY_CACHE_KEY, newClientCompanyData);
  };

  const convertDtoToViewData = (dto: PopupInfoViewDto): PopupInfoViewData =>
    dto;

  const [popupInfoCount, updatePopupInfoCount] = useState(0);
  const setPopupInfoCount = (count: number): void => {
    updatePopupInfoCount(() => count);
  };

  const saveButtonTapped = () => {
    const cachedData = cachedClientCompanyData();
    if (cachedData == null) {
      return;
    }

    const json: string | null = localStorage.getItem('achieveActionDetail');
    const detail =
      json == null ? null : (JSON.parse(json) as AchieveActionDetailType);

    const consentItems = buildConsentItems(cachedData);
    consentRepository
      .save(ConsentCategory.Company, consentItems, detail?.actionId)
      .then((value) => {
        const viewData = convertDtoToViewData(value);
        updatePopupInfoCount(() => viewData.popupInfo.length);
        savedConsentItems.current = consentItems;
        submitAction.current = {
          title: '内容が更新されました。',
          description: '企業からお得な情報が\n届くのをお楽しみに！',
          primaryButton: viewData.notAchieved
            ? { text: 'OK', onClick: () => history.push('/campaign') }
            : {
                text: 'マイデータTOPへ',
                onClick: () => history.push('/mydata'),
              },
        };
        localStorage.removeItem('achieveActionDetail');
        saveResult(new Success(viewData));
      })
      .catch((error) => {
        const errorMessage = isErrorDTO(error)
          ? error.error.message
          : 'データの公開先の更新に失敗しました。';
        submitAction.current = {
          title: 'エラー',
          description: errorMessage,
          primaryButton: { text: 'OK' },
        };
        saveResult(new Failure(Error(errorMessage)));
      });
  };

  const isConsentStateChanged =
    fetchResult.isSuccess() && savedConsentItems.current != null
      ? JSON.stringify(savedConsentItems.current) !==
        JSON.stringify(buildConsentItems(fetchResult.value))
      : false;

  return {
    fetchResult,
    pageLoaded,
    storageData: {
      isCloseButtonTapped: isCloseButtonTapped(),
    },
    closeButtonTapped,
    didConsentChanged,
    saveButtonTapped,
    isConsentStateChanged,
    popupInfoCount,
    setPopupInfoCount,
    submitAction: submitAction.current,
  };
};

export default useClientCompany;
