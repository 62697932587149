import RequestConfig, { URL } from "data/request-configs/request-config";
import ApiClient from "data/api-client/api-client";
import FitstatsPointDto from "../dto/fitstats-point-dto";

const createFitstatsPointRequestConfig = (): RequestConfig => ({
  endpoint: {
    baseUrl: URL.FIT_STATS,
    path: '/users/me/fit_stats_point/balance',
  },
  method: 'get',
  parameters: {},
});

interface FitstatsPointRepository {
  fetch: () => Promise<FitstatsPointDto>;
}

export class FitstatsPointRepositoryImpl implements FitstatsPointRepository {
  fetch = async (): Promise<FitstatsPointDto> =>
    new ApiClient().connect(createFitstatsPointRequestConfig());
}

export default FitstatsPointRepository;
