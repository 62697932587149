import { VFC } from 'react';
import Button from 'global/components/button/Button';
import Container from 'global/components/Container/Container';
import { useHistory } from 'react-router';
import styles from './BottomSheet.module.css';

type Props = {
  mainContent: string;
  subContent: string;
};

const BottomSheet: VFC<Props> = ({ mainContent, subContent }) => {
  const history = useHistory();

  return (
    <>
      <div className={styles.bottomSheetContainer}>
        <div className={styles.textContainer}>
          <Container marginRight="m" marginLeft="m">
            <div className={styles.mainContent}>{mainContent}</div>
          </Container>
          <Container>
            <div className={styles.subContent}>{subContent}</div>
          </Container>
        </div>
        <Container marginTop="l" marginBottom="s">
          <Button
            text="ログイン"
            type="default"
            size="large"
            onClick={() => {
              history.push('/sign_in');
            }}
          />
        </Container>
        <div className={styles.copyright}>
          Copyright &copy; Dai Nippon Printing Co., Ltd.
        </div>
      </div>
    </>
  );
};

export default BottomSheet;
