import { VFC } from "react";
import FitstatsPoint from "features/point/exchange/menu/components/fitstats-point/FitstatsPoint";
import Container from "global/components/Container/Container";
import UndraggableLink from "global/components/UndraggableLink/UndraggableLink";
import styles from './PointExchangeMenuPage.module.css';
import { DotmoneyItemId, DotmoneyItemIds } from "../view-data/my-data-top-view-data";

type Props = {
  dotmoneyLinkButtonTapped: (iid: DotmoneyItemId) => void;
};

const displayDotmoneyItemIds: DotmoneyItemId[] = [
  DotmoneyItemIds.PayPay,
  DotmoneyItemIds.AmazonGiftCard,
  DotmoneyItemIds.DPoint,
  DotmoneyItemIds.RakutenPoint,
  DotmoneyItemIds.Ponta,
  DotmoneyItemIds.Jal,
];

const PointExchangeMenuPage: VFC<Props> = ({
  dotmoneyLinkButtonTapped,
}) => (
  <div className={styles.container}>
    <div className={styles.pointSection}>
      <FitstatsPoint showHistoryLink />
    </div>

    <Container marginTop="s">
      <div className={styles.sectionTitle}>ポイントを交換する</div>
      <div className={styles.pointBannerWrapper}>
        {displayDotmoneyItemIds.map(itemId => (
          <div key={itemId} className={styles.pointBannerItem}>
            <button
              type="button"
              className={styles.transparentArea}
              onClick={() => dotmoneyLinkButtonTapped(itemId)}
            >
              <div className={`${styles.pointBanner} ${styles.pointBannerBalloon} ${styles.pointBannerDotmoney}`}>
                {/* キャッシュ対策のため、画像差し替え時は変更リリース日をクエリパラメータに設定 */}
                <img src={`/mydata/dotmoney_point_icon_${itemId}@3x.png?20240530`} alt="dotmoney_point_banner" />
              </div>
            </button>
          </div>
        ))}
        <div className={styles.pointBannerItem}>
          <UndraggableLink to="/honto_coupon" className={styles.dataSettingLink}>
            <div className={styles.pointBanner}>
              {/* キャッシュ対策のため、画像差し替え時は変更リリース日をクエリパラメータに設定 */}
              <img
                src="/mydata/honto_coupon_icon.png?20230821"
                alt="honto_coupon_banner"
              />
            </div>
          </UndraggableLink>
        </div>
      </div>
      <div className={styles.annotationArea}>
        <p>※FitStatsは大日本印刷株式会社による提供です。</p>
        <p>※Amazon、Amazon.co.jp、およびそれらのロゴはAmazon.com, Inc.またはその関連会社の商標です。</p>
      </div>
    </Container>
  </div>
);

export default PointExchangeMenuPage;
