import Dialog from 'global/components/dialog/Dialog';
import useDialog from 'global/components/dialog/use-dialog';
import Loading from 'global/components/Loading/Loading';
import { useState, VFC } from 'react';
import { useHistory, useLocation } from 'react-router';
import { isErrorDTO } from 'data/dto/error-dto';
import { TutorialLocationState } from 'features/tutorial/hooks/use-tutorial-navigation';
import { ProfileRepositoryImpl } from 'features/profile/data/repositories/profile-repository';
import { DataConsentRepositoryImpl } from 'features/my-data/data/repositories/data-consent-repository';
import CoarchMark from 'global/components/CoarchMark/CoarchMark';
import TutorialFetchErrorDialog from 'global/components/TutorialFetchErrorDialog/TutorialFetchErrorDialog';
import useNavigationBarItemsSetting from 'global/hooks/use-navigation-bar-items-setting';
import usePersonalDataProfile from '../hooks/use-personal-data-profile';
import PersonalDataPage from '../pages/PersonalDataPage';
import { PersonalDataRepositoryImpl } from '../data/repositories/personal-data-repository';

const TutorialPersonalDataContainer: VFC = () => {
  useNavigationBarItemsSetting('提供するデータを選ぶ', {
    isCloseButtonVisible: false,
    isMenuButtonVisible: false,
    isNotificationButtonVisible: false,
    isBackButtonVisible: false,
  });

  const { isOpen, dialogProps, requestShowing, onClose } = useDialog();
  const history = useHistory<TutorialLocationState>();
  const { state } = useLocation<TutorialLocationState | undefined>();
  const [currentOnCloseAction, setOnCloseAction] = useState(() => onClose);

  const {
    fetchResult,
    storageData,
    closeButtonTapped,
    didConsentChanged,
    saveButtonTapped,
  } = usePersonalDataProfile(
    (result) => {
      if (result.isSuccess()) {
        setOnCloseAction(() => ''); // チュートリアル中なので、閉じない
        history.push('/mydata/client_company', { isTutorial: true });
      } else if (result.isFailure()) {
        requestShowing({
          title: 'エラー',
          description: isErrorDTO(result.error)
            ? result.error.error.message
            : '公開するデータの更新に失敗しました。',
          primaryButton: { text: 'OK' },
        });
      }
    },
    new PersonalDataRepositoryImpl(),
    new ProfileRepositoryImpl(),
    new DataConsentRepositoryImpl(),
    state,
  );

  const PopOverValue = {
    children: (
      <div>
        次に、提供するデータを選んでみましょう。
        <br />
        <br />
        ここで選んだデータは、あなたが選択した企業にのみ提供されます。
      </div>
    ),
    clickOption: {
      clickWithCoarchMarkClosed: true,
    },
  };

  if (fetchResult.isSuccess()) {
    return (
      <>
        <Dialog
          title={dialogProps?.title}
          description={dialogProps?.description}
          imageSrc={dialogProps?.imageSrc}
          imageSize={dialogProps?.imageSize}
          primaryButton={dialogProps?.primaryButton ?? { text: 'OK' }}
          isOpen={isOpen}
          showsCloseButton
          onClose={currentOnCloseAction}
        />
        <PersonalDataPage
          personalData={fetchResult.value}
          storageData={storageData}
          closeButtonTapped={closeButtonTapped}
          didConsentChanged={didConsentChanged}
          saveButtonTapped={saveButtonTapped}
          locationState={state}
        />
        <CoarchMark
          elementId="tutorial-mydata-personal"
          popOverOption={PopOverValue}
        />
      </>
    );
  }

  if (fetchResult.isFailure()) {
    return <TutorialFetchErrorDialog />;
  }

  return <Loading />;
};

export default TutorialPersonalDataContainer;
