import { useRef, VFC } from 'react';
import Loading from 'global/components/Loading/Loading';
import useNavigationBarItemsSetting from 'global/hooks/use-navigation-bar-items-setting';
import ErrorContainer from 'global/components/ErrorContainer/ErrorContainer';
import { useHistory } from 'react-router';
import useDialog from 'global/components/dialog/use-dialog';
import Dialog from 'global/components/dialog/Dialog';
import ErrorDTO from 'data/dto/error-dto';
import useEmailRegistration from 'features/sign-up/email/registration/hooks/use-email-registration';
import { EmailRegistrationRepositoryImpl } from 'features/sign-up/email/registration/data/repositories/email-registration-repository';
import { TermsOfUseRepositoryImpl } from 'features/sign-up/terms-of-use/data/repositories/terms-of-use-repository';
import { PrivacyPolicyRepositoryImpl } from 'features/sign-up/privacy-policy/data/repositories/privacy-policy-repository';
import { ProfileRepositoryImpl } from 'features/profile/data/repositories/profile-repository';
import ProfileBasicPage from '../pages/ProfileBasicPage';

type DialogInfo = {
  description: string;
  primaryButtonAction: () => void;
};

const ProfileBasicContainer: VFC = () => {
  const history = useHistory();
  const { isOpen, requestShowing, onClose } = useDialog();
  const dialogInfoRef = useRef<DialogInfo | undefined>();
  const primaryButtonActionRef = useRef<() => void>(() => onClose());

  useNavigationBarItemsSetting('FitStats会員登録', {
    isCloseButtonVisible: false,
    isMenuButtonVisible: false,
    isNotificationButtonVisible: false,
    isBackButtonVisible: false,
  });

  const isAlreadyExistsError = (error: ErrorDTO | undefined) =>
    error && error.error.status === 'ALREADY_EXISTS';
  const {
    fetchTermsOfUseResult,
    fetchPrivacyPolicyResult,
    fetchProfileResult,
    termsOfUseDocVersion,
    privacyPolicyDocVersion,
    submitErrorMessage,
    submitted,
  } = useEmailRegistration(
    new TermsOfUseRepositoryImpl(),
    new PrivacyPolicyRepositoryImpl(),
    new ProfileRepositoryImpl(),
    new EmailRegistrationRepositoryImpl(),
    (result) => {
      if (result.isSuccess()) {
        history.push('/sign_up/email/authentication', {
          email: result.value,
        });
      }
      if (result.isFailure()) {
        const description = result.error
          ? result.error.error.message
          : 'メールアドレスの登録に失敗しました。';

        if (isAlreadyExistsError(result.error)) {
          primaryButtonActionRef.current = () => {
            onClose();
            history.push('/sign_in');
          };
        }

        dialogInfoRef.current = {
          description,
          primaryButtonAction: primaryButtonActionRef.current,
        };

        requestShowing();
      }
    },
  );

  if (
    fetchTermsOfUseResult.isSuccess() &&
    fetchPrivacyPolicyResult.isSuccess() &&
    fetchProfileResult.isSuccess()
  ) {
    return (
      <>
        {dialogInfoRef.current && (
          <Dialog
            title="エラー"
            description={submitErrorMessage}
            primaryButton={{
              text: 'OK',
              onClick: dialogInfoRef.current.primaryButtonAction,
            }}
            isOpen={isOpen}
            onClose={onClose}
          />
        )}
        <ProfileBasicPage
          result={fetchProfileResult.value}
          submitted={submitted}
          termsOfUseDocVersion={termsOfUseDocVersion}
          privacyPolicyDocVersion={privacyPolicyDocVersion}
        />
      </>
    );
  }

  if (fetchTermsOfUseResult.isFailure()) {
    return (
      <ErrorContainer>{fetchTermsOfUseResult.error.message}</ErrorContainer>
    );
  }

  if (fetchProfileResult.isFailure()) {
    return <ErrorContainer>{fetchProfileResult.error.message}</ErrorContainer>;
  }

  return <Loading />;
};

export default ProfileBasicContainer;
