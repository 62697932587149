import ApiClient from 'data/api-client/api-client';
import RequestConfig, { URL } from 'data/request-configs/request-config';
import NotAchievedProgramActionsCount from 'global/data/dto/not-achieved-program-actions-count';

const createFetchRequestConfig = (referAFriendFlag: boolean): RequestConfig => ({
  endpoint: {
    baseUrl: URL.FIT_STATS,
    path: '/mission_program/actions/not_achieved_count',
  },
  method: 'get',
  parameters: { refer_a_friend_flag: referAFriendFlag },
});

interface ProgramActionsNotAchievedCountRepository {
  fetch: (referAFriendFlag: boolean) => Promise<NotAchievedProgramActionsCount>;
}

export class ProgramActionsNotAchievedCountRepositoryImpl
  implements ProgramActionsNotAchievedCountRepository
{
  fetch = async (referAFriendFlag: boolean): Promise<NotAchievedProgramActionsCount> =>
    new ApiClient().connect(createFetchRequestConfig(referAFriendFlag));
}

export default ProgramActionsNotAchievedCountRepository;
