class EntryCampaignCode {
  // 他のID(number)と構造を同じにするためモデル化しているが、codeはキャストが必要無いから必要ないかもしれない
  value: string;

  constructor(value: string) {
    this.value = value;
  }

  // NOTE: numberのidの場合は、Number(value);している
  static fromString(value: string): EntryCampaignCode {
    const code = value;

    return new EntryCampaignCode(code);
  }
}

export default EntryCampaignCode;
