import { isErrorDTO } from 'data/dto/error-dto';
import Result, { Failure, Success } from 'global/utilities/result';
import UserRepository, {
  UserRepositoryImpl,
} from 'features/sign-up/terms-of-use/data/repositories/user-repository';
import { useRef } from 'react';
import AuthorizationRepository from 'features/sign-up/privacy-policy/data/repositories/authorization-repository';
import PartnersAuthorizationRepository from 'features/sign-up/data/repositories/partners-authorization-repository';

type ReturnType = {
  submitErrorMessage?: string;
  submitted: () => void;
};

const useAgreement = (
  onetimeToken: string | null,
  userRepository: UserRepository = new UserRepositoryImpl(),
  authorizationRepository = new AuthorizationRepository(),
  partnersAuthorizationRepository = new PartnersAuthorizationRepository(),
  saveResult: (result: Result<string, void>) => void,
): ReturnType => {
  const submitErrorMessage = useRef<string | undefined>(undefined);

  const submitted = async () => {
    try {
      await userRepository.create();
    } catch (error) {
      submitErrorMessage.current = isErrorDTO(error)
        ? error.error.message
        : 'ユーザーの仮登録に失敗しました';
      saveResult(new Failure(undefined));

      return;
    }

    try {
      const authorizationResult = await authorizationRepository.authorize();
      if (!onetimeToken) {
        throw new Error('could not retrieve onetime token.');
      }
      // partners apiへ認可リクエスト
      const partnersAuthorizationResult = await partnersAuthorizationRepository.authorize(authorizationResult, onetimeToken);
      saveResult(new Success(partnersAuthorizationResult.code));
    } catch (error) {
      submitErrorMessage.current = isErrorDTO(error)
        ? error.error.message
        : '認可画面へのリダイレクトに失敗しました';
      saveResult(new Failure(undefined));
    }
  };

  return {
    submitErrorMessage: submitErrorMessage.current,
    submitted,
  };
};

export default useAgreement;
