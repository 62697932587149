import { VFC } from 'react';
import { Switch, Route } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import WelcomeFirstContainer from 'features/sign-up/welcome/containers/first';
import WelcomeSecondContainer from 'features/sign-up/welcome/containers/second';

const WelcomeRouter: VFC<RouteComponentProps> = ({ match }) => (
  <Switch>
    <Route exact path={`${match.path}/first`}>
      <WelcomeFirstContainer />
    </Route>
    <Route exact path={`${match.path}/second`}>
      <WelcomeSecondContainer />
    </Route>
  </Switch>
);

export default WelcomeRouter;

