import EmailRegistrationRepository, {
  EmailRegistrationRepositoryImpl,
} from 'features/sign-up/email/registration/data/repositories/email-registration-repository';
import ErrorDTO, { isErrorDTO } from 'data/dto/error-dto';
import Result, { Failure, Success } from 'global/utilities/result';
import TermsOfUseAndPrivacyPolicyViewData from 'global/view-data/terms-of-use-and-privacy-policy-view-data';
import queryClient from 'global/query-client';
import { useRef } from 'react';
import TermsOfUseRepository, {
  TermsOfUseRepositoryImpl,
} from 'features/sign-up/terms-of-use/data/repositories/terms-of-use-repository';
import PrivacyPolicyRepository, {
  PrivacyPolicyRepositoryImpl,
} from 'features/sign-up/privacy-policy/data/repositories/privacy-policy-repository';
import { useQuery } from 'react-query';
import { GENERAL_REQUEST_ERROR_MESSAGE } from 'global/constants';
import convertDtoToTermsOfUseAndPrivacyPolicyViewData from 'features/terms-and-privacy-policy/utils/convert-dto-to-terms-of-use-and-privacy-policy-view-data';
import createResult from 'global/utilities/create-result-from-query-result';
import ProfileViewData, {
  ProfileSinglePageViewData,
} from 'features/profile/view-data/profile-view-data';
import ProfileRepository, {
  ProfileRepositoryImpl,
} from 'features/profile/data/repositories/profile-repository';
import ProfileDto from 'features/profile/data/dto/profile-dto';
import getProfileViewData from 'features/profile/utils/get-profile-view-data';
import { UserInputRawData } from 'features/profile/user-input-item';
import convertRecordToUserInputItems from 'features/profile/utils/convert-record-to-user-input-items';
import { PROFILE_CACHE_KEY } from '../../../profile/hooks/constants';

type ReturnType = {
  fetchTermsOfUseResult: Result<TermsOfUseAndPrivacyPolicyViewData, Error>;
  fetchPrivacyPolicyResult: Result<TermsOfUseAndPrivacyPolicyViewData, Error>;
  fetchProfileResult: Result<ProfileSinglePageViewData, Error>;
  termsOfUseDocVersion: string | undefined;
  privacyPolicyDocVersion: string | undefined;
  submitErrorMessage?: string;
  submitted: (userInput: UserInputRawData) => void;
};
const convertDtoToViewData = (dto: ProfileDto): ProfileViewData => dto;

const TERMS_OF_USE_QUERY_KEY = '/terms-of-use';
const PRIVACY_POLICY_QUERY_KEY = '/privacy-policy';

const cachedTermsOfUseViewData = ():
  | TermsOfUseAndPrivacyPolicyViewData
  | undefined => queryClient.getQueryData([TERMS_OF_USE_QUERY_KEY]);

const cachedPrivacyPolicyViewData = ():
  | TermsOfUseAndPrivacyPolicyViewData
  | undefined => queryClient.getQueryData([PRIVACY_POLICY_QUERY_KEY]);

const useEmailRegistration = (
  termsOfUseRepository: TermsOfUseRepository = new TermsOfUseRepositoryImpl(),
  privacyPolicyRepository: PrivacyPolicyRepository = new PrivacyPolicyRepositoryImpl(),
  profileRepository: ProfileRepository = new ProfileRepositoryImpl(),
  repository: EmailRegistrationRepository = new EmailRegistrationRepositoryImpl(),
  saveResult: (result: Result<string, ErrorDTO | undefined>) => void,
): ReturnType => {
  const submitErrorMessage = useRef<string | undefined>(undefined);

  // 個人情報の取り扱い
  const fetchTermsOfUseQueryResult = useQuery<
    TermsOfUseAndPrivacyPolicyViewData,
    Error
  >([TERMS_OF_USE_QUERY_KEY], async () => {
    const dto = await termsOfUseRepository.fetch().catch((error) => {
      if (isErrorDTO(error)) {
        throw Error(error.error.message);
      }
      throw Error(GENERAL_REQUEST_ERROR_MESSAGE);
    });

    return convertDtoToTermsOfUseAndPrivacyPolicyViewData(dto);
  });

  const fetchTermsOfUseResult = createResult(fetchTermsOfUseQueryResult);

  // プライバシーポリシー
  const fetchPrivacyPolicyQueryResult = useQuery<
    TermsOfUseAndPrivacyPolicyViewData,
    Error
  >([PRIVACY_POLICY_QUERY_KEY], async () => {
    const dto = await privacyPolicyRepository.fetch().catch((error) => {
      if (isErrorDTO(error)) {
        throw Error(error.error.message);
      }
      throw Error(GENERAL_REQUEST_ERROR_MESSAGE);
    });

    return convertDtoToTermsOfUseAndPrivacyPolicyViewData(dto);
  });

  const fetchPrivacyPolicyResult = createResult(fetchPrivacyPolicyQueryResult);

  // プロフィール
  const queryResult = useQuery<ProfileViewData, Error>(
    [PROFILE_CACHE_KEY],
    async () => {
      const dto = await profileRepository.fetchStatic().catch((error) => {
        if (isErrorDTO(error)) {
          throw Error(error.error.message);
        }
        throw Error(GENERAL_REQUEST_ERROR_MESSAGE);
      });

      return convertDtoToViewData(dto);
    },
    { cacheTime: Infinity }, // プロフィール入力中に長時間放置した場合にキャッシュが失われないように、勝手にはキャッシュが破棄されないようにする
  );

  const fetchProfileResult = getProfileViewData(
    createResult(queryResult),
    'profile_basic_info',
  );

  const termsOfUseDocVersion = cachedTermsOfUseViewData()?.docVersion;
  const privacyPolicyDocVersion = cachedPrivacyPolicyViewData()?.docVersion;

  const submitted = async (userInput: UserInputRawData): Promise<void> => {
    if (!termsOfUseDocVersion) {
      throw Error('利用規約の取得に失敗しました');
    }

    if (!privacyPolicyDocVersion) {
      throw Error('個人情報の取り扱いの取得に失敗しました');
    }

    const userInputItems = convertRecordToUserInputItems(userInput);
    const emailInput = userInputItems.find((item) => item.name === 'email');

    if (!emailInput || !emailInput.value) {
      submitErrorMessage.current = 'ユーザーの仮登録に失敗しました';
      saveResult(new Failure(undefined));

      return;
    }

    let emailValue: string;
    if (Array.isArray(emailInput.value)) {
      [emailValue] = emailInput.value;
    } else {
      emailValue = emailInput.value;
    }

    // emailにスペースが含まれている場合、バリデーションで弾いてしまうのがアプリの挙動として適切だが、
    // 下記の経緯のため、スペースのトリムによる実装とする。
    // ref. https://dnp-pjm-s0001.cloudmine.jp/issues/4698
    const replacedEmailValue: string = emailValue.replace(/\s+/g, "");
    try {
      await repository.save(replacedEmailValue);
      saveResult(new Success(replacedEmailValue));
    } catch (error) {
      submitErrorMessage.current = isErrorDTO(error)
        ? error.error.message
        : 'ユーザーの仮登録に失敗しました';
      const _ = isErrorDTO(error)
        ? saveResult(new Failure(error))
        : saveResult(new Failure(undefined));
    }
  };

  return {
    fetchTermsOfUseResult,
    fetchPrivacyPolicyResult,
    fetchProfileResult,
    termsOfUseDocVersion,
    privacyPolicyDocVersion,
    submitErrorMessage: submitErrorMessage.current,
    submitted,
  };
};

export default useEmailRegistration;
