import RequestConfig, { URL } from 'data/request-configs/request-config';
import ApiClient from 'data/api-client/api-client';
import EmailValidityCheckDto from '../dto/email-validity-check-dto';

const createEmailValidityCheckRequestConfig = (
  props: string,
): RequestConfig => ({
  endpoint: {
    baseUrl: URL.FIT_STATS,
    path: `/users/me/email/check_domain`,
  },
  method: 'get',
  parameters: { email: props },
});

interface EmailValidityCheckRepository {
  fetch: (email: string) => Promise<EmailValidityCheckDto>;
}

export class EmailValidityCheckRepositoryImpl
  implements EmailValidityCheckRepository
{
  fetch = async (email: string): Promise<EmailValidityCheckDto> =>
    new ApiClient().connect(createEmailValidityCheckRequestConfig(email));
}

export default EmailValidityCheckRepository;
