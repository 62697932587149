import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import queryClient from 'global/query-client';
import createResult from 'global/utilities/create-result-from-query-result';
import Result from 'global/utilities/result';
import requestWebCommand, {
  OpenExternalWeb,
  OpenLifelogBodyWeightTop,
  OpenLifelogMealTop,
  OpenLifelogSleepTop,
  OpenLifelogStepTop,
} from 'global/utilities/web-command';
import { isErrorDTO } from 'data/dto/error-dto';
import ProgramDetailRepository, {
  ProgramDetailRepositoryImpl,
} from '../data/repositories/program-detail-repository';
import {
  convertProgramDetailDtoToDomainObject,
  programDetailJoinedStateWhen,
} from '../data/domain-objects/utils/program-detail-dto-convert';
import ActionViewData, {
  ActionButtonStateViewObject,
  ActionProgressTypeViewObject,
  ActionRequirementsViewState,
} from '../view-data/action-view-data';
import ProgramDetailViewData, {
  ProgramDetailJoinedViewState,
} from '../view-data/program_detail-view-data';
import ProgramId from '../data/common/program-id';
import ProgramDetailDomainObject, {
  ProgramDetailJoinedState,
} from '../data/domain-objects/program-detail-domain-object';
import {
  actionAchieveStateWhen,
  actionProgressStateWhen,
  actionRequirementsDisableReasonWhen,
  actionRequirementsEnableStateWhen,
  actionRequirementsTypeWhen,
  convertActionDtoToDomainObject,
  rewardTitle,
  rewardValue,
} from '../data/domain-objects/utils/action-dto-convert';
import { missionTermStateWhen } from '../data/domain-objects/utils/mission-dto-convert';
import ActionId from '../data/common/action-id';
import ActionDomainObject from '../data/domain-objects/action-domain-object';
import { MissionTermState } from '../data/domain-objects/mission-domain-object';
import { ProgramDetailActionResponseDto } from '../data/dto/program-detail-dto';

const PROGRAM_DETAIL_VIEW_QUERY_KEY = '/program/detail/view';
const PROGRAM_DETAIL_DOMAIN_QUERY_KEY = '/program/detail/domain';

const cachedProgramDetailViewData = (
  programId: ProgramId,
): ProgramDetailViewData | undefined =>
  queryClient.getQueryData([PROGRAM_DETAIL_VIEW_QUERY_KEY, programId]);

const cachedProgramDetailDomainData = (
  programId: ProgramId,
): ProgramDetailDomainObject | undefined =>
  queryClient.getQueryData([PROGRAM_DETAIL_DOMAIN_QUERY_KEY, programId]);

type ReturnType = {
  fetchResult: Result<ProgramDetailViewData, Error>;
  joinProgramButtonTapped: () => void;
  leaveProgramButtonTapped: () => void;
  actionButtonTapped: (id: ActionId) => void;
};

const convertActionDomainObjectToViewData = (
  programJoinedState: ProgramDetailJoinedState,
  missionTermState: MissionTermState,
  action: ActionDomainObject,
): ActionViewData => ({
  id: action.id,
  title: action.title,
  reward: {
    titleText: rewardTitle(action.reward),
    iconPath: action.reward.iconPath,
    valueText: rewardValue(action.reward),
  },
  progressType: actionProgressStateWhen<ActionProgressTypeViewObject>({
    state: action.progressState,
    noNeed: () => ({ kind: 'noNeed' }),
    rate: (allCountText, achievedCountText, rateValue, unitSuffix) => ({
      kind: 'rate',
      denominatorText: allCountText,
      numeratorText: achievedCountText,
      progressValue: rateValue,
      unitSuffixText: unitSuffix,
    }),
  }),
  requirementsViewState:
    programDetailJoinedStateWhen<ActionRequirementsViewState>({
      state: programJoinedState,
      joined: (_) =>
        missionTermStateWhen<ActionRequirementsViewState>({
          state: missionTermState,
          before: () => ({ kind: 'empty' }),
          withIn: () => ({
            kind: 'exist',
            button: {
              state: actionAchieveStateWhen<ActionButtonStateViewObject>({
                state: action.achievementState,
                notStartedYet: () => ({
                  kind: 'notStartedYet',
                  buttonText: action.requirements.title,
                }),
                progressing: () =>
                  actionRequirementsEnableStateWhen<ActionButtonStateViewObject>(
                    {
                      state: action.requirements.enableState,
                      enable: () => ({
                        kind: 'progressing',
                        buttonText: action.requirements.title,
                      }),
                      disable: (reason) =>
                        actionRequirementsDisableReasonWhen<ActionButtonStateViewObject>(
                          {
                            reason,
                            todaysAchieved: () => ({
                              kind: 'todaysAchieved',
                              buttonText: '本日達成済み',
                            }),
                          },
                        ),
                    },
                  ),
                done: () => ({
                  kind: 'rewardCanBeReceived',
                  buttonText: '受け取る',
                }),
                rewardReceived: () => ({
                  kind: 'rewardReceived',
                  buttonText: '達成済み',
                }),
              }),
            },
          }),
          end: () => ({ kind: 'empty' }),
        }),
      notJoined: () => ({ kind: 'empty' }),
    }),
});

const convertDomainObjectToViewData = (
  domainObject: ProgramDetailDomainObject,
): ProgramDetailViewData => ({
  id: domainObject.id,
  title: domainObject.title,
  description: domainObject.description,
  termDescription: domainObject.termDescription,
  scoreDescription:
    domainObject.totalScore === 0 ? '' : `${domainObject.totalScore} 点`,
  pointDescription:
    domainObject.totalPoint === 0 ? '' : `${domainObject.totalPoint} pt`,
  bannerImage: domainObject.bannerImage,
  currentForcusMissionPanelIndex: domainObject.mission.items.findIndex(
    (e) => e.id.value === domainObject.mission.focusMissionId.value,
  ),
  missionCount: domainObject.mission.items.length,
  missions: domainObject.mission.items.map((mission) => ({
    id: mission.id,
    title: mission.title,
    isInTerm: mission.termState.kind === 'withIn',
    termDescription: mission.termState.description,
    actionList: mission.actionList.map((action) =>
      convertActionDomainObjectToViewData(
        domainObject.joinedState,
        mission.termState,
        action,
      ),
    ),
  })),
  joinedState: programDetailJoinedStateWhen<ProgramDetailJoinedViewState>({
    state: domainObject.joinedState,
    joined: (isCancelable) =>
      isCancelable
        ? { kind: 'joinedCancellable' }
        : { kind: 'joinedNotCancellable' },
    notJoined: () => ({ kind: 'notJoined' }),
  }),
  notice: { ...domainObject.notice },
  pointStatus: domainObject.pointStatus,
  pointAmount: domainObject.pointAmount,
});

const useProgramDetail = (
  programId: ProgramId,
  transitionNativeConfirm: (onTapDecided: () => void) => void,
  leaveConfirm: (
    viewData: ProgramDetailViewData,
    onTapDecided: () => void,
  ) => void,
  joinedAndLeaved: (viewData: ProgramDetailViewData) => void,
  receivedActionReward: (viewData: ActionViewData) => void,
  onError: (error: Error) => void,
  repository: ProgramDetailRepository = new ProgramDetailRepositoryImpl(),
): ReturnType => {
  const history = useHistory();

  // NOTE: 実機でのみ遷移直後ミッションパネルのスワイプ操作が効かないことがある。
  // 詳細原因不明だがスクリプト上で1タップ挟むことで状況改善が見られるのでこうしておく。
  useEffect(() => {
    window.ontouchstart = () => {
      ('');
    };
  }, []);

  const queryResult = useQuery<ProgramDetailViewData, Error>(
    [PROGRAM_DETAIL_VIEW_QUERY_KEY, programId],
    async () => {
      const dto = await repository.fetch(programId).catch((error) => {
        if (isErrorDTO(error)) {
          throw Error(error.error.message);
        }
        throw Error(
          `プログラム詳細の取得に失敗しました。id=${programId.value}`,
        );
      });

      // NOTE(smile-yoshryo): 物量も多いので生のサーバーレスポンスとクライアント定義のdomainレイヤーとで分けて変化に耐えうる構造にしとく
      const domainObject = convertProgramDetailDtoToDomainObject(dto);
      queryClient.setQueryData(
        [PROGRAM_DETAIL_DOMAIN_QUERY_KEY, programId],
        domainObject,
      );

      return convertDomainObjectToViewData(domainObject);
    },
  );

  const fetchResult = createResult(queryResult);

  const joinProgramButtonTapped = (): void => {
    const _ = repository
      .joinProgram(programId)
      .then((dto) => {
        const domainObject = convertProgramDetailDtoToDomainObject(dto);
        queryClient.setQueryData(
          [PROGRAM_DETAIL_DOMAIN_QUERY_KEY, programId],
          domainObject,
        );
        const viewData = convertDomainObjectToViewData(domainObject);
        queryClient.setQueryData(
          [PROGRAM_DETAIL_VIEW_QUERY_KEY, programId],
          viewData,
        );
        joinedAndLeaved(viewData);
      })
      .catch(() => {
        onError(Error('プログラムの参加に失敗しました。'));
      });
  };

  const leaveProgramButtonTapped = (): void => {
    const cached = cachedProgramDetailViewData(programId);
    if (cached) {
      leaveConfirm(cached, () => {
        const _ = repository
          .leaveProgram(programId)
          .then((dto) => {
            const domainObject = convertProgramDetailDtoToDomainObject(dto);
            queryClient.setQueryData(
              [PROGRAM_DETAIL_DOMAIN_QUERY_KEY, programId],
              domainObject,
            );
            const viewData = convertDomainObjectToViewData(domainObject);
            queryClient.setQueryData(
              [PROGRAM_DETAIL_VIEW_QUERY_KEY, programId],
              viewData,
            );
            joinedAndLeaved(viewData);
          })
          .catch(() => {
            onError(Error('プログラムの参加キャンセルに失敗しました。'));
          });
      });
    }
  };

  const actionButtonTapped = (actionId: ActionId): void => {
    const cached = cachedProgramDetailDomainData(programId);
    if (!cached) {
      return;
    }

    const actionParentMission = cached.mission.items.find(
      (m) => m.actionList.findIndex((a) => a.id.value === actionId.value) >= 0,
    );

    if (!actionParentMission) {
      return;
    }

    const actionDomainObject = actionParentMission.actionList.find(
      (a) => a.id.value === actionId.value,
    );

    if (!actionDomainObject) {
      return;
    }

    const updatedActionDomain = (
      dto: ProgramDetailActionResponseDto,
    ): ProgramDetailDomainObject => {
      const missions = cached.mission.items.map((m) => {
        const newMission = m;
        const index = m.actionList.findIndex(
          (a) => a.id.value === dto.action.id.value,
        );
        if (index >= 0) {
          newMission.actionList[index] = convertActionDtoToDomainObject(
            dto.action,
          );
        }

        return newMission;
      });

      return {
        ...cached,
        mission: {
          ...cached.mission,
          items: missions,
        },
      };
    };

    const requestSelfReporting = (): void => {
      const _ = repository
        .selfReportingAction(actionId)
        .then((dto) => {
          const domainObject = updatedActionDomain(dto);
          queryClient.setQueryData(
            [PROGRAM_DETAIL_DOMAIN_QUERY_KEY, programId],
            domainObject,
          );
          const viewData = convertDomainObjectToViewData(domainObject);
          queryClient.setQueryData(
            [PROGRAM_DETAIL_VIEW_QUERY_KEY, programId],
            viewData,
          );
        })
        .catch(() => {
          onError(Error('アクションの達成に失敗しました'));
        });
    };

    const requestReceiveActionReward = (): void => {
      const _ = repository
        .receiveActionReward(actionId)
        .then((dto) => {
          const domainObject = updatedActionDomain(dto);
          queryClient.setQueryData(
            [PROGRAM_DETAIL_DOMAIN_QUERY_KEY, programId],
            domainObject,
          );
          const viewData = convertDomainObjectToViewData(domainObject);
          queryClient.setQueryData(
            [PROGRAM_DETAIL_VIEW_QUERY_KEY, programId],
            viewData,
          );
          receivedActionReward(
            convertActionDomainObjectToViewData(
              domainObject.joinedState,
              actionParentMission.termState,
              convertActionDtoToDomainObject(dto.action),
            ),
          );
        })
        .catch(() => {
          onError(Error('アクションの報酬受け取りに失敗しました'));
        });
    };

    if (actionDomainObject.achievementState.kind === 'done') {
      requestReceiveActionReward();
    } else {
      actionRequirementsTypeWhen<void>({
        type: actionDomainObject.requirements.type,
        openExternalWebContents: (url) => {
          requestSelfReporting();
          requestWebCommand(new OpenExternalWeb(url));
        },
        selfReporting: () => {
          requestSelfReporting();
        },
        selfCheck: (url) => {
          history.push(url);
        },
        lifelogMealRecord: (__) => {
          transitionNativeConfirm(() =>
            requestWebCommand(new OpenLifelogMealTop()),
          );
        },
        lifelogStepRecord: () => {
          transitionNativeConfirm(() =>
            requestWebCommand(new OpenLifelogStepTop()),
          );
        },
        lifelogBodyWeightRecord: () => {
          transitionNativeConfirm(() =>
            requestWebCommand(new OpenLifelogBodyWeightTop()),
          );
        },
        lifelogSleepRecord: () => {
          transitionNativeConfirm(() =>
            requestWebCommand(new OpenLifelogSleepTop()),
          );
        },
        mydataUpdate: (url) => {
          const achieveActionDetail = {
            actionId: actionId.value,
          };
          localStorage.setItem(
            'achieveActionDetail',
            JSON.stringify(achieveActionDetail),
          );

          const disassembledUrl: string[] = url.split('/').filter(Boolean);
          const targetItem = disassembledUrl[disassembledUrl.length - 1];
          const targetUrl = `/${disassembledUrl
            .slice(0, disassembledUrl.length - 1)
            .join('/')}/`;

          history.push(targetUrl, {
            anchor: targetItem,
            achieveActionDetail,
          });
        },
        transitionInternalContents: (url) => {
          requestSelfReporting();
          history.push(url);
        },
      });
    }
  };

  return {
    fetchResult,
    joinProgramButtonTapped,
    leaveProgramButtonTapped,
    actionButtonTapped,
  };
};

export default useProgramDetail;
