import { useQuery } from 'react-query';
import { GENERAL_REQUEST_ERROR_MESSAGE } from 'global/constants';
import ProgramTopRepository, {
  ProgramTopRepositoryImpl,
} from '../data/repositories/program-top-repository';
import { isErrorDTO } from '../../../data/dto/error-dto';
import Result from '../../../global/utilities/result';
import createResult from '../../../global/utilities/create-result-from-query-result';
import ProgramTopViewData, {
  ProgramTopViewState,
} from '../view-data/program-top-view-data';
import ProgramTopDto, {
  ProgramTopContentsDto,
} from '../data/dto/program-top-dto';

type ReturnType = {
  fetchResult: Result<ProgramTopViewData, Error>;
};

const convertProgramContentsData = (
  contents: ProgramTopContentsDto,
): ProgramTopViewState =>
  contents.items?.length > 0
    ? {
        kind: 'exist',
        contents: contents.items.map((e) => ({
          id: e.id,
          title: e.title,
          bannerImage: e.bannerImage,
          termDescription: e.termDescription,
          scoreDescription:
            e.totalScore === 0
              ? ''
              : `獲得するライフスタッツ ${e.totalScore}点`,
          pointDescription:
            e.totalPoint === 0
              ? ''
              : `獲得するFitStatsポイント ${e.totalPoint}pt`,
        })),
      }
    : {
        kind: 'empty',
      };

const convertDtoToViewData = (dto: ProgramTopDto): ProgramTopViewData => ({
  notJoinedState: convertProgramContentsData(dto.notJoinedProgram),
  joinedState: convertProgramContentsData(dto.joinedProgram),
});

const useProgramTop = (
  repository: ProgramTopRepository = new ProgramTopRepositoryImpl(),
): ReturnType => {
  const referAFriendFlag: boolean = localStorage.getItem('refer_a_friend') === 'true';
  const queryResult = useQuery<ProgramTopViewData, Error>(
    ['/program/top'],
    async () => {
      const dto = await repository.fetch(referAFriendFlag).catch((error) => {
        if (isErrorDTO(error)) {
          throw Error(error.error.message);
        }
        throw Error(GENERAL_REQUEST_ERROR_MESSAGE);
      });

      return convertDtoToViewData(dto);
    },
  );

  const fetchResult = createResult(queryResult);

  return {
    fetchResult,
  };
};

export default useProgramTop;
