import useNavigationBarItemsSetting from "global/hooks/use-navigation-bar-items-setting";
import { VFC } from "react";
import ScrollToTopWhenMounted from "global/components/ScrollToTopWhenMounted/ScrollToTopWhenMounted";
import PointExchangeMenuPage from "../pages/PointExchangeMenuPage";
import usePointExchangeMenu from "../hooks/use-point-exchange-menu";

const PointExchangeMenuContainer: VFC = () => {
  const { dotmoneyLinkButtonTapped } = usePointExchangeMenu();

  useNavigationBarItemsSetting('ポイント');

  return (
    <ScrollToTopWhenMounted>
      <PointExchangeMenuPage
        dotmoneyLinkButtonTapped={dotmoneyLinkButtonTapped}
      />
    </ScrollToTopWhenMounted>
  );
}
export default PointExchangeMenuContainer;
