import { VFC } from 'react';
import Container, {
  MarginSize,
} from 'global/components/Container/Container';
import {
  FieldValues,
  UseFormRegister,
  FieldErrors,
  UseFormWatch,
  UseFormSetValue,
  UseFormSetError,
  UseFormClearErrors,
} from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { ProfileFormItemViewData } from 'features/profile/view-data/profile-view-data';
import { normalize } from '@geolonia/normalize-japanese-addresses';
import { FormTitleSize } from 'global/components/FormContainer/FormContainer';
import { InputTextSize } from 'global/components/InputText/InputText';
import { BirthDateSize } from 'global/components/InputBirthDate/InputBirthDate';
import { SelectSize } from 'global/components/Select/Select';
import { UserInputRawData } from '../user-input-item';
import FormItem from './FormItem';
import styles from './FormItemList.module.css';
import useAddressSearch from '../hooks/use-address-search';

type Props = {
  formItems: ProfileFormItemViewData[];
  register: UseFormRegister<FieldValues>;
  errors: FieldErrors<FieldValues>;
  watch: UseFormWatch<FieldValues>;
  ageChanged?: (age: number) => void;
  setValue: UseFormSetValue<UserInputRawData>;
  setError: UseFormSetError<UserInputRawData>;
  clearErrors: UseFormClearErrors<UserInputRawData>;
  itemMargin?: MarginSize;
  errorMargin?: MarginSize;
  itemTitleSize?: FormTitleSize;
  itemSize?: InputTextSize;
  birthDateSize?: BirthDateSize;
  selectSize?: SelectSize;
};

const FormItemList: VFC<Props> = ({
  formItems,
  register,
  errors,
  watch,
  ageChanged,
  setValue,
  setError,
  clearErrors,
  itemMargin = 10,
  errorMargin = 's',
  itemTitleSize = 'medium',
  itemSize = 'xs',
  birthDateSize = 'medium',
  selectSize = 'medium',
}) => {
  const { getAddress } = useAddressSearch();

  const replaceHanToZenInAddress = (raw: string) => {
    const replaceTable = [
      { from: '0', to: '０' },
      { from: '1', to: '１' },
      { from: '2', to: '２' },
      { from: '3', to: '３' },
      { from: '4', to: '４' },
      { from: '5', to: '５' },
      { from: '6', to: '６' },
      { from: '7', to: '７' },
      { from: '8', to: '８' },
      { from: '9', to: '９' },
      { from: '-', to: '－' },
    ];
    let replaced = raw;
    replaceTable.forEach((pattern) => {
      replaced = replaced.replaceAll(pattern.from, pattern.to);
    });

    return replaced;
  };

  const replaceHanToZenInBuildingName = async () => {
    // フォーカスが外れた直後に変換すると、画面上は変換が行われたように見えるが、
    // 内部データが変換されておらず、送信内容が半角のままになる。
    // （onBlurタイミングで内部データが書き換わらないのは仕様としてそうなっていそう https://github.com/react-hook-form/react-hook-form/issues/854 ）
    // これを防ぐため、0.1秒待機し、タイミングをずらして変換を行う。
    await new Promise((resolve) => setTimeout(resolve, 100));
    setValue('building_name', replaceHanToZenInAddress(watch('building_name')));
  };

  const addressSearch = () => {
    getAddress(watch('zip_code'), (addr) => {
      if (addr.region.length > 0) {
        normalize(addr.region + addr.locality + addr.street + addr.extended)
          .then((result) => {
            // levelは「どこまで正規化できたか」で、丁目番地が正規化できた場合は最大の3になる
            if (result.level === 3) {
              setValue('prefecture', addr.region);
              setValue('city', result.city);
              setValue(
                'address_number',
                replaceHanToZenInAddress(result.town + result.addr),
              );
            } else {
              setValue('prefecture', addr.region);
              setValue('city', addr.locality);
              setValue(
                'address_number',
                replaceHanToZenInAddress(addr.street + addr.extended),
              );
            }
          })
          .catch(() => {
            setValue('prefecture', addr.region);
            setValue('city', addr.locality);
            setValue(
              'address_number',
              replaceHanToZenInAddress(addr.street + addr.extended),
            );
          });
      } else {
        setError('zip_code', {
          type: 'manual',
          message: '該当する住所が見つかりませんでした。',
        });
      }
    });
  };

  const normalizeAddress = () => {
    const prefecture = watch('prefecture') as string;
    const city = watch('city') as string;
    const addressNumber = watch('address_number') as string;

    normalize(prefecture + city + addressNumber)
      .then((result) => {
        // levelは「どこまで正規化できたか」で、丁目番地が正規化できた場合は最大の3になる
        if (result.level === 3) {
          setValue(
            'address_number',
            replaceHanToZenInAddress(result.town + result.addr),
          );
        }
      })
      .catch(() => {
        // do nothing
      });
  };

  const validateBirthday = () => {
    const year = Number(watch('year'));
    const month = Number(watch('month'));
    const day = Number(watch('day'));
    const lastday = new Date(year, month, 0).getDate();
    if (day > lastday) {
      setError('birthday', {
        type: 'manual',
        message: '生年月日は必須項目です。',
      });
      setValue('day', '');
    }
    if (new Date() < new Date(year, month - 1, day)) {
      setError('birthday', {
        type: 'manual',
        message: '生年月日が正しくありません。',
      });
    } else {
      clearErrors('birthday');
    }
  };

  return (
    <div>
      {formItems.map((item) => (
        <Container marginTop={itemMargin} marginBottom={6}>
          <FormItem
            formItem={item}
            register={register}
            watch={watch}
            ageChanged={ageChanged}
            addressSearchButtonTapped={addressSearch}
            normalizeAddress={normalizeAddress}
            replaceHanToZenInBuildingName={replaceHanToZenInBuildingName}
            validateBirthday={validateBirthday}
            itemTitleSize={itemTitleSize}
            itemSize={itemSize}
            birthDateSize={birthDateSize}
            selectSize={selectSize}
          />
          <Container marginTop={errorMargin} marginBottom={errorMargin}>
            <ErrorMessage
              errors={errors}
              name={item.inputElement.name}
              render={({ message }) => (
                <div className={styles.errorMessage}>{message}</div>
              )}
            />
          </Container>
        </Container>
      ))}
    </div>
  );
};

export default FormItemList;
