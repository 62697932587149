import { VFC } from 'react';
import styles from './Button.module.css';

type ButtonType = 'default' | 'primary' | 'destructive';
type ButtonSize = 'large' | 'medium' | 'small';
type IconType = 'external';
type DisabledType = 'light' | 'dark'

type Props = {
  text: string;
  type: ButtonType;
  size: ButtonSize;
  disabled?: boolean;
  onClick: () => void;
  iconType?: IconType;
  disabledType?: DisabledType;
};

const classNameForButtonType = (buttonType: ButtonType): string => {
  switch (buttonType) {
    case 'default':
      return styles.buttonDefault;
    case 'primary':
      return styles.buttonPrimary;
    case 'destructive':
      return styles.buttonDestructive;
    default:
      return '';
  }
};

const classNameForButtonSize = (buttonSize: ButtonSize): string => {
  switch (buttonSize) {
    case 'large':
      return styles.buttonLarge;
    case 'medium':
      return styles.buttonMedium;
    case 'small':
      return styles.buttonSmall;
    default:
      return '';
  }
};

const classNameForIcon = (type: IconType): string => {
  switch (type) {
    case 'external':
      return styles.iconExternal;
    default:
      return '';
  }
};

const classNameForDisabledType = (disabledType?: DisabledType): string => {
  switch (disabledType) {
    case 'light':
      return styles.buttonDisabled;
    case 'dark':
      return styles.buttonDisabledDark;
    default:
      return styles.buttonDisabled;
  }
}

const Button: VFC<Props> = ({
  text,
  type,
  size,
  disabled = false,
  onClick,
  iconType,
  disabledType,
}) => (
  <button
    type="button"
    disabled={disabled}
    className={[
      styles.button,
      classNameForButtonType(type),
      classNameForButtonSize(size),
      disabled ? classNameForDisabledType(disabledType) : '',
      iconType && classNameForIcon(iconType),
    ].join(' ')}
    onClick={onClick}
  >
    {text}
  </button>
);

export default Button;
