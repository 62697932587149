import ProgramDetailDto from '../../dto/program-detail-dto';
import ProgramDetailDomainObject, {
  ProgramDetailJoinedState,
} from '../program-detail-domain-object';
import { convertMissionDtoToDomainObject } from './mission-dto-convert';

export const programDetailJoinedStateWhen = <T>({
  state,
  joined,
  notJoined,
}: {
  state: ProgramDetailJoinedState;
  joined: (isCancellable: boolean) => T;
  notJoined: () => T;
}): T => {
  switch (state.kind) {
    case 'joined':
      return joined(state.isCancellable);
    case 'notJoined':
      return notJoined();
    default:
      throw new Error(`Unexpected Object`);
  }
};

export const convertProgramDetailDtoToDomainObject = (
  dto: ProgramDetailDto,
): ProgramDetailDomainObject => ({
  id: dto.id,
  title: dto.title,
  description: dto.description,
  termDescription: dto.termDescription,
  totalScore: dto.totalScore,
  totalPoint: dto.totalPoint,
  bannerImage: dto.bannerImage,
  joinedState: dto.isJoined
    ? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      { kind: 'joined', isCancellable: dto.isJoinedTrueDetail!.isCancellable }
    : { kind: 'notJoined' },
  mission: {
    focusMissionId: dto.mission.focusMissionId,
    items: dto.mission.items.map((e) => convertMissionDtoToDomainObject(e)),
  },
  notice: { ...dto.notice },
  pointStatus: dto.pointStatus,
  pointAmount: dto.pointAmount,
});
