import Badge from 'global/components/Badge/Badge';
import Container from 'global/components/Container/Container';
import { VFC } from 'react';
import UndraggableLink from 'global/components/UndraggableLink/UndraggableLink';
import { useHistory } from 'react-router';
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
} from '@chakra-ui/react';
import Button from 'global/components/button/Button';
import requestWebCommand, {
  OpenExternalWeb,
} from 'global/utilities/web-command';
import styles from './MyDataTopPage.module.css';
import useSelfCheck from '../hooks/use-self-check';

type Props = {
  hasNewClientCompany: boolean;
};

const MyDataTopPage: VFC<Props> = ({ hasNewClientCompany }) => {
  const history = useHistory();
  const { fetchResult } = useSelfCheck();
  const descriptionPageUrl =
    'https://help.fitstats-dnp.com/--61aec78d977337001e95d411?inapp=true';

  return (
    <div className={styles.container}>
      <Container marginTop="s">
        <Container marginTop="s">
          <div className={styles.section}>
            <div className={styles.sectionHeader}>
              <h2>
                <span>1.</span> データを登録する
              </h2>
              <button
                type="button"
                className={styles.helpButton}
                onClick={() =>
                  requestWebCommand(new OpenExternalWeb(descriptionPageUrl))
                }
              >
                <div className={styles.displayIcon} />
                登録できるデータ
              </button>
            </div>
            <p>
              プロフィールやライフスタイルなどあなたのデータを登録しましょう。
            </p>

            <div className={styles.content}>
              <div className={styles.item}>
                <UndraggableLink
                  to="/mydata/personal/edit"
                  className={styles.dataSettingLink}
                >
                  <div className={styles.dataSettingItem}>
                    <img
                      src="/mydata/mydata_icon_personal.png"
                      className={styles.dataSettingIcon}
                      alt=""
                    />
                    <Container marginLeft="s" marginRight="s">
                      <div className={styles.dataSettingTitle}>
                        1-1. 基本データ
                      </div>
                    </Container>
                  </div>
                </UndraggableLink>
              </div>

              <Accordion className={styles.item} allowToggle>
                <AccordionItem>
                  {({ isExpanded }) => (
                    <>
                      <div className={styles.dataSettingItem}>
                        <AccordionButton
                          _hover={{ background: 'none' }}
                          _focus={{ boxShadow: 'none' }}
                          height={42}
                          padding={0}
                          sx={{ color: '#979797' }}
                        >
                          <img
                            src="/mydata/selfcheck_icon.png"
                            className={styles.dataSettingIcon}
                            alt=""
                          />
                          <Container marginLeft="s">
                            <div className={styles.dataSettingTitle}>
                              1-2. セルフチェック
                            </div>
                          </Container>
                          {isExpanded ? (
                            <img
                              src="/mydata/close@3x.png"
                              className={styles.accordionIcon}
                              alt=""
                            />
                          ) : (
                            <img
                              src="/mydata/open@3x.png"
                              className={styles.accordionIcon}
                              alt=""
                            />
                          )}
                        </AccordionButton>
                      </div>
                      {fetchResult.isSuccess() &&
                        fetchResult.value.categories.map((category) => (
                          <AccordionPanel pr={2} pl={2} pt={0} pb={0}>
                            <hr className={styles.selfCheckHr} />
                            <div
                              className={styles.selfCheckContainer}
                              key={category.id}
                            >
                              <img
                                src={`/mydata/questionnaire_category_${category.id}.svg`}
                                alt="icon"
                                className={styles.selfCheckIcon}
                              />
                              <div className={styles.selfCheckDetail}>
                                <div
                                  className={styles.selfCheckName}
                                >{`${category.name}について`}</div>
                                <div className={styles.selfCheckProgress}>
                                  {category.answeredQuestionsCount}
                                  <div className={styles.selfCheckTotal}>
                                    &nbsp;/&nbsp;{category.totalQuestionsCount}
                                  </div>
                                  <div className={styles.selfCheckTotalUnit}>
                                    問
                                  </div>
                                </div>
                              </div>
                              <div className={styles.selfCheckButton}>
                                <div className={styles.selfCheckEnableButton}>
                                  <Button
                                    text="回答する"
                                    type="default"
                                    size="small"
                                    onClick={() =>
                                      history.push(
                                        `/questionnaires/categories/${category.id}`,
                                      )
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </AccordionPanel>
                        ))}
                    </>
                  )}
                </AccordionItem>
              </Accordion>
            </div>
          </div>
        </Container>

        <Container marginTop="s">
          <div className={styles.section}>
            <div className={styles.sectionHeader}>
              <h2>
                <span>2.</span> データ提供する企業を選ぶ
              </h2>
            </div>
            <p>
              データの利用目的を確認し、お得な情報を受け取りたい企業を選びましょう。
            </p>
            <div className={styles.content}>
              <div className={styles.item}>
                <UndraggableLink
                  to="/mydata/client_company"
                  className={styles.dataSettingLink}
                >
                  <div className={styles.dataSettingItem}>
                    <img
                      src="/mydata/mydata_icon_disclosure.png"
                      className={styles.dataSettingIcon}
                      alt=""
                    />
                    <Container marginLeft="s" marginRight="s">
                      <div className={styles.dataSettingTitle}>企業一覧</div>
                      {hasNewClientCompany && (
                        <>
                          <div className={styles.dataSettingItemBadge}>
                            <Badge type={{ kind: 'unread' }} />
                          </div>
                          <div className={styles.dataSettingItemSupplement}>
                            新規追加された企業があります
                          </div>
                        </>
                      )}
                    </Container>
                  </div>
                </UndraggableLink>
              </div>
            </div>
          </div>
        </Container>

        <Container marginTop="s">
          <div className={styles.section}>
            <div className={styles.sectionHeader}>
              <h2>
                <span>3.</span> 提供するデータを選ぶ
              </h2>
            </div>
            <p>提供するデータが多いほどあなたに合った情報が届きます。</p>

            <div className={styles.content}>
              <div className={styles.item}>
                <UndraggableLink
                  to="/mydata/personal"
                  className={styles.dataSettingLink}
                >
                  <div className={styles.dataSettingItem}>
                    <img
                      src="/mydata/mydata_icon_select.png"
                      className={styles.dataSettingIcon}
                      alt=""
                    />
                    <Container marginLeft="s" marginRight="s">
                      <div className={styles.dataSettingTitle}>
                        データ項目一覧
                      </div>
                    </Container>
                  </div>
                </UndraggableLink>
              </div>
            </div>
          </div>
        </Container>
      </Container>
    </div>
  );
};

export default MyDataTopPage;
