import { VFC } from 'react';
import {
  UserOfferViewData,
  UserOfferTypeViewData,
} from '../view-data/offer-top-view-data';
import styles from './OfferListItem.module.css';
import Badge from '../../../global/components/Badge/Badge';

type Props = {
  offer: UserOfferViewData;
  onTapOffersItem: ((id: string, type: UserOfferTypeViewData) => void) | undefined;
};

const OfferListItem: VFC<Props> = ({ offer, onTapOffersItem }) => (
  <>
    <div key={offer.offerId}>
      <button
        type="button"
        className={styles.bodyButton}
        onClick={(onTapOffersItem === undefined) ? undefined : () => onTapOffersItem(offer.offerId, offer.offerType)}
        disabled={!onTapOffersItem}
      >
        {!offer.isOfferOpened && (
          <div className={styles.unreadBadge}>
            <Badge type={{ kind: 'unread' }} />
          </div>
        )}
        <div className={styles.offerContents}>
          <div>
            <div className={styles.offerFeaturedImage}>
              <img
                src={offer.iconImageUrl}
                alt="featured_image"
                onError={(e) => {
                  e.currentTarget.src = '/global/fit-stats_icon.svg';
                  e.currentTarget.style.opacity = '0.2';
                }}
              />
            </div>
            <div className={styles.offerType}>{offer.offerType.text}</div>
          </div>
          <div className={styles.offerSummary}>
            <div className={styles.offerMessage}>{offer.message}</div>
            <div className={styles.offerCompanyName}>{offer.companyName}</div>
            <div className={styles.offerMisc}>
              <span className={styles.offerPoint}>
                {offer.rewardDescription}
              </span>
              <span className={styles.offerExpirationDate}>
                {offer.termDescription}
              </span>
            </div>
          </div>
        </div>
      </button>
    </div>
  </>
);

export default OfferListItem;
