import { Divider } from '@chakra-ui/react';
import { DeleteIntroductionProgressRepositoryImpl } from 'features/introduction-progress/data/repositories/delete-introduction-progress-repository';
import useDeleteIntroductionProgress from 'features/introduction-progress/hooks/use-delete-introduction-progress';
import BottomContainer from 'global/components/BottomContainer/BottomContainer';
import Button from 'global/components/button/Button';
import Container from 'global/components/Container/Container';
import FormContainer from 'global/components/FormContainer/FormContainer';
import InputTel from 'global/components/InputTel/InputTel';
import { useRef, VFC } from 'react';
import { useForm } from 'react-hook-form';
import styles from './PhoneNumberRegistrationPage.module.css';

type Props = {
  submitted: (phoneNumber: string) => void;
};

type FormData = {
  phoneNumber: string;
};

type Profile = {
  sex: string;
  birthday: string;
  year: string;
  month: string;
  day: string;
  prefecture: string;
  city: string;
};

type Account = {
  email: string;
  password: string;
  phoneNumber: string;
  profile: Profile;
  privacyPolicyVersion: string;
  termsOfUseVersion: string;
};

const PhoneNumberRegistrationPage: VFC<Props> = ({ submitted }) => {
  const { deleteResult } = useDeleteIntroductionProgress(
    new DeleteIntroductionProgressRepositoryImpl,
    'localStorageMissing'
  );
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({ mode: 'onBlur' });

  // TODO: キャストが安全ではないので修正する
  const fetchAccountFromLocalStorage = (): Account | null => {
    const inputAccount: string | null = localStorage.getItem('input_account');

    return inputAccount ? (JSON.parse(inputAccount) as Account) : null;
  };

  const fetchPhoneNumberFromLocalStorage = (
    account: Account | null,
  ): string | null => (account ? account.phoneNumber : null);

  const phoneNumber = fetchPhoneNumberFromLocalStorage(
    fetchAccountFromLocalStorage(),
  );
  if (!phoneNumber) {
    deleteResult();
  }

  const processing = useRef(false);
  const onSubmit = (data: FormData) => {
    // TODO: 今後のことを考えると専用のコンポーネントにするのが望ましい
    // 処理中(true)なら非同期処理せずに抜ける
    if (processing.current) return;

    // 処理中フラグを上げる
    processing.current = true;

    // 疑似非同期処理
    setTimeout(() => {
      // 処理中フラグを下げる
      processing.current = false;
    }, 3000);

    submitted(data.phoneNumber);
  };

  const name = 'phoneNumber';
  const options = {
    value: phoneNumber as string,
  };

  const registration = register(name, options);

  const inputTel = (
    <InputTel
      key={name}
      name={name}
      size="large"
      hidden
      register={registration}
    />
  );

  return (
    <>
      <Container marginLeft="s" marginRight="s">
        <p className={styles.topMessage}>
          メールアドレスでの本人確認が完了しました。
          <br />
          <br />
          引き続き、より安全にお使いいただくために、
          <br />
          電話番号のSMS認証を行います。
          <br />
          <br />
          「認証コードを受け取る」ボタンをタップして、
          <br />
          届いたSMS認証コードを入力してください。
        </p>
        <Container marginTop="s">
          <FormContainer>{[inputTel]}</FormContainer>
          <p className={styles.errorMessage}>{errors?.phoneNumber?.message}</p>
        </Container>
      </Container>

      <div className={styles.bottomContainer}>
        <BottomContainer>
          <Divider orientation="horizontal" />
          <Container
            marginTop="s"
            marginBottom="s"
            marginLeft="s"
            marginRight="s"
          >
            <Button
              text="認証コードを受け取る"
              type="primary"
              size="large"
              disabled={false}
              onClick={handleSubmit(onSubmit)}
            />
          </Container>
        </BottomContainer>
      </div>
    </>
  );
};

export default PhoneNumberRegistrationPage;
