import { VFC } from 'react';
import QuestionnaireDetailPage from 'features/questionnaire/pages/QuestionnaireDetailPage';
import useQuestionnaireDetail from 'features/questionnaire/hooks/use-questionnaire-detail';
import Loading from 'global/components/Loading/Loading';
import { useHistory, useParams } from 'react-router-dom';
import DialogPrompt from 'global/components/dialog-prompt/DialogPrompt';
import Dialog from 'global/components/dialog/Dialog';
import useDialog from 'global/components/dialog/use-dialog';
import useNavigationBarItemsSetting from 'global/hooks/use-navigation-bar-items-setting';
import ErrorContainer from 'global/components/ErrorContainer/ErrorContainer';
import requestWebCommand, {
  OpenExternalWeb,
} from 'global/utilities/web-command';
import { QuestionnaireRepositoryImpl } from '../data/repositories/questionnaire-repository';
import { PopupInfoDetailViewData } from '../view-data/popup-info-view-data';

export const reveiveFitStatsPointPage =
  'https://help.fitstats-dnp.com/--61aec78c323140001dfd1217';

const QuestionnaireDetailContainer: VFC = () => {
  const { categoryId } = useParams<{ categoryId: string }>();
  const { isOpen, dialogProps, requestShowing, onClose } = useDialog();
  const history = useHistory();

  let popupInfo: PopupInfoDetailViewData[];

  const showPopup = (): void => {
    const info = popupInfo.pop();
    if (info?.title && info?.description) {
      requestShowing({
        title: info.title,
        description: info.description,
        imageSrc: '/finc_landing_page/content_2-1.png',
        imageSize: 'large',
        primaryButton: {
          text: '今すぐチェック！',
          onClick: () => {
            requestWebCommand(new OpenExternalWeb(reveiveFitStatsPointPage));
          },
        },
      });
    }
  };

  const {
    fetchResult,
    currentPage,
    checkboxChecked,
    nextButtonTapped,
    prevButtonTapped,
    submitted,
    isAnswerChanged,
    categoryName,
  } = useQuestionnaireDetail(
    categoryId,
    new QuestionnaireRepositoryImpl(),
    (saveResult) => {
      if (saveResult.isSuccess()) {
        if (saveResult.value) {
          popupInfo = saveResult.value.popupInfo;
          if (popupInfo.length !== 0) {
            showPopup();
          } else {
            requestShowing({
              title: '回答を完了しました',
              description:
                'セルフチェックで回答した内容が、ライフスタッツに反映されます。',
              primaryButton: saveResult.value.notAchieved
                ? { text: 'OK', onClick: () => history.push('/campaign') }
                : {
                    text: 'OK',
                    onClick: () => history.push('/mydata'),
                  },
            });
          }
        }
      }
      if (saveResult.isFailure()) {
        requestShowing({
          description: saveResult.error.message,
          primaryButton: {
            text: 'OK',
          },
        });
      }
    },
  );

  useNavigationBarItemsSetting(categoryName ? `${categoryName}について` : '');

  if (fetchResult.isSuccess()) {
    const hasNextPage = fetchResult.value.pages.length > currentPage;
    const hasPrevPage = currentPage > 1;

    return (
      <>
        <DialogPrompt
          when={isAnswerChanged}
          dialogProps={{
            description:
              'セルフチェックを回答中です。\n回答を完了せず、終了してよろしいですか？',
            primaryButtonTitle: '回答に戻る',
            destructiveButtonTitle: 'はい',
          }}
        />
        <Dialog
          title={dialogProps?.title}
          description={dialogProps?.description}
          imageSrc={dialogProps?.imageSrc}
          imageSize={dialogProps?.imageSize}
          primaryButton={dialogProps?.primaryButton ?? { text: 'OK' }}
          isOpen={isOpen}
          onClose={onClose}
        />
        <QuestionnaireDetailPage
          questionnaire={fetchResult.value.pages[currentPage - 1]}
          hasPrevPage={hasPrevPage}
          hasNextPage={hasNextPage}
          checkboxChecked={checkboxChecked}
          nextButtonTapped={nextButtonTapped}
          prevButtonTapped={prevButtonTapped}
          submitted={submitted}
        />
      </>
    );
  }

  if (fetchResult.isFailure()) {
    return <ErrorContainer>{fetchResult.error.message}</ErrorContainer>;
  }

  return <Loading />;
};

export default QuestionnaireDetailContainer;
