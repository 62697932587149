import { VFC } from 'react';
import { Switch, Route } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import TermsOfUseContainer from './terms-of-use/containers';
import PrivacyPolicyContainer from './privacy-policy/containers';

const AgreementRouter: VFC<RouteComponentProps> = ({ match }) => (
  <Switch>
    <Route exact path={`${match.path}/privacy_policy`}>
      <PrivacyPolicyContainer />
    </Route>
    <Route exact path={`${match.path}/terms_of_use`}>
      <TermsOfUseContainer />
    </Route>
  </Switch>
);

export default AgreementRouter;
