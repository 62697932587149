import ApiClient from '../../../../data/api-client/api-client';
import RequestConfig, {
  URL,
} from '../../../../data/request-configs/request-config';
import ProgramTopDto from '../dto/program-top-dto';

const createProgramTopRequestConfig = (referAFriendFlag: boolean): RequestConfig => ({
  endpoint: {
    baseUrl: URL.FIT_STATS,
    path: '/mission_program/top',
  },
  method: 'get',
  parameters: {refer_a_friend_flag: referAFriendFlag},
});

interface ProgramTopRepository {
  fetch: (referAFriendFlag: boolean) => Promise<ProgramTopDto>;
}

export class ProgramTopRepositoryImpl implements ProgramTopRepository {
  fetch = async (referAFriendFlag: boolean): Promise<ProgramTopDto> =>
    new ApiClient().connect(createProgramTopRequestConfig(referAFriendFlag));
}

export default ProgramTopRepository;
