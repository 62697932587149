import { useRef, VFC } from 'react';
import { useHistory } from 'react-router';

import Loading from 'global/components/Loading/Loading';
import useNavigationBarItemsSetting from 'global/hooks/use-navigation-bar-items-setting';
import ErrorContainer from 'global/components/ErrorContainer/ErrorContainer';
import useDialog from 'global/components/dialog/use-dialog';
import Dialog from 'global/components/dialog/Dialog';
import useProfileBasic from 'features/sign-up/profile/hooks/use-profile-basic';
import ProfileNewPage from '../pages/ProfileNewPage';

type DialogInfo = {
  description: string;
  primaryButtonAction: () => void;
};

const ProfileNewContainer: VFC = () => {
  const { isOpen, requestShowing, onClose } = useDialog();
  const dialogInfoRef = useRef<DialogInfo | undefined>();
  const primaryButtonActionRef = useRef<() => void>(() => onClose());
  const history = useHistory();

  useNavigationBarItemsSetting('プロフィールの登録', {
    isCloseButtonVisible: false,
    isMenuButtonVisible: false,
    isNotificationButtonVisible: false,
    isBackButtonVisible: false,
  });

  const {
    fetchResult,
    submitErrorMessage,
    submitted,
  } = useProfileBasic(
    (result) => {
      if (result.isSuccess()) {
        history.push('/top', { isTutorial: true });
      }
      if (result.isFailure()) {
        const description = result.error
          ? result.error.message
          : 'プロフィールの登録に失敗しました';

        dialogInfoRef.current = {
          description,
          primaryButtonAction: primaryButtonActionRef.current,
        };

        requestShowing();
      }
    },
  );

  if (fetchResult.isSuccess()) {
    return (
      <>
        {dialogInfoRef.current && (
          <Dialog
            title="エラー"
            description={submitErrorMessage}
            primaryButton={{
              text: 'OK',
              onClick: dialogInfoRef.current.primaryButtonAction,
            }}
            isOpen={isOpen}
            onClose={onClose}
          />
        )}
        <ProfileNewPage
          inputProfileItems={fetchResult.value}
          submitted={submitted}
        />
      </>
    );
  }

  if (fetchResult.isFailure()) {
    return <ErrorContainer>{fetchResult.error.message}</ErrorContainer>;
  }

  return <Loading />;
};

export default ProfileNewContainer;
