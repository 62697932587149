import { isErrorDTO } from 'data/dto/error-dto';
import Dialog from 'global/components/dialog/Dialog';
import useDialog from 'global/components/dialog/use-dialog';
import requestWebCommand, {
  OpenExternalWeb,
} from 'global/utilities/web-command';
import { useEffect, VFC } from 'react';
import { useHistory } from 'react-router';
import { UserAnnouncementModalRepositoryImpl } from '../data/repositories/user-announcement-modal-repository';
import { UserRankBonusesRepositoryImpl } from '../data/repositories/user-rank-bonuses-repository';
import { UserTermsAgreementStatusesRepositoryImpl } from '../data/repositories/user-terms-agreement-statuses-repository';
import convertUserRankBonusesDtoToViewData from '../hooks/utils/convert-user-rank-bonuses-dto';

const TopDisplayOrderModals: VFC = () => {
  const { isOpen, requestShowing, dialogProps, onClose } = useDialog();
  const history = useHistory();
  const callAnnouncementModalFlow = () => {
    const referAFriendFlag: boolean =
      localStorage.getItem('refer_a_friend') === 'true';
    void new UserAnnouncementModalRepositoryImpl()
      .fetch(referAFriendFlag)
      .then((modalsDto) => {
        if (modalsDto.announcementModals?.length) {
          const modalInfo = modalsDto.announcementModals[0];
          const primaryButtonAction = () => {
            void new UserAnnouncementModalRepositoryImpl()
                .save(modalInfo.code)
                .catch()
                .finally(()=>{
                  if (modalInfo.urlType === 'external') {
                    requestWebCommand(new OpenExternalWeb(modalInfo.url));
                  } else if (modalInfo.urlType === 'internal') {
                    history.push(modalInfo.url);
                  }
                });
          };
          requestShowing({
            title: modalInfo.title,
            description: modalInfo.description,
            imageSrc: modalInfo.imageSrc,
            imageSize: 'large',
            showCloseButton: modalInfo.showClose,
            closeOnOverlayClick: false,
            allowedOnClose: true,
            primaryButton: {
              text: modalInfo.buttonText,
              onClick:
                modalInfo.urlType === 'nothing'
                  ? undefined
                  : primaryButtonAction,
            },
            // NOTE: 今後もリテンションキャンペーンのモーダルは中央寄せにする方針
            // https://finc.slack.com/archives/C01U1LGBDHU/p1692849101371469?thread_ts=1692843089.663379&cid=C01U1LGBDHU
            alignDescriptionLeft: !modalInfo.code.startsWith('retentioncp'),
          });
        } else {
          void new UserRankBonusesRepositoryImpl()
            .fetch()
            .then((userRankBounseDto) => {
              const viewData =
                convertUserRankBonusesDtoToViewData(userRankBounseDto);

              if (viewData.userRankUpBonus) {
                const bounseDescription =
                  viewData.userRankUpBonus.details.reduce(
                    (desc, detail) => `${desc}\n${detail}`,
                    `ランクアップボーナスとして\nFitStatsポイントを\n${viewData.userRankUpBonus.totalPoint}pt獲得しました。\n`,
                  );
                requestShowing({
                  title: 'ランクアップボーナス獲得！',
                  description: bounseDescription,
                  imageSrc: '/global/get_point.png',
                  imageSize: 'large',
                  allowedOnClose: true,
                  showCloseButton: true,
                  primaryButton: { text: 'OK' },
                });
              }
              if (viewData.userRankKeepBonus) {
                requestShowing({
                  title: 'Aランク維持ボーナス獲得！',
                  description: `総合ランクAを${viewData.userRankKeepBonus.days}日間維持したので、FitStatsポイントを${viewData.userRankKeepBonus.totalPoint}pt獲得しました。\n\n獲得したポイントが反映されるまで数日かかる場合があります。獲得状況はポイント履歴からご確認ください。`,
                  imageSrc: '/global/get_point.png',
                  imageSize: 'large',
                  allowedOnClose: true,
                  showCloseButton: true,
                  primaryButton: { text: 'OK' },
                });
              }
            })
            .catch((error) => {
              const errorMessage = isErrorDTO(error)
                ? error.error.message
                : 'ランクボーナスのデータ取得に失敗しました';

              requestShowing({
                title: 'エラー',
                description: errorMessage,
                imageSrc: '',
                allowedOnClose: true,
                showCloseButton: true,
                primaryButton: { text: 'OK' },
              });
            });
        }
      })
      .catch((error) => {
        const errorMessage = isErrorDTO(error)
          ? error.error.message
          : '告知情報の取得に失敗しました';

        requestShowing({
          title: 'エラー',
          description: errorMessage,
          imageSrc: '',
          allowedOnClose: true,
          showCloseButton: true,
          primaryButton: { text: 'OK' },
        });
      });
  };

  /* top画面表示時に利用規約 > 告知 > ランクアップ の優先順位で1つモーダルを表示する */
  useEffect(() => {
    void new UserTermsAgreementStatusesRepositoryImpl()
      .fetch()
      .then((dto) => {
        const termsOfUseUpdates =
          '利用規約\n第15条：利用目的変更時の対応について\n\n';
        const privacyPolicyUpdates =
          '個人情報の取り扱いについて\n１．個人情報の利用目的\n５．情報収集モジュール\n\n';

        const description = (content: string): string =>
          '' +
          `${content}が更新されました。\n` +
          '詳細をご確認の上、同意をお願いします。\n\n' +
          '主な変更箇所は以下の通りです。\n' +
          `${dto.termsOfUse ? termsOfUseUpdates : ''}` +
          `${dto.privacyPolicy ? privacyPolicyUpdates : ''}` +
          '※新規約等を確認・同意後、FitStatsがご利用いただけます。';

        if (dto.termsOfUse && dto.privacyPolicy) {
          requestShowing({
            description: description(
              '「利用規約」および「個人情報の取り扱いについて」',
            ),
            allowedOnClose: false,
            showCloseButton: false,
            closeOnOverlayClick: false,
            primaryButton: {
              text: 'OK',
              onClick: () => {
                history.push('/terms_and_privacy_policy/terms_of_use', {
                  privacyPolicy: true,
                });
              },
            },
          });
        } else if (dto.termsOfUse) {
          requestShowing({
            description: description('「利用規約」'),
            allowedOnClose: false,
            showCloseButton: false,
            closeOnOverlayClick: false,
            primaryButton: {
              text: 'OK',
              onClick: () => {
                history.push('/terms_and_privacy_policy/terms_of_use', {
                  privacyPolicy: false,
                });
              },
            },
          });
        } else if (dto.privacyPolicy) {
          requestShowing({
            description: description('「個人情報の取り扱いについて」'),
            allowedOnClose: false,
            showCloseButton: false,
            closeOnOverlayClick: false,
            primaryButton: {
              text: 'OK',
              onClick: () => {
                history.push('/terms_and_privacy_policy/privacy_policy');
              },
            },
          });
        }

        if (!dto.termsOfUse && !dto.privacyPolicy) {
          callAnnouncementModalFlow();
        }
      })
      .catch((error) => {
        const errorMessage = isErrorDTO(error)
          ? error.error.message
          : 'ユーザーの利用規約および個人情報の取り扱いの同意ステータスの取得に失敗しました';

        requestShowing({
          title: 'エラー',
          description: errorMessage,
          showCloseButton: false,
          allowedOnClose: false,
          primaryButton: {
            text: 'OK',
            onClick: () => {
              history.go(0);
            },
          },
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, requestShowing, onClose]);

  return (
    <>
      {dialogProps && (
        <Dialog
          title={dialogProps.title}
          description={dialogProps.description}
          primaryButton={dialogProps.primaryButton}
          imageSrc={dialogProps.imageSrc}
          imageSize={dialogProps.imageSize}
          isOpen={isOpen}
          showsCloseButton={dialogProps.showCloseButton}
          closeOnOverlayClick={dialogProps.closeOnOverlayClick}
          onClose={dialogProps.allowedOnClose ? onClose : () => undefined}
          alignDescriptionLeft={dialogProps.alignDescriptionLeft}
        />
      )}
    </>
  );
};

export default TopDisplayOrderModals;
