import { isErrorDTO } from 'data/dto/error-dto';
import { GENERAL_REQUEST_ERROR_MESSAGE } from 'global/constants';
import Result from 'global/utilities/result';
import requestWebCommand, {
  OpenExternalWeb,
} from 'global/utilities/web-command';
import { useHistory } from 'react-router';
import { EntryCampaignRepositoryImpl } from '../data/repositories/entry-campaign-repository';
import EntryCampaignViewData from '../view-data/entry-campaign-view-data';
import useEntryCampaign from './use-entry-campaign';
import EntryCampaignCode from "../../program/data/common/entry-campaign-code";

type ReturnType = {
  fetchResult: Result<EntryCampaignViewData | null, Error>;
  entryButtonTapped: (entryCampaign: EntryCampaignViewData) => void;
};

const useEntryCampaignDetail = (
  entryCampaignCode: EntryCampaignCode,
  entryCampaignRegistrationFailed: (errorMessage: string) => void,
): ReturnType => {
  const history = useHistory();
  const repository = new EntryCampaignRepositoryImpl();
  const { fetchResult } = useEntryCampaign(entryCampaignCode.value);

  const entryButtonTapped = (entryCampaign: EntryCampaignViewData) => {
    repository
      .register(entryCampaign.code)
      .then(() => {
        requestWebCommand(new OpenExternalWeb(entryCampaign.buttonUrl));
        history.push('/top');
      })
      .catch((error) => {
        const errorMessage = isErrorDTO(error)
          ? error.error.message
          : GENERAL_REQUEST_ERROR_MESSAGE;
        entryCampaignRegistrationFailed(errorMessage);
      });
  };

  return {
    fetchResult,
    entryButtonTapped,
  };
};

export default useEntryCampaignDetail;
