import ApiClient from 'data/api-client/api-client';
import RequestConfig, { URL } from 'data/request-configs/request-config';
import PopupInfoDto from 'features/profile/data/dto/popup-info-dto';
import DataConsentDto from '../dto/data-consent-dto';

// eslint-disable-next-line no-shadow
export enum ConsentCategory {
  Property = '1',
  Company = '2',
}

export type ConsentItem = {
  name: string;
  consent: boolean;
};

const createGetRequestConfig = (category: ConsentCategory): RequestConfig => ({
  endpoint: {
    baseUrl: URL.FIT_STATS,
    path: '/users/me/consent_items',
  },
  method: 'get',
  parameters: { category },
});

const createPutRequestConfig = (
  category: ConsentCategory,
  consentItems: ConsentItem[],
  actionId?: number,
): RequestConfig => ({
  endpoint: {
    baseUrl: URL.FIT_STATS,
    path: '/users/me/consent_items',
  },
  method: 'put',
  parameters: { category, consent_items: consentItems, action_id: actionId },
});

interface DataConsentRepository {
  fetch: (category: ConsentCategory) => Promise<DataConsentDto>;
  save: (
    category: ConsentCategory,
    consentItems: ConsentItem[],
    actionId?: number,
  ) => Promise<PopupInfoDto>;
}

export class DataConsentRepositoryImpl implements DataConsentRepository {
  fetch = async (category: ConsentCategory): Promise<DataConsentDto> =>
    new ApiClient().connect(createGetRequestConfig(category));

  save = async (
    category: ConsentCategory,
    consentItems: ConsentItem[],
    actionId?: number,
  ): Promise<PopupInfoDto> =>
    new ApiClient().connect(
      createPutRequestConfig(category, consentItems, actionId),
    );
}

export default DataConsentRepository;
