import {
  FieldValues,
  UseFormRegister,
  FieldErrors,
  UseFormSetValue,
} from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { VFC } from 'react';
import Container from 'global/components/Container/Container';
import FormContainer, {
  FormTitle,
} from 'global/components/FormContainer/FormContainer';
import InputText from 'global/components/InputText/InputText';
import Dialog from 'global/components/dialog/Dialog';
import useDialog from 'global/components/dialog/use-dialog';
import ErrorDTO from 'data/dto/error-dto';
import styles from './ReferralCodePage.module.css';
import ReferralCodePresenceRepository, {
  ReferralCodePresenceRepositoryImpl,
} from '../data/repositories/referral-code';

type Props = {
  register: UseFormRegister<FieldValues>;
  errors: FieldErrors<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
};

const formTitle = <FormTitle size="large" text="紹介コードの入力" />;

const ReferralCodePage: VFC<Props> = ({ register, errors, setValue }) => {
  const { isOpen, requestShowing, dialogProps, onClose } = useDialog();

  const inputCode = (
    <InputText
      name="referralCode"
      size="small"
      placeholder="お持ちの方のみ入力してください"
      register={register('referralCode', {
        validate: (value: string) => {
          const trimmedValue = value.replace(/\s+/g, '');

          if (trimmedValue.length < 1) {
            return true;
          }

          const repository: ReferralCodePresenceRepository =
            new ReferralCodePresenceRepositoryImpl();

          return repository
            .fetch(trimmedValue)
            .then((response) => {
              if (response.exists) {
                setValue('referralCode', trimmedValue);

                return true;
              }
              requestShowing({
                title: 'エラー',
                description: '紹介コードが間違っています。',
                primaryButton: { text: 'OK' },
              });

              return false;
            })
            .catch((_: ErrorDTO) => {
              requestShowing({
                title: 'エラー',
                description:
                  'FitStatsに不具合が起きてます。一度FitStatsを終了させて再度お試しください。',
                primaryButton: { text: 'OK' },
              });

              return false;
            });
        },
      })}
    />
  );

  return (
    <>
      <Container marginTop="s">
        <FormContainer>
          {[
            <div className={styles.formTitleWrapper}>{formTitle}</div>,
            inputCode,
          ]}
        </FormContainer>
        <ErrorMessage
          errors={errors}
          name="referralCode"
          render={({ message }) => (
            <p className={styles.errorMessage}>{message}</p>
          )}
        />
      </Container>
      <Dialog
        title={dialogProps?.title}
        primaryButton={dialogProps?.primaryButton ?? { text: 'OK' }}
        description={dialogProps?.description}
        isOpen={isOpen}
        onClose={onClose}
        alignDescriptionLeft
      />
    </>
  );
};

export default ReferralCodePage;
