/* eslint-disable react/jsx-props-no-spreading */
import { useEffect, VFC } from 'react';
import Container from 'global/components/Container/Container';
import UndraggableLink from 'global/components/UndraggableLink/UndraggableLink';
import styles from './EmailAuthenticationPage.module.css';

type Props = {
  email: string;
};

const EmailAuthenticationPage: VFC<Props> = ({ email }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Container marginLeft="s" marginRight="s">
        <p className={styles.topMessage}>
          登録いただいたメールアドレス
          <br />
          <span className={styles.highlightText}>{email}</span>
          に認証メールを送信しました。
          <br />
        </p>
        <Container marginTop="xxl">
          <p className={styles.text}>
            メールが届かない場合は、下記の原因が考えられます。
          </p>
          <p className={styles.text}>
            1. 自動的に振り分けられてる可能性がありますので、
            一度、迷惑メールフォルダをご確認ください。
          </p>
          <p className={styles.text}>
            2. メールアドレスが正しく入力されていない場合は、 「
            <UndraggableLink
              className={styles.highlightText}
              to="/sign_up/profile/basic"
            >
              前の画面
            </UndraggableLink>
            」に戻ってメールアドレスの登録をご確認の上、再送してください。
          </p>
        </Container>

        <Container marginTop="3xs">
          <p className={styles.text}>
            ※ 認証メールの有効期限が切れた場合は、「
            <UndraggableLink
              className={styles.highlightText}
              to="/sign_up/profile/basic"
            >
              前の画面
            </UndraggableLink>
            」に戻って次へを再度タップしてください。
          </p>
          <p className={styles.text}>
            ※ 認証メールのURLをタップすると、メールアドレスによる本人認証が
            完了し、画面が次のステップへ続きます。次の画面へ進まない場合は、
            FiNCアプリのバージョンが古い場合がありますので、FiNCアプリを
            最新にバージョンアップしてください。
          </p>
        </Container>
      </Container>
    </>
  );
};

export default EmailAuthenticationPage;
