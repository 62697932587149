import Container from 'global/components/Container/Container';
import FormContainer, {
  FormTitle,
} from 'global/components/FormContainer/FormContainer';
import InputTel from 'global/components/InputTel/InputTel';
import { VFC } from 'react';
import { FieldValues, FieldErrors, UseFormRegister } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import styles from './PhoneNumberRegistrationPage.module.css';

type Props = {
  register: UseFormRegister<FieldValues>;
  errors: FieldErrors<FieldValues>;
};

const formTitle = <FormTitle size="large" text="電話番号" />;

const PhoneNumberFormPage: VFC<Props> = ({
  register,
  errors,
}) => {
  const name = 'phoneNumber';
  const options = {
    required: '電話番号は入力必須の項目です。',
    pattern: {
      value: /^0[0-9]{1}0[0-9]{8}$/,
      message: '電話番号が間違っています。',
    },
  };

  const registration = register(name, options);

  const inputTel = (
    <InputTel
      name={name}
      size="small"
      placeholder="携帯電話の電話番号"
      register={registration}
    />
  );

  return (
    <>
      <Container marginTop="s">
        <FormContainer>{[
          <div className={styles.formTitleWrapper}>
            {formTitle}
            <p className={styles.remark}>
              ※認証番号の送信(SMS)に使用します
            </p>
          </div>,
          inputTel
        ]}</FormContainer>

        <ErrorMessage
          errors={errors}
          name={name}
          render={({ message }) => (
            <p className={styles.errorMessageLarge}>{message}</p>
          )}
        />

      </Container>
    </>
  );
};

export default PhoneNumberFormPage;
