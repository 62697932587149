import { useEffect }  from 'react';
import { isErrorDTO } from 'data/dto/error-dto';
import { GENERAL_REQUEST_ERROR_MESSAGE } from 'global/constants';
import CheckFunctionalStatus, { CheckFunctionalStatusRepositoryImpl } from '../data/repositories/check-functional-status-repository';

const repository: CheckFunctionalStatus = new CheckFunctionalStatusRepositoryImpl()

  const useCheckFunctionalStatus = (serviceName: string): Promise<void> => {
    useEffect(() => {
      repository
        .fetch(serviceName)
        .then((response) => {
          if (!response) {
            return;
          }

          localStorage.setItem(response.serviceName, String(response.status));
        })
        .catch((error) => {
          // インフルエンサーを活用した企画を実施するため、エラーが起きても友達紹介機能をオンにする
          localStorage.setItem('refer_a_friend', 'true');
          if (isErrorDTO(error)) {
            throw Error(error.error.message);
          }
          throw Error(GENERAL_REQUEST_ERROR_MESSAGE);
        });
      }
    );

    return Promise.resolve();
  };

export default useCheckFunctionalStatus;
