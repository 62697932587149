import PasswordAndProfileRegistrationRepository, {
  PasswordAndProfileRegistrationRepositoryImpl,
} from 'features/sign-up/phone-number/authentication/data/repositories/password-and-profile-registration-repository';
import { UserInputPostItem } from 'features/profile/user-input-item';
import ErrorDTO, { isErrorDTO } from 'data/dto/error-dto';
import Result, { Failure, Success } from 'global/utilities/result';
import PasswordAndProfileRegistrationDto from '../data/dto/password-and-profile-registration-dto';

type ReturnType = {
  registrationSubmitted: (
    password: string | null,
    privacyPolicyVersion: string | null,
    termsOfUseVersion: string | null,
    userProfile: UserInputPostItem[],
    referralCode: string | null,
  ) => void;
};

const usePasswordAndProfileRegistration = (
  repository: PasswordAndProfileRegistrationRepository = new PasswordAndProfileRegistrationRepositoryImpl(),
  registrationResult: (
    result: Result<PasswordAndProfileRegistrationDto, ErrorDTO | undefined>,
  ) => void,
): ReturnType => {
  const registrationSubmitted = async (
    password: string | null,
    privacyPolicyVersion: string | null,
    termsOfUseVersion: string | null,
    userProfile: UserInputPostItem[],
    referralCode: string | null,
  ): Promise<void> => {
    try {
      const dto: PasswordAndProfileRegistrationDto = await repository.post(
        password,
        privacyPolicyVersion,
        termsOfUseVersion,
        userProfile,
        referralCode,
      );
      registrationResult(new Success(dto));
    } catch (error) {
      const _ = isErrorDTO(error)
        ? registrationResult(new Failure(error))
        : registrationResult(new Failure(undefined));
    }
  };

  return { registrationSubmitted };
};

export default usePasswordAndProfileRegistration;
