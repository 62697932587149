import { VFC } from 'react';
import Loading from 'global/components/Loading/Loading';
import CoarchMark from 'global/components/CoarchMark/CoarchMark';
import TutorialFetchErrorDialog from 'global/components/TutorialFetchErrorDialog/TutorialFetchErrorDialog';
import useNavigationBarItemsSetting from 'global/hooks/use-navigation-bar-items-setting';
import useTutorialNavigation from 'features/tutorial/hooks/use-tutorial-navigation';
import OfferTopPage from '../pages/OfferTopPage';
import useOfferTop from '../hooks/use-offer-top';
import TutorialOfferRepository from '../data/repositories/tutorial-offer-repository';

const TutorialOfferTopContainer: VFC = () => {
  useNavigationBarItemsSetting('オファー', {
    isCloseButtonVisible: false,
    isMenuButtonVisible: false,
    isNotificationButtonVisible: false,
    isBackButtonVisible: false,
  });

  const {
    fetchResult,
    isNeedAddLoadShownListMore,
    onAddLoadShownListItem,
    topShownOffers,
  } = useOfferTop(new TutorialOfferRepository());

  const { navigate } = useTutorialNavigation();

  const PopOverValue = {
    children: (
      <div>
        オファーを開封すると「FitStatsポイント」が貯まります。
        <br /><br />
        オファーは、あなたが提供したデータの種類に基づいて配信されます。より多くのデータを提供して、たくさんオファーを受け取りましょう。
      </div>
    ),
    buttonText: '次へ',
    clickOption: {
      clickCustomAction: () => {
        navigate('/mydata/personal');
      },
    },
  };

  if (fetchResult.isSuccess()) {
    return (
      <>
        <OfferTopPage
          offers={topShownOffers}
          isNeedLoadMore={isNeedAddLoadShownListMore}
          onLoadItem={onAddLoadShownListItem}
          onTapOffersItem={undefined}
        />
        <CoarchMark elementId="tutorial-offer" popOverOption={PopOverValue} />
      </>
    );
  }

  if (fetchResult.isFailure()) {
    return <TutorialFetchErrorDialog />;
  }

  return <Loading />;
};

export default TutorialOfferTopContainer;
