import { isErrorDTO } from 'data/dto/error-dto';
import useClearCaches from 'global/hooks/use-clear-caches';
import Result, { Failure, Success } from 'global/utilities/result';
import { useRef, useState } from 'react';
import { useHistory } from 'react-router';
import WithdrawalSettingsRepository, {
  WithdrawalSettingsRepositoryImpl,
} from '../data/repositories/withdrawal-settings-repository';
import WithdrawalSettingsViewData, {
  WithdrawalReasonType,
} from '../view-data/withdrawal-settings-view-data';

type ReturnType = {
  viewData: WithdrawalSettingsViewData;
  onChangeReasonChecked: (
    type: WithdrawalReasonType,
    isApplicable: boolean,
  ) => void;
  onSubmit: (opinionAndRequest: string) => void;
};

const createViewData = (): WithdrawalSettingsViewData => ({
  title: 'FitStats退会手続き',
  description:
    'FitStatsの退会処理を行います。\n退会理由について教えてください。（複数選択可能）\n\n退会が完了すると、FitStatsのあなたのアカウント情報が全て削除され、FitStatsをご利用いただけなくなります。\n\n※FitStatsを退会することでFiNCのアカウント情報が削除されることはありません。',
  checks: [
    {
      reason: {
        type: 'notInterestedInService',
        text: 'サービス内容が合わない',
      },
      isApplicable: false,
    },
    {
      reason: {
        type: 'difficultToUnderstand',
        text: 'サービスが使いにくい・利用方法が分からない',
      },
      isApplicable: false,
    },
    {
      reason: {
        type: 'noMatchingOffer',
        text: '自分に合ったオファーが届かない',
      },
      isApplicable: false,
    },
    {
      reason: {
        type: 'bothersome',
        text: 'データ登録・データ提供の手続きが面倒',
      },
      isApplicable: false,
    },
    {
      reason: {
        type: 'notAgreeTerms',
        text: 'データ利用目的が合わない',
      },
      isApplicable: false,
    },
    {
      reason: {
        type: 'noReplacement',
        text: 'ポイントが貯まらない・交換したい先がない',
      },
      isApplicable: false,
    },
    {
      reason: {
        type: 'other',
        text: 'その他',
      },
      isApplicable: false,
    },
  ],
});

const useWithdrawalSettings = (
  confirm: (onTapDecided: () => void) => void,
  repository: WithdrawalSettingsRepository = new WithdrawalSettingsRepositoryImpl(),
  saveResult: (result: Result<void, Error>) => void,
): ReturnType => {
  const history = useHistory();
  const viewData = useRef<WithdrawalSettingsViewData>(createViewData());
  const [checked, setChecked] = useState(
    viewData.current.checks.reduce(
      (p: { [key: string]: boolean }, c) => ({
        ...p,
        [c.reason.type]: c.isApplicable,
      }),
      {},
    ),
  );
  const { clearCaches } = useClearCaches();

  const onChangeReasonChecked = (
    type: WithdrawalReasonType,
    isApplicable: boolean,
  ): void => {
    setChecked({ ...checked, [type]: isApplicable });

    const cached = viewData.current;
    viewData.current = {
      ...cached,
      checks: cached.checks.map((c) =>
        c.reason.type === type ? { ...c, isApplicable } : c,
      ),
    };
  };

  const onSubmit = (opinionAndRequest: string): void => {
    confirm(() => {
      const reasons = viewData.current.checks
        .filter((c) => checked[c.reason.type])
        .map((c) => c.reason.text);

      // 自由記述欄に記入がなかった場合は全角ハイフンを送るようにする。
      // システム基盤側で値を必須で受け付ける仕様があり、工数の都合上修正できない
      // & ユーザー観点で入力必須にすることによるクレーム増加を防ぐため。
      const userComments = opinionAndRequest.trim() || 'ー';

      const _ = repository
        .withdraw(reasons, userComments)
        .then(() => {
          clearCaches();
          history.push('/withdrawal_settings/completed');
          saveResult(new Success(undefined));
        })
        .catch((error) => {
          const errorMessage = isErrorDTO(error)
            ? error.error.message
            : '退会情報の送信に失敗しました。';
          saveResult(new Failure(Error(errorMessage)));
        });
    });
  };

  return {
    viewData: viewData.current,
    onChangeReasonChecked,
    onSubmit,
  };
};

export default useWithdrawalSettings;
