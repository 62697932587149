import { VFC, useState } from 'react';
import { useHistory } from 'react-router';
import useDialog from 'global/components/dialog/use-dialog';
import Dialog from 'global/components/dialog/Dialog';
import useNavigationBarItemsSetting from 'global/hooks/use-navigation-bar-items-setting';
import NewPasswordPage from '../pages/NewPasswordPage';
import useNewPassword from '../hooks/use-new-password';

type NewPasswordLocationState = {
  transactionId: string;
};

const NewPasswordContainer: VFC = () => {
  const history = useHistory();
  const { isOpen, requestShowing, dialogProps, onClose } = useDialog();
  const [isDisabled, setIsDisabled] = useState(false);
  useNavigationBarItemsSetting('パスワードの再設定');
  const { submitErrorMessage, submitted } = useNewPassword((saveResult) => {
    if (saveResult.isSuccess()) {
      requestShowing({
        title: '',
        description: 'パスワードの再設定が完了しました。',
        primaryButton: { text: 'OK' },
      });
    }
    if (saveResult.isFailure()) {
      requestShowing({
        title: 'エラー',
        description: saveResult.error,
        primaryButton: { text: 'OK' },
      });
    }
    setIsDisabled(false);
  });

  const onCloseDialog = () => {
    onClose();
    // 変更が成功した時はダイアログを閉じた後ログイン画面に戻る
    if (!submitErrorMessage) {
      history.push('/sign_in');
    }
  };

  const newPasswordLocationState =
    useHistory<NewPasswordLocationState | undefined>();

  // transactionId と emailがない場合不正な遷移なのでtopに戻る
  if (!newPasswordLocationState.location.state?.transactionId) {
    history.replace('/top');

    return <></>;
  }

  return (
    <>
      <Dialog
        title={dialogProps?.title}
        description={dialogProps?.description}
        primaryButton={dialogProps?.primaryButton ?? { text: '閉じる' }}
        isOpen={isOpen}
        onClose={onCloseDialog}
      />

      <NewPasswordPage
        transactionId={newPasswordLocationState.location.state?.transactionId}
        submitted={submitted}
        isDisabled={isDisabled}
        setIsDisabled={setIsDisabled}
      />
    </>
  );
};

export default NewPasswordContainer;
