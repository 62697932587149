import { VFC, useState } from 'react';
import { useHistory } from 'react-router';
import usePhoneNumberAuthentication from 'features/settings/phone-number/authentication/hooks/use-phone-number-authentication';
import { PhoneNumberAuthenticationRepositoryImpl } from 'features/settings/phone-number/authentication/data/repositories/phone-number-authentication-repository';
import useDialog from 'global/components/dialog/use-dialog';
import Dialog from 'global/components/dialog/Dialog';
import useNavigationBarItemsSetting from 'global/hooks/use-navigation-bar-items-setting';
import PhoneNumberAuthenticationPage from '../pages/PhoneNumberAuthenticationPage';

type PhoneNumberLocationState = {
  phoneNumber: string;
};

const PhoneNumberAuthenticationContainer: VFC = () => {
  const history = useHistory();
  const { isOpen, requestShowing, onClose } = useDialog();
  const [isDisabled, setIsDisabled] = useState(false);
  useNavigationBarItemsSetting('電話番号の再設定');

  const { submitErrorMessage, submitted } = usePhoneNumberAuthentication(
    new PhoneNumberAuthenticationRepositoryImpl(),
    (saveResult) => {
      if (saveResult.isSuccess()) {
        requestShowing();
      }
      if (saveResult.isFailure()) {
        requestShowing();
      }
      setIsDisabled(false);
    },
  );

  const onCloseDialog = () => {
    onClose();
    // 変更が成功した時はダイアログを閉じた後に設定まで戻る
    if (!submitErrorMessage) {
      history.push('/settings');
    }
  };

  const phoneNumberLocationState =
    useHistory<PhoneNumberLocationState | undefined>();

  // phoneNumberがない場合不正な遷移なのでtopに戻る
  if (!phoneNumberLocationState.location.state?.phoneNumber) {
    history.replace('/top');

    return <></>;
  }

  return (
    <>
      <Dialog
        title=""
        description={submitErrorMessage || '電話番号の変更が完了しました。'}
        primaryButton={{ text: 'OK' }}
        isOpen={isOpen}
        onClose={onCloseDialog}
      />

      <PhoneNumberAuthenticationPage
        phoneNumber={phoneNumberLocationState.location.state?.phoneNumber}
        submitted={submitted}
        isDisabled={isDisabled}
        setIsDisabled={setIsDisabled}
      />
    </>
  );
};

export default PhoneNumberAuthenticationContainer;
