import ApiClient from 'data/api-client/api-client';
import RequestConfig, { URL } from 'data/request-configs/request-config';
import CampaignSummaryDto from '../dto/campaign-summary-dto';

const createCampaignSummaryRequestConfig = (): RequestConfig => ({
  endpoint: {
    baseUrl: URL.FIT_STATS,
    path: '/campaigns/register/progress',
  },
  method: 'post',
  parameters: {},
});

const campaignSummaryDto: CampaignSummaryDto = {
  isAchieve: false,
  endAt: '2029-12-31T23:59:59+0900',
};

interface CampaignSummaryRepository {
  fetch: () => Promise<CampaignSummaryDto>;
}

export class CampaignSummaryRepositoryImpl
  implements CampaignSummaryRepository
{
  fetch = async (): Promise<CampaignSummaryDto> =>
    new ApiClient().connect(createCampaignSummaryRequestConfig());
}

export class MockCampaignSummaryRepositoryImpl
  implements CampaignSummaryRepository
{
  fetch = async (): Promise<CampaignSummaryDto> =>
    new Promise((resolve, _) =>
      setTimeout(() => resolve(campaignSummaryDto), 1500),
    );
}

export default CampaignSummaryRepository;
