import {
  HELP_DETAIL_OF_LIFESTATS,
  OpenHelpPage,
} from 'global/utilities/open-help-page';
import { VFC } from 'react';
import { useHistory } from 'react-router';
import RankDescriptionList from '../component/RankDescriptionList';
import styles from './StatsDescriptionPage.module.css';

const StatsDescriptionPage: VFC = () => {
  const history = useHistory();
  const contents = [
    {
      rank: 'A',
      scoreFrom: 901,
      scoreTo: 1000,
      description:
        '理想的な生活習慣です！プログラムへの参加やFiNCへの記録がきちんと習慣化できていますね。引き続き規則正しい生活を送りましょう。',
      rewardPoint: 100,
      recurringReward: 100,
    },
    {
      rank: 'B',
      scoreFrom: 651,
      scoreTo: 900,
      description:
        '概ね理想的な生活習慣です。プログラムに参加して「セルフチェック」の内容を見直してみませんか？普段から健康を意識した行動を一層心がけ、Aランクを目指しましょう。',
      rewardPoint: 10,
    },
    {
      rank: 'C',
      scoreFrom: 301,
      scoreTo: 650,
      description:
        '標準的な生活習慣です。プログラムへの参加、FiNCアプリへの記録を習慣づけることを意識してみましょう。',
      rewardPoint: 10,
    },
    {
      rank: 'D',
      scoreFrom: 101,
      scoreTo: 300,
      description:
        '生活習慣の改善が必要です。健康には食事・運動・睡眠・カラダ・メンタルをバランスよく整えることが大切です。それぞれのスタッツをチェックして、改善できそうなところから取り組んでみましょう。',
      rewardPoint: 10,
    },
    {
      rank: 'E',
      scoreFrom: 0,
      scoreTo: 100,
      description:
        'スタッツ算出のためのデータが不足しています。まずは興味のあるプログラムに参加して、スタッツを毎日チェックすることから始めてみましょう。',
    },
  ];

  return (
    <div className={styles.container}>
      <section className={styles.mainSection}>
        <h2 className={styles.mainSectionTitle}>スタッツとは？</h2>
        <p>
          FitStatsの「スタッツ」は、プログラムの達成状況、
          <br />
          FiNCへのライフログ記録内容、セルフチェックへの回
          <br />
          答結果などをもとに算出された、あなたの健康度を表
          <br />
          すスコアです。
          <br />
          スタッツによってA・B・C・D・Eのランクが決ま
          <br />
          り、ランクアップするとポイント獲得できます。
        </p>
      </section>
      <section className={styles.mainSection}>
        <h2>スタッツランクを確認しよう！</h2>
        <div className={styles.contentBackground}>
          {contents.map((content, index) => (
            <RankDescriptionList content={content} index={index} />
          ))}
        </div>
        <p>
          ※食事（300点満点）・運動（200点満点）・睡眠
          （200点満点）・カラダ（150点満点）・メンタル
          （150点満点）の各カテゴリーのスタッツを合計したのが、「総合スタッツ」です。
        </p>
      </section>
      <section className={styles.mainSection}>
        <h2>スタッツUPのヒント</h2>
        <div className={styles.contentBackground}>
          <section>
            <h3 className={styles.secondSectionTitle}>
              1. プログラムに参加しよう！
            </h3>
            <p>
              FitStatsのプログラムは、設定されたアクションを実施するだけで健康増進のきっかけづくりができます。
              毎月2回新しいプログラムが配信されるので、忘れずに参加してみましょう。
              アクションを達成するごとにスタッツがUPします。
            </p>
            <button
              type="button"
              className={styles.programButton}
              onClick={() => history.push('/program')}
            >
              プログラムに参加する
            </button>
          </section>
          <section>
            <h3 className={styles.secondSectionTitle}>
              2. FiNCにきろくをしよう！
            </h3>
            <p>
              食事、体重、歩数などのライフログを毎日記録してみましょう。
              <br />
              ※FiNCの歩数記録は「端末から取得」をお選びください。
            </p>
          </section>
        </div>
      </section>
      <section className={styles.mainSectionBackground}>
        <h2 className={styles.helpTitle}>スタッツのより詳しい内容はこちら</h2>
        <button
          type="button"
          className={styles.helpButton}
          onClick={() => OpenHelpPage(HELP_DETAIL_OF_LIFESTATS)}
        >
          ヘルプを見る
        </button>
      </section>
    </div>
  );
};

export default StatsDescriptionPage;
