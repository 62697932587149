import { VFC } from 'react';
import useNavigationBarItemsSetting from 'global/hooks/use-navigation-bar-items-setting';
import StatsDescriptionPage from '../pages/StatsDescriptionPage';

const StatsDescriptionContainer: VFC = () => {
  useNavigationBarItemsSetting('スタッツとは?');

  return <StatsDescriptionPage />;
};

export default StatsDescriptionContainer;
