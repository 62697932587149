/* eslint-disable react/jsx-props-no-spreading */
import { InputValue } from 'features/profile/user-input-item';
import { VFC } from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';
import styles from './Select.module.css';

export type SelectSize = 'large' | 'medium';

type InputSelectBoxValue = {
  title: string;
  value: string;
};

export type Props = {
  name: string;
  options: InputSelectBoxValue[];
  placeholder?: string;
  multiple?: boolean;
  register?: UseFormRegisterReturn;
  value?: InputValue | undefined;
  selectSize?: SelectSize;
};

const Select: VFC<Props> = ({
  name,
  placeholder,
  multiple,
  options,
  register,
  value,
  selectSize,
}) => {
  // 「選択してください」等の、デフォルトの選択肢を選択状態にするかどうかの判定
  const showPlaceholder = (): boolean => {
    if (Array.isArray(value)) {
      // 複数選択の場合はデフォルトの選択肢を省いた上で、選択状態の選択肢が存在しないか確認
      return value.filter((v) => v.length !== 0).length === 0;
    }

    return value == null || value.length === 0;
  };

  const classNameForSelectSize = (size?: SelectSize): string => {
    switch (size) {
      case 'large':
        return styles.selectLarge;
      default:
        return '';
    }
  };

  return (
    <select
      name={name}
      multiple={multiple}
      {...register}
      className={[
        showPlaceholder() ? styles.selectPlaceholder : '',
        classNameForSelectSize(selectSize),
      ].join(' ')}
    >
      {/* selectはプレースホルダーを指定できないため、optionの一つをplaceholderとして設定している。 
      プレースホルダーの空valueはバリデーションやpost時のfilterで回避する */}
      <optgroup>
        <option value="" selected={showPlaceholder()}>
          {placeholder || multiple
            ? '選択してください *複数選択可能'
            : '選択してください'}
        </option>
        {options.map((option) => (
          <option value={option.value}>{option.title}</option>
        ))}
      </optgroup>
    </select>
  );
};
export default Select;
