import { VFC, useEffect } from 'react';
import Container from 'global/components/Container/Container';
import Button from 'global/components/button/Button';
import styles from './EntryCampaignDetailPage.module.css';
import EntryCampaignViewData from '../view-data/entry-campaign-view-data';

type Props = {
  entryCampaign: EntryCampaignViewData;
  entryButtonTapped: () => void;
};

// eslint-disable-next-line @typescript-eslint/no-empty-function
const doNothing = () => {}

const EntryCampaignDetailPage: VFC<Props> = ({
  entryCampaign,
  entryButtonTapped,
}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Container>
        <div className={styles.container}>
          <img
            src={entryCampaign.coverImagePath}
            alt={entryCampaign.title}
            onError={(e) => {
              e.currentTarget.style.display = 'none';
            }}
          />
        </div>
      </Container>
      <Container marginTop="m" marginBottom="s" marginLeft="s" marginRight="s">
        <div className={styles.title}>{entryCampaign.title}</div>
        <div className={styles.clientName}>{entryCampaign.clientName}</div>
        {/* TODO: 日付フォーマット修正 */}
        <div className={styles.expirationDate}>
          {entryCampaign.endAt.toFormat('yyyy/MM/dd')} まで
        </div>
      </Container>
      <Container marginTop="s" marginBottom="s" marginLeft="s" marginRight="s">
        <div className={styles.headline}>{entryCampaign.headline}</div>
        <div className={styles.description}>{entryCampaign.description}</div>
      </Container>
      <Container
        marginTop="l"
        marginBottom="xxl"
        marginLeft="s"
        marginRight="s"
      >
        {entryCampaign.isEntered
          ? <Button
              text="既にエントリー済です"
              type="primary"
              disabled
              size="large"
              onClick={doNothing}
          />
          : <Button
            text="エントリーする"
            type="primary"
            size="large"
            onClick={entryButtonTapped}
          />
        }
      </Container>

      <div className={styles.cautionContainer}>
        <div className={styles.termsTitle}>注意事項</div>
        <div className={styles.termsBody}>
          {entryCampaign.notes}
          <div className={styles.termsSubTitle}>本利用規約</div>
          {entryCampaign.terms}
        </div>
      </div>
    </>
  );
};
export default EntryCampaignDetailPage;
