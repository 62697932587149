import { useHistory } from 'react-router';
import { isErrorDTO } from 'data/dto/error-dto';
import { GENERAL_REQUEST_ERROR_MESSAGE } from 'global/constants';
import { useCallback } from 'react';
import DeleteIntroductionProgressRepository, {
  DeleteIntroductionProgressRepositoryImpl,
} from '../data/repositories/delete-introduction-progress-repository';

type ReturnType = {
  deleteResult: () => void;
};

const useDeleteIntroductionProgress = (
  repository: DeleteIntroductionProgressRepository = new DeleteIntroductionProgressRepositoryImpl(),
  callReason?: string,
): ReturnType => {
  const history = useHistory();

  const deleteResult = useCallback(() => {
    repository
      .delete(callReason)
      .then(() => {
        history.push('/sign_up/profile/basic');
      })
      .catch((error) => {
        if (isErrorDTO(error)) {
          throw Error(error.error.message);
        }
        throw Error(GENERAL_REQUEST_ERROR_MESSAGE);
      });
    // historyは毎描画で生成されるため、無限ループ化を防ぐ
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { deleteResult };
};

export default useDeleteIntroductionProgress;
