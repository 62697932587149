import { VFC, useEffect } from 'react';
import Result from 'global/utilities/result';
import Container from 'global/components/Container/Container';
import { useHistory } from 'react-router';
import OfferTopViewData from 'features/offer/view-data/offer-top-view-data';
import { TutorialLocationState } from 'features/tutorial/hooks/use-tutorial-navigation';
import EntryCampaignViewData from 'features/entry-campaign/view-data/entry-campaign-view-data';
import styles from './TopPage.module.css';
import TopScoresViewData from '../view-data/top-scores-view-data';
import TopLifeScore from '../components/TopLifeScore';
import TopDailyScores from '../components/TopDailyScores';
import TopDailyScoresViewData from '../view-data/top-daily-scores-view-data';
import TopDisplayOrderModals from '../components/TopDisplayOrderModals';
import TopOffers from '../components/TopOffers';
import CampaignSummaryBoard from '../../campaign/components/SummaryBoard';
import TopEntryCampaign from '../components/entry-campaign/TopEntryCampaign';
import AnnouncementBannersDto from '../data/dto/announcement-banners-dto';
import AnnouncmentBanners from '../components/AnnouncementBanners';
import AdvertisementBannersDto from '../data/dto/advertisement-banners-dto';
import AdvertisementBanners from '../components/AdvertisementBanners';

type Props = {
  scoresFetchResult: Result<TopScoresViewData, Error>;
  dailyScoresFetchResult: Result<TopDailyScoresViewData, Error>;
  dailyScoresPrevButtonTapped: () => void;
  dailyScoresNextButtonTapped: () => void;
  didLoad?: () => void;
  locationState?: TutorialLocationState | undefined;
  offersFetchResult: Result<OfferTopViewData, Error>;
  entryCampaigns: EntryCampaignViewData[] | null;
  entryCampaignBannerTapped: (code: string) => () => void;
  announcementBannersFetchResult: Result<AnnouncementBannersDto, Error>;
  advertisementBannersFetchResult: Result<AdvertisementBannersDto, Error>;
  advertisementBannerTapped: (
    code: string,
    urlType: string,
    url: string,
  ) => void;
};

const TopPage: VFC<Props> = ({
  scoresFetchResult,
  dailyScoresFetchResult,
  dailyScoresPrevButtonTapped,
  dailyScoresNextButtonTapped,
  didLoad,
  locationState,
  offersFetchResult,
  entryCampaigns,
  entryCampaignBannerTapped,
  announcementBannersFetchResult,
  advertisementBannersFetchResult,
  advertisementBannerTapped,
}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
    if (didLoad != null) {
      didLoad();
    }
  }, [didLoad]);

  const history = useHistory();

  // 広告バナーが表示されている場合、新規登録キャンペーンバナー上部にpadding追加
  const registrationCampaignPaddingTop = !!(
    advertisementBannersFetchResult.isSuccess() &&
    advertisementBannersFetchResult.value.advertisementBanners &&
    advertisementBannersFetchResult.value.advertisementBanners.length > 0
  );

  return (
    <>
      {/* チュートリアルではランクアップボーナスをリクエストしない（ダイアログの案内が重複するため） */}
      {locationState?.isTutorial ? <></> : <TopDisplayOrderModals />}
      <AdvertisementBanners
        advertisementBannersFetchResult={advertisementBannersFetchResult}
        advertisementBannerTapped={advertisementBannerTapped}
      />
      <div className={styles.containerWithoutPadding}>
        <CampaignSummaryBoard
          onTop
          paddingTop={registrationCampaignPaddingTop}
        />
      </div>
      <div className={styles.container}>
        <div className={styles.sectionWrapper}>
          <Container marginBottom="s">
            <TopLifeScore scoresFetchResult={scoresFetchResult} />
          </Container>
          <Container marginTop="s" marginBottom="s">
            <TopOffers
              offersFetchResult={offersFetchResult}
              onTapOffersItem={(id, type) => {
                if (type.kind === 'announcement') {
                  history.push(`/offers/announcement_offers/${id}`);
                }
                if (type.kind === 'questionnaire') {
                  history.push(`/offers/questionnaire_offers/${id}`);
                }
              }}
            />
          </Container>
        </div>
        {entryCampaigns !== null && (
          entryCampaigns.map((entryCampaign, _) => (
            <Container marginTop="s">
              <TopEntryCampaign
                entryCampaign={entryCampaign}
                // eslint-disable-next-line no-alert
                onBannerTapped={entryCampaignBannerTapped(entryCampaign.code)}
              />
            </Container>
          ))
        )}
        <Container marginTop="s" marginBottom="s">
          <AnnouncmentBanners
            announcementBannersFetchResult={announcementBannersFetchResult}
          />
        </Container>
        <div className={styles.sectionWrapper}>
          <TopDailyScores
            dailyScoresFetchResult={dailyScoresFetchResult}
            prevButtonTapped={dailyScoresPrevButtonTapped}
            nextButtonTapped={dailyScoresNextButtonTapped}
          />
        </div>
      </div>
    </>
  );
};

export default TopPage;
