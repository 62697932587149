import { VFC } from 'react';
import styles from './ToggleSwitch.module.css';

type OnOffText = {
  onText: string;
  offText: string;
};

type SwitchSize = 'large' | 'medium';

type Props = {
  onOffText: OnOffText;
  isOn: boolean;
  switchSize?: SwitchSize;
  onClick: (isOn: boolean) => void;
};

const classNameOfSize = (switchSize?: SwitchSize): string => {
  switch (switchSize) {
    case 'medium':
      return styles.switchMedium;
    case 'large':
      return styles.switchLarge;
    default:
      return styles.switchMedium;
  }
};

const ToggleSwitch: VFC<Props> = ({ onOffText, isOn, switchSize, onClick }) => {
  const click = () => {
    onClick(!isOn);
  };

  const className = isOn
    ? `${styles.switch} ${styles.on} ${classNameOfSize(switchSize)}`
    : `${styles.switch} ${styles.off} ${classNameOfSize(switchSize)}`;

  return (
    <button type="button" className={className} onClick={click}>
      <div className={styles.labelText}>
        {isOn ? onOffText.onText : onOffText.offText}
      </div>
    </button>
  );
};

export default ToggleSwitch;
