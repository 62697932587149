import { VFC } from 'react';
import { useHistory } from 'react-router';
import useDialog from 'global/components/dialog/use-dialog';
import Dialog from 'global/components/dialog/Dialog';
import useNavigationBarItemsSetting from 'global/hooks/use-navigation-bar-items-setting';
import PhoneNumberRegistrationPage from '../pages/PhoneNumberRegistrationPage';
import usePhoneNumberRegistration from '../hooks/use-phone-number-registration';
import { PhoneNumberRegistrationRepositoryImpl } from '../data/repositories/phone-number-registration-repository';

const PhoneNumberRegistrationContainer: VFC = () => {
  const history = useHistory();
  const { isOpen, requestShowing, onClose } = useDialog();
  useNavigationBarItemsSetting('本人認証', {
    isCloseButtonVisible: false,
    isMenuButtonVisible: false,
    isNotificationButtonVisible: false,
    isBackButtonVisible: false,
  });

  const { submitErrorMessage, submitted } = usePhoneNumberRegistration(
    new PhoneNumberRegistrationRepositoryImpl(),
    (saveResult) => {
      if (saveResult.isSuccess()) {
        history.push('/sign_up/phone_number/authentication', {
          phoneNumber: saveResult.value,
        });
      }
      if (saveResult.isFailure()) {
        requestShowing();
      }
    },
  );

  return (
    <>
      <Dialog
        title="エラー"
        description={submitErrorMessage}
        primaryButton={{ text: 'OK' }}
        isOpen={isOpen}
        onClose={onClose}
      />

      {/* これ以上style長くなりそうならcssファイルの切り出し検討する */}
      <div style={{ paddingTop: 60, paddingBottom: 150 }}>
        <PhoneNumberRegistrationPage submitted={submitted} />
      </div>
    </>
  );
};

export default PhoneNumberRegistrationContainer;
