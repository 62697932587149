import ApiClient from 'data/api-client/api-client';
import RequestConfig, { URL } from 'data/request-configs/request-config';
import RegistrationCompleteEventDto from '../dto/registration-complete-event-dto';

const createEventConfig = (): RequestConfig => ({
  endpoint: {
    baseUrl: URL.FIT_STATS,
    path: '/users/me/registrations/complete',
  },
  method: 'post',
  parameters: {},
});

interface RegistrationCompleteRepository {
  createEvent: () => Promise<RegistrationCompleteEventDto>;
}

export class RegistrationCompleteRepositoryImpl implements RegistrationCompleteRepository {
  createEvent = async (): Promise<RegistrationCompleteEventDto> =>
    new ApiClient().connect(createEventConfig());
}

export default RegistrationCompleteRepository;
