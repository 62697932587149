export const DotmoneyItemIds = {
  PayPay:         13000001,
  AmazonGiftCard: 13000002,
  DPoint:         13000003,
  RakutenPoint:   13000004,
  Ponta:          13000005,
  Jal:            13000006,
} as const;

export type DotmoneyItemId = typeof DotmoneyItemIds[keyof typeof DotmoneyItemIds];
