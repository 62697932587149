import ApiClient from 'data/api-client/api-client';
import RequestConfig, { URL } from 'data/request-configs/request-config';
import EntryCampaignDto from '../dto/entry-campaign-dto';
import EntryCampaignsDto from '../dto/entry-campaigns-dto';

const createFetchRequestConfig = (): RequestConfig => ({
  endpoint: {
    baseUrl: URL.FIT_STATS,
    path: '/users/me/entry_campaigns',
  },
  method: 'get',
  parameters: {},
});

const createFindRequestConfig = (code: string): RequestConfig => ({
  endpoint: {
    baseUrl: URL.FIT_STATS,
    path: `/users/me/entry_campaigns/${code}`,
  },
  method: 'get',
  parameters: {},
});

const createRegistrationRequestConfig = (code: string): RequestConfig => ({
  endpoint: {
    baseUrl: URL.FIT_STATS,
    path: '/users/me/entry_campaigns',
  },
  method: 'post',
  parameters: { code },
});

interface EntryCampaignRepository {
  fetch: () => Promise<EntryCampaignsDto>;
  register: (code: string) => Promise<void>;
}

export class EntryCampaignRepositoryImpl implements EntryCampaignRepository {
  fetch = async (): Promise<EntryCampaignsDto> =>
    new ApiClient().connect(createFetchRequestConfig());

  find = async (code: string): Promise<EntryCampaignDto> =>
    new ApiClient().connect(createFindRequestConfig(code));

  register = async (code: string): Promise<void> =>
    new ApiClient().connect(createRegistrationRequestConfig(code));
}

export default EntryCampaignRepository;
