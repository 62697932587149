import { VFC } from 'react';
import TopContainer from 'global/components/TopContainer/TopContainer';
import ProgressBar from 'features/sign-up/components/ProgressBar/ProgressBar';
import useNavigationBarItemsSetting from 'global/hooks/use-navigation-bar-items-setting';

const PasswordContainer: VFC = () => {
  useNavigationBarItemsSetting('パスワードの登録', {
    isCloseButtonVisible: false,
    isMenuButtonVisible: false,
    isNotificationButtonVisible: false,
    isBackButtonVisible: false,
  });

  return (
    <>
      {/* PasswordPageは初回登録以外でも呼ばれる可能性があるためここで初回登録用のプログレスバーを設置 */}
      <TopContainer>
        <ProgressBar max={8} value={7} />
      </TopContainer>
    </>
  );
};

export default PasswordContainer;
