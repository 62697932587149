import React, { VFC } from 'react';
import { Redirect, useLocation } from 'react-router';
import queryString from 'query-string';
import useAuthentication from 'features/authentication/hooks/use-authentication';
import Loading from 'global/components/Loading/Loading';
import useUserSwitchCheck from '../hooks/use-user-switch-check';

const Authentication: VFC = () => {
  const { search } = useLocation();
  const qs = queryString.parse(search);
  const authorizationCode = qs.code as string;
  const { redirectToSignInIfUserSwitched } = useUserSwitchCheck();
  const result = useAuthentication(
    authorizationCode,
    redirectToSignInIfUserSwitched,
  );

  if (result.isSuccess()) {
    return <Redirect to="/sign_up/profile/basic" />;
  }

  if (result.isFailure()) {
    window.location.href = '/finc_landing_page.html'

    return <></>;
  }

  return <Loading />;
};

export default Authentication;
