import { VFC, useEffect } from 'react';
import { UserRepositoryImpl } from 'features/sign-up/terms-of-use/data/repositories/user-repository';
import AuthorizationRepository from 'features/sign-up/privacy-policy/data/repositories/authorization-repository';
import PartnersAuthorizationRepository from 'features/sign-up/data/repositories/partners-authorization-repository';
import useIntroductionProgress from 'features/introduction-progress/hooks/use-introduction-progress';
import useAgreement from 'features/sign-up/agreement/hooks/use-agreement';
import queryString from 'query-string';
import { useLocation } from 'react-router';
import requestWebCommand, {
  LoadFitStatsServicePage,
} from 'global/utilities/web-command';
import { SignOutRepositoryImpl } from 'features/logout/data/sign-out-repository';
import useClearCaches from 'global/hooks/use-clear-caches';
import useCheckFunctionalStatus from 'features/functional-status/hooks/use-check-functional-status';

const LandingContainer: VFC = () => {
  const { redirectToRecoveryPoint } = useIntroductionProgress();
  const { search } = useLocation();
  const qs = queryString.parse(search);
  const onetimeToken = qs.onetime_token as string | null;
  // アプリ(partners-api)から遷移したときに、ブラウザのセッションよりもアプリで遷移したユーザをログインユーザとして優先するために使用
  // session_keyはpartners-apiが発行し、1ユーザ(finc_user_id)につき一意に定まる
  // session_keyの値自体は意味を持たず、DBにも保存しない
  // finc_user_idから生成される値が一致しているかの判定のみに用いる
  const sessionKey = qs.session_key as string | null;

  // 認可プロセス
  const {
    submitted,
  } = useAgreement(
    onetimeToken,
    new UserRepositoryImpl(),
    new AuthorizationRepository(),
    new PartnersAuthorizationRepository(),
    (saveResult) => {
      if (saveResult.isSuccess()) {
        const authorizationCode = saveResult.value;
        redirectToRecoveryPoint(true, authorizationCode);
      }
      if (saveResult.isFailure()) {
        window.location.href = '/finc_landing_page.html'
      }
    },
  );

  void useCheckFunctionalStatus('refer_a_friend');
  const { clearCaches } = useClearCaches();

  useEffect(() => {
    if(sessionKey) {
      const beforeSessionKey:string | null = localStorage.getItem('sessionKey');
      localStorage.setItem('sessionKey', sessionKey);

      // リリース時の互換性のために、localStorageにsessionKeyがないユーザはログアウトしない
      // localStorageにsessionKeyがある && ログインしたsessionKeyが異なるユーザのみセッションを破棄して、再ログインを促す
      if(beforeSessionKey && beforeSessionKey !== sessionKey) {
        new SignOutRepositoryImpl().delete()
        .then(() => {
          localStorage.removeItem('deviceId');
          clearCaches();
          requestWebCommand(new LoadFitStatsServicePage());
        })
        .catch(() => {
          localStorage.removeItem('deviceId');
          clearCaches();
          window.location.href = '/finc_landing_page.html'
        });
      } else {
        submitted();
      }
    } else {
      submitted();
    }
  });

  return (
    <>
    </>
  );
};

export default LandingContainer;
