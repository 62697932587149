import { VFC } from 'react';
import { FieldValues, FieldErrors, UseFormRegister, UseFormWatch, UseFormSetValue, UseFormSetError, UseFormClearErrors } from 'react-hook-form';
import FormItemList from 'features/profile/components/FormItemList';
import { ProfileSinglePageViewData } from '../../../profile/view-data/profile-view-data';

type Props = {
  register: UseFormRegister<FieldValues>;
  errors: FieldErrors<FieldValues>;
  watch: UseFormWatch<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
  setError: UseFormSetError<FieldValues>;
  clearErrors: UseFormClearErrors<FieldValues>;
  inputProfileItems: ProfileSinglePageViewData;
  ageChanged: (age: number) => void;
};

const ProfileNew: VFC<Props> = ({
  register,
  errors,
  watch,
  setValue,
  setError,
  clearErrors,
  inputProfileItems,
  ageChanged,
}) => (
    <>
      {inputProfileItems.profilePageViewData.sections.map((section) => (
        <FormItemList
          formItems={section.formItems}
          register={register}
          errors={errors}
          watch={watch}
          ageChanged={ageChanged}
          setValue={setValue}
          setError={setError}
          clearErrors={clearErrors}
          itemMargin='m'
          errorMargin={0}
          itemTitleSize='large'
          itemSize='small'
          birthDateSize='large'
          selectSize='large'
        />
      ))}
    </>);

export default ProfileNew;
