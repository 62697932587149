import useNavigationBarItemsSetting from 'global/hooks/use-navigation-bar-items-setting';
import { VFC } from 'react';
import ScrollToTopWhenMounted from 'global/components/ScrollToTopWhenMounted/ScrollToTopWhenMounted';
import { ClientCompanyRepositoryImpl } from '../client-company/data/repositories/client-company-repository';
import useMyDataTop from '../hooks/use-my-data-top';
import MyDataTopPage from '../pages/MyDataTopPage';

const MyDataTopContainer: VFC = () => {
  const { hasNewClientCompany } = useMyDataTop(
    new ClientCompanyRepositoryImpl(),
  );
  useNavigationBarItemsSetting('マイデータ');

  // アクションからマイデータの各編集画面へ遷移後に更新しなかったユーザーへの対策
  localStorage.removeItem('achieveActionDetail')

  return (
    <ScrollToTopWhenMounted>
      <MyDataTopPage
        hasNewClientCompany={hasNewClientCompany}
      />
    </ScrollToTopWhenMounted>
  );
};

export default MyDataTopContainer;
