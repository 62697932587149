import PhoneNumberAuthenticationRepository, {
  PhoneNumberAuthenticationRepositoryImpl,
} from 'features/sign-up/phone-number/authentication/data/repositories/phone-number-authentication-repository';
import ErrorDTO, { isErrorDTO } from 'data/dto/error-dto';
import Result, { Failure, Success } from 'global/utilities/result';

type ReturnType = {
  submitted: (phoneNumberAuthCode: number, password: string) => void;
};

const usePhoneNumberAuthentication = (
  repository: PhoneNumberAuthenticationRepository = new PhoneNumberAuthenticationRepositoryImpl(),
  authResult: (result: Result<undefined, ErrorDTO | undefined>) => void,
): ReturnType => {
  const submitted = async (
    phoneNumberAuthCode: number,
    password: string,
  ): Promise<void> => {
    try {
      await repository.auth(phoneNumberAuthCode, password);
      authResult(new Success(undefined));
    } catch (error) {
      const _ = isErrorDTO(error)
        ? authResult(new Failure(error))
        : authResult(new Failure(undefined));
    }
  };

  return { submitted };
};

export default usePhoneNumberAuthentication;
