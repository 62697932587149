import { VFC } from 'react';
import styles from './CampaignPage.module.css';
import SummaryBoard from '../components/SummaryBoard';
import QuestionsList from '../components/QuestionsList';
import useQuestionsList from '../hooks/use-questions-list';

const CampaignPage: VFC = () => {
  const { questionsListResult } = useQuestionsList();

  return (
    <div>
      <div className={styles.container}>
        <SummaryBoard onTop={false} />
        <div className={styles.questionSection}>
          {questionsListResult.isSuccess() &&
            questionsListResult.value.map((question) => (
              <QuestionsList question={question} key={question.name} />
            ))}
          <div className={styles.space} />
        </div>
      </div>
    </div>
  );
};

export default CampaignPage;
