import { useQuery } from 'react-query';
import { isErrorDTO } from 'data/dto/error-dto';
import Result from 'global/utilities/result';
import createResult from 'global/utilities/create-result-from-query-result';
import { useState } from 'react';
import { GENERAL_REQUEST_ERROR_MESSAGE } from 'global/constants';
import CampaignSummaryRepository, {
  CampaignSummaryRepositoryImpl,
} from '../data/repositories/campaign-summary-repository';
import CampaignSummaryViewData from '../view-data/campaign-summary-view-data';
import CampaignSummaryDto from '../data/dto/campaign-summary-dto';

type ReturnType = {
  summaryBoardVisibleOnTop: boolean;
  hideSummaryBoadFromTop: () => void;
  checkSummaryBoardDisalbe: (data: CampaignSummaryViewData) => void;
  campaignSummaryResult: Result<CampaignSummaryViewData, Error>;
  topBannerVisible: boolean;
};

export const convertDtoToViewData = (
  dto: CampaignSummaryDto,
): CampaignSummaryViewData => {
  const isEnd = Date.parse(dto.endAt) < Date.now();

  const campaignSummaryViewData: CampaignSummaryViewData = {
    isAchieve: dto.isAchieve,
    isEnd,
  };

  return campaignSummaryViewData;
};

const useCampaignSummary = (
  repository: CampaignSummaryRepository = new CampaignSummaryRepositoryImpl(),
): ReturnType => {
  const [summaryBoardVisibleOnTop, setSummaryBoardVisibleOnTop] =
    useState(true);

  const hideSummaryBoadFromTop = (): void => {
    setSummaryBoardVisibleOnTop(() => false);
  };

  let topBannerVisible = true;

  const checkSummaryBoardDisalbe = (data: CampaignSummaryViewData): void => {
    if (!summaryBoardVisibleOnTop) {
      return;
    }

    // トップから消す条件(上から優先順位高い)
    // 1. キャンペーンが終了している場合
    // 2. is_archieveがtrueの場合（キャンペーン達成済み、または過去キャンペーンでポイント付与済み）

    if (data.isEnd || data.isAchieve) {
      hideSummaryBoadFromTop();
      topBannerVisible = false;
    }
  };

  const queryResult = useQuery<CampaignSummaryViewData, Error>(
    ['/campaign/register/progress'],
    async () => {
      const dto = await repository.fetch().catch((error) => {
        if (isErrorDTO(error)) {
          throw Error(error.error.message);
        }
        throw Error(GENERAL_REQUEST_ERROR_MESSAGE);
      });

      return convertDtoToViewData(dto);
    },
  );

  const campaignSummaryResult = createResult(queryResult);

  return {
    summaryBoardVisibleOnTop,
    hideSummaryBoadFromTop,
    checkSummaryBoardDisalbe,
    campaignSummaryResult,
    topBannerVisible,
  };
};

export default useCampaignSummary;
