import ApiClient from 'data/api-client/api-client';
import RequestConfig, { URL } from 'data/request-configs/request-config';
import AnnouncementBannersDto from '../dto/announcement-banners-dto';

const createAnnouncementBannerRequestConfig = (): RequestConfig => ({
  endpoint: {
    baseUrl: URL.FIT_STATS,
    path: '/users/me/announcement_banners',
  },
  method: 'get',
  parameters: {},
});

interface AnnouncementBannersRepository {
  fetch: () => Promise<AnnouncementBannersDto>;
}

export class AnnouncementBannersRepositoryImpl
  implements AnnouncementBannersRepository
{
  fetch = async (): Promise<AnnouncementBannersDto> =>
    new ApiClient().connect(createAnnouncementBannerRequestConfig());
}

export default AnnouncementBannersRepositoryImpl;
