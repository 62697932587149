import Dialog from 'global/components/dialog/Dialog';
import useDialog from 'global/components/dialog/use-dialog';
import useNavigationBarItemsSetting from 'global/hooks/use-navigation-bar-items-setting';
import { VFC, useState } from 'react';
import { WithdrawalSettingsRepositoryImpl } from '../data/repositories/withdrawal-settings-repository';
import useWithdrawalSettings from '../hooks/use-withdrawal-settings';
import WithdrawalSettingsPage from '../pages/WithdrawalSettingsPage';

const WithdrawalSettingsContainer: VFC = () => {
  useNavigationBarItemsSetting('退会', {
    isCloseButtonVisible: true,
    isMenuButtonVisible: false,
    isNotificationButtonVisible: false,
    isBackButtonVisible: true,
  });

  const [isDisabled, setIsDisabled] = useState(false);
  const { isOpen, requestShowing, dialogProps, onClose } = useDialog();
  const { viewData, onChangeReasonChecked, onSubmit } = useWithdrawalSettings(
    (onTapDecided) => {
      requestShowing({
        title: '本当に退会しますか？',
        description:
          '「退会する」を押すと、退会処理が完了し、FitStatsのあなたのアカウント情報が全て削除され、FitStatsをご利用いただけなくなります。',
        primaryButton: {
          text: '退会する',
          onClick: onTapDecided,
        },
        destructiveButton: { text: '戻る' },
      });
    },
    new WithdrawalSettingsRepositoryImpl(),
    (saveResult) => {
      if (saveResult.isFailure()) {
        requestShowing({
          title: 'エラー',
          description: saveResult.error.message,
          primaryButton: {
            text: 'OK',
          },
        });
      }
    setIsDisabled(false);
    },
  );

  return (
    <>
      <Dialog
        isOpen={isOpen}
        title={dialogProps?.title}
        description={dialogProps?.description}
        primaryButton={dialogProps?.primaryButton ?? { text: '閉じる' }}
        destructiveButton={dialogProps?.destructiveButton}
        onClose={onClose}
        isDisabled={isDisabled}
        setIsDisabled={setIsDisabled}
      />

      <WithdrawalSettingsPage
        viewData={viewData}
        onChangeReasonChecked={onChangeReasonChecked}
        submitted={onSubmit}
      />
    </>
  );
};

export default WithdrawalSettingsContainer;
