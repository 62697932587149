import CopyToClipboard from 'react-copy-to-clipboard';
import { VFC } from 'react';

export type Props = {
  textToCopy: string;
  buttonLabel?: string;
  onCopy?: (text: string, result: boolean) => void;
};

const handlePostCopy = () => { /* console.log('default handler!'); */ };

const CopyToClipboardButton: VFC<Props> = ({ textToCopy, buttonLabel = 'copy', onCopy = handlePostCopy }) => (
  <CopyToClipboard
    text={ textToCopy }
    onCopy={ onCopy }>
    <label htmlFor='copyButton'>
      <button type="button" name='copyButton'>
        {buttonLabel}
      </button>
    </label>
  </CopyToClipboard>
);

export default CopyToClipboardButton;
