import { Integrations } from "@sentry/tracing";
import * as Sentry from "@sentry/react";

const sentryDsn = process.env.SENTRY_DSN;

const setupSentry = (): void => {
  if (sentryDsn) {
    Sentry.init({
      dsn: sentryDsn,
      environment: 'production',
      integrations: [new Integrations.BrowserTracing()],
      
      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 1.0,
    });
  }
}


export default setupSentry;
