import { useQuery } from 'react-query';
import { useHistory } from 'react-router';
import SettingsRootRepository, {
  SettingsRootRepositoryImpl,
} from 'features/settings/root/settings-root/data/repositories/settings-root-repository';
import Result from 'global/utilities/result';
import createResult from 'global/utilities/create-result-from-query-result';
import { SignOutRepositoryImpl } from 'features/logout/data/sign-out-repository';
import { DateTime } from 'luxon';
import { HELP_TOP, OpenHelpPage } from 'global/utilities/open-help-page';
import useClearCaches from 'global/hooks/use-clear-caches';
import SettingsRootViewData, {
  SettingsCategory,
  SettingsContentsViewData,
} from '../view-data/settings-root-view-data';
import SettingsRootDto from '../data/dto/settings-root-dto';

type ReturnType = {
  fetchResult: Result<SettingsRootViewData, Error>;
  onClickSettingMenu: (category: SettingsCategory) => void;
  onClickLogout: () => void;
};

// NOTE: クライアントでのみ必要な導線onlyのデータなので直接書き込む
const createSettingsContentsViewData = (): SettingsContentsViewData[] => [
  {
    category: 'accountSettings',
    name: 'アカウント設定',
  },
  {
    category: 'referAFriend',
    name: '友達紹介',
  },
  {
    category: 'helpInquiry',
    name: 'ヘルプとお問い合わせ',
  },
  {
    category: 'withdrawal',
    name: 'FitStatsの退会',
  },
  {
    category: 'teamOfService',
    name: '利用規約',
  },
  {
    category: 'privacyPolicy',
    name: '個人情報の取り扱いについて',
  },
];

const switchSettingsContentsViewData = () => {
  if (localStorage.getItem('refer_a_friend') === 'false' ) {
    return createSettingsContentsViewData().filter(item => item.category !== 'referAFriend');
  }

    return createSettingsContentsViewData();
}

const convertDtoToViewData = (
  dto: SettingsRootDto | undefined,
): SettingsRootViewData => {
  if (!dto) {
    return {
      failedLoggedInCount: ' - ',
      settings: switchSettingsContentsViewData(),
      lastLoggedInAt: '取得に失敗しました',
    };
  }
  let lastLogin = 'ログイン履歴なし';
  if (dto.successHistories.length > 1) {
    // NOTE: evidenceAtの昇順で返ってくるため最後のものを対象にしている
    lastLogin = DateTime.fromISO(
      dto.successHistories[dto.successHistories.length - 1].evidenceAt,
    )
      .setZone('Asia/Tokyo')
      .toFormat('yyyy/MM/dd HH:mm:ss');
  }

  return {
    failedLoggedInCount: dto.failureHistories.length,
    settings: switchSettingsContentsViewData(),
    lastLoggedInAt: lastLogin,
  };
};

const useSettingsRoot = (
  logoutConfirm: (onTapDecided: () => void) => void,
  onError: (errorMessage: string) => void,
  repository: SettingsRootRepository = new SettingsRootRepositoryImpl(),
): ReturnType => {
  const history = useHistory();
  const { clearCaches } = useClearCaches();
  const queryResult = useQuery<SettingsRootViewData, Error>(
    ['/settings-root'],
    async () => {
      const dto = await repository.fetch().catch(() => undefined);

      // 道連れで設定画面が全て見えなくなるのを防ぐため
      // fetchに失敗しても設定画面を表示させるためエラーは拾わない
      return convertDtoToViewData(dto);
    },
  );

  const fetchResult = createResult(queryResult);

  const onClickSettingMenu = (category: SettingsCategory): void => {
    switch (category) {
      case 'accountSettings':
        history.push('/acount_settings');
        break;
      case 'referAFriend':
        history.push('/refer_a_friend/referral_code');
        break;
      case 'helpInquiry':
        OpenHelpPage(HELP_TOP);
        break;
      case 'withdrawal':
        history.push('/withdrawal_settings');
        break;
      case 'teamOfService':
        history.push('/settings/terms_of_use');
        break;
      case 'privacyPolicy':
        history.push('/settings/privacy_policy');
        break;
      default:
        break;
    }
  };

  const onClickLogout = (): void => {
    logoutConfirm(() => {
      const _ = new SignOutRepositoryImpl()
        .delete()
        .then(() => {
          localStorage.removeItem('deviceId');
          clearCaches();
          history.push('/');
        })
        .catch(() => onError('ログアウトに失敗しました。'));
    });
  };

  return {
    fetchResult,
    onClickSettingMenu,
    onClickLogout,
  };
};

export default useSettingsRoot;
