import Result from 'global/utilities/result';
import { useHistory } from 'react-router';
import { VFC } from 'react';
import requestWebCommand, {
  OpenExternalWeb,
} from 'global/utilities/web-command';
import AnnouncementBannersDto from '../data/dto/announcement-banners-dto';
import styles from './AnnouncementBanners.module.css';

type Props = {
  announcementBannersFetchResult: Result<AnnouncementBannersDto, Error>;
};

const AnnouncementBanners: VFC<Props> = ({
  announcementBannersFetchResult,
}) => {
  const history = useHistory();

  const goToNextPage = (urlType: string, url: string) => {
    switch (urlType) {
      case 'internal':
        return history.push(url);
      case 'external':
        return requestWebCommand(new OpenExternalWeb(url));
      default:
        return '';
    }
  };

  return (
    <>
      <div>
        {announcementBannersFetchResult.isSuccess() &&
          announcementBannersFetchResult.value.announcementBanners?.map(
            (announcementBanner) => (
              <div
                key={announcementBanner.code}
                aria-hidden
                onClick={() => {
                  goToNextPage(
                    announcementBanner.urlType,
                    announcementBanner.url,
                  );
                }}
              >
                <img
                  src={announcementBanner.imageSrc}
                  alt=""
                  className={styles.image}
                />
              </div>
            ),
          )}
      </div>
    </>
  );
};

export default AnnouncementBanners;
