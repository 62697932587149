import { VFC } from 'react';
import { Switch, Route } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import OfferTopContainer from 'features/offer/containers/index';
import AnnouncementOfferDetailContainer from 'features/offer/containers/announcement_offer_detail';
import QuestionnaireOfferDetailContainer from 'features/offer/containers/questionnaire_offer_detail';
import ContainerResolver from 'features/tutorial/ContainerResolver';
import TutorialOfferTopContainer from './containers/tutorial-index';

const OfferRouter: VFC<RouteComponentProps> = ({ match }) => (
  <Switch>
    <Route exact path={`${match.path}`}>
      <ContainerResolver
        defaultContainer={<OfferTopContainer />}
        tutorialContainer={<TutorialOfferTopContainer />}
      />
    </Route>
    <Route path={`${match.path}/announcement_offers/:offerId`}>
      <AnnouncementOfferDetailContainer />
    </Route>
    <Route path={`${match.path}/questionnaire_offers/:offerId`}>
      <QuestionnaireOfferDetailContainer />
    </Route>
  </Switch>
);

export default OfferRouter;
