import { VFC, useState } from 'react';
import { useHistory } from 'react-router';
import useDialog from 'global/components/dialog/use-dialog';
import Dialog from 'global/components/dialog/Dialog';
import useNavigationBarItemsSetting from 'global/hooks/use-navigation-bar-items-setting';
import EmailResettingsPage from '../pages/EmailResettingsPage';
import useEmailResettings from '../hooks/use-email-resettings';

const EmailResettingsContainer: VFC = () => {
  const history = useHistory();
  const { isOpen, requestShowing, dialogProps, onClose } = useDialog();
  const [isDisabled, setIsDisabled] = useState(false);
  const { submitted } = useEmailResettings((saveResult) => {
    if (saveResult.isSuccess()) {
      history.push('/email_resettings/authentication', {
        email: saveResult.value,
      });
    }
    if (saveResult.isFailure()) {
      requestShowing({
        title: 'エラーが発生しました',
        description: saveResult.error,
        primaryButton: { text: 'OK' },
      });
    }
    setIsDisabled(false);
  });
  useNavigationBarItemsSetting('メールアドレスの再設定');

  return (
    <>
      <Dialog
        title={dialogProps?.title}
        description={dialogProps?.description}
        primaryButton={dialogProps?.primaryButton ?? { text: '閉じる' }}
        isOpen={isOpen}
        onClose={onClose}
      />

      <EmailResettingsPage submitted={submitted} isDisabled={isDisabled} setIsDisabled={setIsDisabled} />
    </>
  );
};

export default EmailResettingsContainer;
