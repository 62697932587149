import IntegrationCodeRepository, { IntegrationCodeDotmoneySeed, IntegrationCodeRepositoryImpl } from "features/integration-code/data/repositories/integration-code-repository";
import requestWebCommand, { OpenExternalWeb } from "global/utilities/web-command";
import { DotmoneyItemId } from "../view-data/my-data-top-view-data";

type ReturnType = {
  dotmoneyLinkButtonTapped: (iid: DotmoneyItemId) => void;
};

const usePointExchangeMenu = (): ReturnType => {
  const dotmoneyLinkButtonTapped = (
    iid: DotmoneyItemId,
    integrationCodeRepository: IntegrationCodeRepository = new IntegrationCodeRepositoryImpl(),
    ): void => {
      const seed: IntegrationCodeDotmoneySeed = {
        iid,
      };

      const _ = integrationCodeRepository
        .generateIntegrationCode(seed)
        .then((value) => {
          requestWebCommand(
            new OpenExternalWeb(
              `${process.env.REACT_APP_DNP_DOTMONEY_POINT_SYSTEM_URL}/public/dotmoney/input?key=${value.code}`,
            ),
          );
        });
  };

  return {
    dotmoneyLinkButtonTapped,
  };
};

export default usePointExchangeMenu;