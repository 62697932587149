import { isErrorDTO } from 'data/dto/error-dto';
import { DataConsentRepositoryImpl } from 'features/my-data/data/repositories/data-consent-repository';
import useTopTutorial from 'features/top/hooks/use-top-tutorial';
import { TutorialLocationState } from 'features/tutorial/hooks/use-tutorial-navigation';
import CoarchMark from 'global/components/CoarchMark/CoarchMark';
import DialogInSlideShow, {
  DialogUsedSlide,
} from 'global/components/dialog-in-slide-show/DialogInSlideShow';
import useDialogInSlideShow from 'global/components/dialog-in-slide-show/use-dialog-in-slide-show';
import Dialog from 'global/components/dialog/Dialog';
import useDialog from 'global/components/dialog/use-dialog';
import Loading from 'global/components/Loading/Loading';
import TutorialFetchErrorDialog from 'global/components/TutorialFetchErrorDialog/TutorialFetchErrorDialog';
import useNavigationBarItemsSetting from 'global/hooks/use-navigation-bar-items-setting';
import { useCallback, useState, VFC } from 'react';
import { useHistory, useLocation } from 'react-router';
import { ClientCompanyRepositoryImpl } from '../data/repositories/client-company-repository';
import useClientCompany from '../hooks/use-client-company';
import ClientCompanyPage from '../pages/ClientCompanyPage';

const TutorialClientCompanyContainer: VFC = () => {
  useNavigationBarItemsSetting('データ提供する企業を選ぶ', {
    isCloseButtonVisible: false,
    isMenuButtonVisible: false,
    isNotificationButtonVisible: false,
    isBackButtonVisible: false,
  });

  const { isOpen, dialogProps, requestShowing, onClose } = useDialog();
  const history = useHistory<TutorialLocationState>();
  const { state } = useLocation<TutorialLocationState | undefined>();
  const [currentShowsCloseButton, setShowsCloseButton] = useState(true);
  const [currentOnCloseAction, setOnCloseAction] = useState(() => onClose);

  const {
    isOpen: isSlideShowOpen,
    currentPage,
    button,
    requestShowing: requestSlideShowShowing,
    requestSlidePaging,
    requestButtonChanging,
  } = useDialogInSlideShow();

  const { pointEventFailedAction, pointEventSuccessedAction, startTutorial } =
    useTopTutorial();

  const openTutorialDialog = useCallback(() => {
    requestSlideShowShowing({
      text: '次へ',
      onClick: (page) => {
        requestSlidePaging(page + 1);
      },
    });
  }, [requestSlideShowShowing, requestSlidePaging]);

  const openErrorDialog = useCallback(() => {
    requestShowing();
  }, [requestShowing]);

  const didLoad = useCallback(() => {
    startTutorial(openTutorialDialog, openErrorDialog);
  }, [openTutorialDialog, openErrorDialog, startTutorial]);

  const slide1: DialogUsedSlide = {
    kind: 'common',
    imageSrc: '/global/get_point.png',
    title: pointEventSuccessedAction?.title,
    description: pointEventSuccessedAction?.description,
  };

  const slide2: DialogUsedSlide = {
    kind: 'common',
    imageSrc: '/tutorial/tutorial_point_exchange.png',
    title: '選べるポイント交換先！',
    description:
      '貯めたFitStatsポイントは、人気の電子マネーや共通ポイントなどに交換できます。\n※一部交換先を除き、ポイントは新規登録後15日が経過してから交換可能です',
  };

  const slide3: DialogUsedSlide = {
    kind: 'common',
    imageSrc: '/tutorial/tutorial_finished_modal.png',
    title: '初回登録は全て完了しました',
    description:
      '毎日の健康スコアをチェックしながら、\nお得に健康生活を始めましょう！',
  };



  const elements = [slide1, slide2, slide3];

  const {
    fetchResult,
    pageLoaded,
    storageData,
    closeButtonTapped,
    didConsentChanged,
    saveButtonTapped,
  } = useClientCompany(
    new ClientCompanyRepositoryImpl(),
    new DataConsentRepositoryImpl(),
    (saveResult) => {
      if (saveResult.isSuccess()) {
        requestSlideShowShowing({
          text: '次へ',
          onClick: (page) => {
            requestSlidePaging(page + 1);
          },
        });
        didLoad();
        setShowsCloseButton(false);
        setOnCloseAction(() => ''); // チュートリアル中なので、閉じない
      }
      if (saveResult.isFailure()) {
        requestShowing({
          title: 'エラー',
          description: isErrorDTO(saveResult.error)
            ? saveResult.error.error.message
            : '公開するデータの更新に失敗しました。',
          primaryButton: { text: 'OK' },
        });
      }
    },
  );

  const PopOverValue = {
    children: (
      <div>
        あなたにオファーを配信できるのは、あなたがデータ提供を許可した企業のみです。
        <br />
        より多くのオファーを受け取るため、気になる企業にデータを提供してみましょう。
      </div>
    ),
    clickOption: {
      clickWithCoarchMarkClosed: true,
    },
  };

  if (fetchResult.isSuccess()) {
    return (
      <>
        <Dialog
          title={dialogProps?.title}
          description={dialogProps?.description}
          imageSrc={dialogProps?.imageSrc}
          primaryButton={dialogProps?.primaryButton ?? { text: 'OK' }}
          isOpen={isOpen}
          showsCloseButton={currentShowsCloseButton}
          onClose={currentOnCloseAction}
        />
        <ClientCompanyPage
          clientConmapnyData={fetchResult.value}
          pageLoaded={pageLoaded}
          storageData={storageData}
          closeButtonTapped={closeButtonTapped}
          didConsentChanged={didConsentChanged}
          saveButtonTapped={saveButtonTapped}
          locationState={state}
        />
        <DialogInSlideShow
          isOpen={isSlideShowOpen}
          slideElements={elements}
          selectedPage={currentPage}
          buttonText={button.text}
          onClose={() => {
            // チュートリアル中なので、閉じない
          }}
          onButtonClicked={() => button.onClick(currentPage)}
          onPageChanged={(page) => {
            if (page >= elements.length - 1) {
              requestButtonChanging({
                text: 'FitStatsをはじめる',
                onClick: () => {
                  onClose();
                  history.push('/top');
                },
              });
            } else {
              requestButtonChanging({
                text: '次へ',
                onClick: () => {
                  requestSlidePaging(page + 1);
                },
              });
            }
            if (page !== currentPage) {
              requestSlidePaging(page);
            }
          }}
        />
        {!state?.hideCoarchMark && (
          <CoarchMark
            elementId="tutorial-mydata-client-company"
            popOverOption={PopOverValue}
          />
        )}
        {pointEventFailedAction && (
          <Dialog
            title={pointEventFailedAction.title}
            description={pointEventFailedAction.description}
            primaryButton={pointEventFailedAction.primaryButton}
            isOpen={isOpen}
            onClose={pointEventFailedAction.onClose}
          />
        )}
      </>
    );
  }

  if (fetchResult.isFailure()) {
    return <TutorialFetchErrorDialog />;
  }

  return <Loading />;
};

export default TutorialClientCompanyContainer;
