/* eslint-disable jsx-a11y/label-has-associated-control */
import SquareCheckboxWithLabel from 'global/components/square-checkbox-with-label/SquareCheckboxWithLabel';
import { useState, VFC, useEffect } from 'react';
import sanitizeHtml from 'sanitize-html';
import Button from '../../components/button/Button';
import styles from './TermsOfUseAndPrivacyPolicyPage.module.css';

type Props = {
  name: string;
  content: string;
  submitted?: () => void;
  isDisabled?: boolean;
  setIsDisabled?: React.Dispatch<React.SetStateAction<boolean>>;
};

const TermsOfUseAndPrivacyPolicyPage: VFC<Props> = ({
  name,
  content,
  submitted,
  isDisabled,
  setIsDisabled,
}) => {
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className={styles.container}>
        <div className={styles.contentArea}>
          <div
            className={styles.content}
            // 前提として、下記2点がある
            // 1. ユーザーは本文を全て読んでからでなければ同意のチェックを付けられないようにしたい
            // 2. DNPのAPI (別ドメイン) を叩くとURLが返り、更にそのURLをGETするとHTMLが返ってくる
            // 上記の前提を踏まえ最初にiframeの利用を検討したが、クロスドメインのiframe表示ではスクロール量の検知が難しかったため、
            // 今回は非推奨ではあるが dangerouslySetInnerHTML を利用することとした
            // see: https://developer.mozilla.org/ja/docs/Web/HTML/Element/iframe#scripting
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: sanitizeHtml(content, {
                // リンクを外部ブラウザで開くためにリンクにweb-commandをセットするが、sanitizeHtmlに弾かれてしまうため対応
                // see: https://github.com/apostrophecms/sanitize-html#allowed-url-schemes
                allowedSchemes: ['fitstats-web-command'],
                allowedTags: false,
                allowedAttributes: false,
              }),
            }}
          />
          {submitted !== undefined && (
            <div className={styles.checkbox}>
              <SquareCheckboxWithLabel
                name="checkbox"
                value=""
                label={`${name}に同意する`}
                checked={checked}
                onClick={() => setChecked(!checked)}
              />
            </div>
          )}
        </div>
      </div>
      <div className={styles.fixedBottom}>
        <div className={styles.buttonArea}>
          {submitted !== undefined && (
            <Button
              text="次へ"
              type="primary"
              size="large"
              disabled={!checked || isDisabled}
              onClick={() => {
                setIsDisabled?.(true);
                submitted();
              }}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default TermsOfUseAndPrivacyPolicyPage;
