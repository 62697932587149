import { VFC } from 'react';
import WelcomeFirstPage from '../pages/WelcomeFirstPage';

const WelcomeFirstContainer: VFC = () => (
    <>
      <WelcomeFirstPage />
    </>
  );

export default WelcomeFirstContainer;
