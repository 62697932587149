import { UserInputPostItem } from 'features/profile/user-input-item';
import ApiClient from 'data/api-client/api-client';
import RequestConfig, { URL } from 'data/request-configs/request-config';
import PasswordAndProfileRegistrationDto from '../dto/password-and-profile-registration-dto';

const createPasswordAndProfileRegistrationConfig = (
  password: string | null,
  privacyPolicyVersion: string | null,
  termsOfUseVersion: string | null,
  userProfile: UserInputPostItem[],
  referralCode: string | null,
): RequestConfig => ({
  endpoint: {
    baseUrl: URL.FIT_STATS,
    path: '/users/registration/profile_and_password',
  },
  method: 'post',
  parameters: {
    password,
    privacy_policy_version: privacyPolicyVersion,
    terms_of_use_version: termsOfUseVersion,
    user_profile: userProfile,
    referral_code: referralCode,
  },
});

interface PasswordAndProfileRegistrationRepository {
  post: (
    password: string | null,
    privacyPolicyVersion: string | null,
    termsOfUseVersion: string | null,
    userProfile: UserInputPostItem[],
    referralCode: string | null,
  ) => Promise<PasswordAndProfileRegistrationDto>;
}

export class PasswordAndProfileRegistrationRepositoryImpl
  implements PasswordAndProfileRegistrationRepository
{
  post = async (
    password: string | null,
    privacyPolicyVersion: string | null,
    termsOfUseVersion: string | null,
    userProfile: UserInputPostItem[],
    referralCode: string | null,
  ): Promise<PasswordAndProfileRegistrationDto> =>
    new ApiClient().connect(
      createPasswordAndProfileRegistrationConfig(
        password,
        privacyPolicyVersion,
        termsOfUseVersion,
        userProfile,
        referralCode,
      ),
    );
}

export default PasswordAndProfileRegistrationRepository;
