import { VFC } from 'react';
import Result from 'global/utilities/result';
import OfferTopViewData, {
  UserOfferTypeViewData,
} from 'features/offer/view-data/offer-top-view-data';
import OfferListItem from 'features/offer/components/OfferListItem';
import { Divider } from '@chakra-ui/react';
import Container from 'global/components/Container/Container';
import Button from 'global/components/button/Button';
import { useHistory } from 'react-router';
import Loading from 'global/components/Loading/Loading';
import styles from './TopOffers.module.css';
import offerListItemStyles from '../../offer/components/OfferListItem.module.css'

type Props = {
  offersFetchResult: Result<OfferTopViewData, Error>;
  onTapOffersItem: (id: string, type: UserOfferTypeViewData) => void;
};

const TopOffers: VFC<Props> = ({ offersFetchResult, onTapOffersItem }) => {
  const history = useHistory();

  return (
    <>
      {offersFetchResult.isSuccess() &&
        (offersFetchResult.value.offers.length ? (
          <>
            <div className={styles.sectionTitle}>新着オファー</div>
            <div className={offerListItemStyles.top}>
            {offersFetchResult.value.offers.map((offer) => (
              <>
                <Container marginTop="s" marginBottom="s">
                  <OfferListItem
                    offer={offer}
                    onTapOffersItem={onTapOffersItem}
                  />
                </Container>
                <Divider orientation="horizontal" />
              </>
            ))}
            </div>
            <Container marginTop="m">
              <Button
                text="もっと見る"
                type="default"
                size="large"
                onClick={() => history.push('/offers')}
              />
            </Container>
          </>
        ) : (
          <></>
        ))}
      {offersFetchResult.isFailure() && (
        <>
          <div className={styles.sectionTitle}>新着オファー</div>
          <p>{offersFetchResult.error.message}</p>
        </>
      )}
      {offersFetchResult.isInProgress() && (
        <>
          <div className={styles.sectionTitle}>新着オファー</div>
          <Loading />
        </>
      )}
    </>
  );
};
export default TopOffers;
