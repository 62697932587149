/* eslint-disable import/prefer-default-export */
export const GLOBAL_NAVIGATION_HEIGHT_PX = 64;

export const GENERAL_REQUEST_ERROR_MESSAGE =
  '一時的な通信エラーによりリクエストが完了しませんでした。もう一度お試しください。';
export const GENERAL_INTERNAL_SERVER_ERROR_MESSAGE = 'FitStatsに不具合が起きてます。一度FitStatsを終了させて再度お試しください。';
export const EMAIL_VALIDATION_MESSAGES = {
  EMAIL_IS_REQUIRED: 'メールアドレスは入力必須の項目です。',
  INPUT_EMAIL_IS_INVALID_PATTERN: '正しいメールアドレスを入力してください。',
  INPUT_EMAIL_DOMAIN_IS_INVALID: '有効なメールアドレスを入力してください。',
  INPUT_EMAIL_LENGTH_IS_ABOVE: '50文字以下で入力してください。',
  NEW_EMAIL_IS_DUPLICATED: '現在のメールアドレスと重複しています。現在のメールアドレスと異なるアドレスを入力してください。',
}
