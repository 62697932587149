import { useEffect, useState, useRef, VFC } from 'react';
import { Prompt } from 'react-router';
import PopOver from '../PopOver/PopOver';
import styles from './CoarchMark.module.css';

type popOverClickOption = {
  clickWithCoarchMarkClosed?: boolean;
  clickCustomAction?: () => void;
};

// 強調したい要素の上下どちらにpopoverを表示するか
export type popOverPosition = 'top' | 'bottom';

type popOverValue = {
  children: JSX.Element;
  buttonText?: string;
  clickOption?: popOverClickOption;
  position?: popOverPosition;
  floating?: boolean; // ターゲットがフローティングなUIの時に使う
};

export type Props = {
  elementId: string;
  popOverOption: popOverValue;
};

const CoarchMark: VFC<Props> = ({ elementId, popOverOption }) => {
  const [currentPopOverEnabled, setPopOverEnabled] = useState(false);
  const [currentPopOverPosition, setPopOverPosition] = useState(0);

  // 吹き出しのしっぽの横方奥の位置
  const [popOverTailPosition, setPopOverTailPosition] = useState<number|string|undefined>(undefined);
  const popOverRef = useRef<HTMLDivElement>(null);

  // 吹き出しの高さ取得とそれを元にした表示位置調整
  useEffect(() => {
    if (popOverRef.current) {
      const resizeObserver = new ResizeObserver((entries: ResizeObserverEntry[]) => {
        entries.forEach((entry) => {
          const { height } = entry.target.getBoundingClientRect();
          if(popOverOption.floating) {
            const target = document.getElementById(elementId) as HTMLElement;
            const clientRect = target.getBoundingClientRect();
            setPopOverPosition(clientRect.top - height);
          }
        })
      });

      resizeObserver.observe(popOverRef.current);

      return () => {
        resizeObserver.disconnect();
      };
    }

    return undefined;
  }, [elementId, popOverOption.floating]);


  const handleClick = () => {
    // ターゲットidを検索し、css classを付与（ターゲットを画面最前面に表示する）
    const target = document.getElementById(elementId) as HTMLElement;
    target.classList.add(styles.tutorialTarget);
    // FIXME: チュートリアル用。結合が密になるだけなのでなんとかしたい。
    if(elementId === 'global_navigation_offer') {
      target.classList.add(styles.fit);
    }

    // body全体をoverlayする
    const body = document.getElementById('tutorial-body') as HTMLElement;
    body.classList.add(styles.tutorialBody);

    // ターゲットの位置を検索し、popoverをセットする
    const clientRect = target.getBoundingClientRect();
    const popover = document.getElementById('tutorial-popover') as HTMLElement;
    popover.classList.add(styles.tutorialPopOver);
    const popOverRect = popover.getBoundingClientRect();

    // popoverを指定位置に表示する
    setPopOverEnabled(true);
    setPopOverPosition(clientRect.bottom);

    // FIXME: チュートリアルのTop画面用の調整。
    //        floatingかどうかは本質的には無関係のはずなので適切に処理できるように直したい。
    //        そのまま使えるかもしれないしそうでないかもしれない。
    if(popOverOption.floating) {
      popover.classList.add(styles.float);
      // 吹き出しのしっぽの横方向の位置をターゲットの中央へ持ってくる
      setPopOverTailPosition(clientRect.x + clientRect.width / 2 - popOverRect.x);
    }
  };

  const setPopOverClosed = () => {
    // popoverを非表示にする
    setPopOverEnabled(false);
  };

  const setCoarchMarkClosed = () => {
    // body全体のoverlayを解除
    const body = document.getElementById('tutorial-body') as HTMLElement;
    body.classList.remove(styles.tutorialBody);

    // ターゲットidの画面最前面表示を解除
    const target = document.getElementById(elementId) as HTMLElement;
    target.classList.remove(styles.tutorialTarget);
    // FIXME: チュートリアル用。結合が密になるだけなのでなんとかしたい。
    if(elementId === 'global_navigation_offer') {
      target.classList.remove(styles.fit);
    }
  };

  const onPopOverClick = () => {
    if (popOverOption.clickOption?.clickCustomAction) {
      popOverOption.clickOption.clickCustomAction();
    }

    if (popOverOption.clickOption?.clickWithCoarchMarkClosed) {
      setCoarchMarkClosed();
    }

    setPopOverClosed();
  };

  // ターゲットの要素の読み込みが完了するまでpopoverの位置を確定できないので、画像の読み込み完了後に処理を始める。
  // 背景: useEffectだけだと遅れて読み込まれた画像等でターゲットの表示位置が変わりpopoverが追従できないことがある。
  useEffect(() => {
    const imageTags: HTMLImageElement[] = [...document.getElementsByTagName('img')];

    const imageCount = imageTags
                          .filter(img => img.getAttribute('src'))
                          .length;
    const imageLoadCount = () => imageTags
                                    .filter(img => img.complete)
                                    .length;
    const allImagesLoadingCompleted = () => imageLoadCount() >= imageCount;

    const handleClickOnce = (() => {
      let executed = false;

      return () => {
        if(!executed) {
          executed = true;
          handleClick();
        }
      }
    })();

    if (imageCount === 0) {
      handleClick();
    }

    imageTags.forEach(img => {
      // eslint-disable-next-line no-loop-func
      if(allImagesLoadingCompleted()) {
        handleClickOnce();
      } else {
        img.addEventListener('load', () => {
          if (allImagesLoadingCompleted()) {
            handleClickOnce();
          }
        });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      id="tutorial-popover"
      ref={popOverRef}
      style={{
        top: currentPopOverPosition + (popOverOption.position && popOverOption.position === 'top' ? -24 : 24),
        width: '90%',
      }}
    >
      <PopOver
        enabled={currentPopOverEnabled}
        onClick={onPopOverClick}
        buttonText={popOverOption.buttonText}
        direction={popOverOption.position && popOverOption.position === 'top' ? 'downward' : 'upward' }
        beforeLeft={popOverTailPosition}
      >
        {popOverOption.children}
      </PopOver>
      <Prompt
        when
        message={() => {
          setCoarchMarkClosed();

          // see: https://v5.reactrouter.com/core/api/Prompt/message-func
          return true;
        }}
      />
    </div>
  );
};

export default CoarchMark;
