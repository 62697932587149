import Result from 'global/utilities/result';
import { VFC } from 'react';
import AdvertisementBannersDto from '../data/dto/advertisement-banners-dto';
import styles from './AdvertisementBanners.module.css';

type Props = {
  advertisementBannersFetchResult: Result<AdvertisementBannersDto, Error>;
  advertisementBannerTapped: (
    code: string,
    urlType: string,
    url: string,
  ) => void;
};

const AdvertisementBanners: VFC<Props> = ({
  advertisementBannersFetchResult,
  advertisementBannerTapped,
}) => (
  <>
    {advertisementBannersFetchResult.isSuccess() &&
      advertisementBannersFetchResult.value.advertisementBanners?.map(
        (advertisementBanner) => (
          <div className={styles.advertisementBanner}>
            <div
              key={advertisementBanner.code}
              aria-hidden
              onClick={() => {
                advertisementBannerTapped(
                  advertisementBanner.code,
                  advertisementBanner.urlType,
                  advertisementBanner.url,
                );
              }}
            >
              <img
                src={advertisementBanner.imageSrc}
                alt=""
                className={styles.image}
              />
            </div>
          </div>
        ),
      )}
  </>
);

export default AdvertisementBanners;
