import { VFC } from "react";
import PointIcon from "global/components/PointIcon/PointIcon";
import {
  HELP_WHAT_IS_FITSTATS_POINT,
  OpenHelpPage
} from "global/utilities/open-help-page";
import UndraggableLink from "global/components/UndraggableLink/UndraggableLink";
import styles from './FitstatsPoint.module.css';
import useFitstatsPoint from "../../hooks/use-fitstats-point";

type Props = { showHistoryLink: boolean };

const FitstatsPoint: VFC<Props> = ({ showHistoryLink }) => {
  const { fitstatsPointResult } = useFitstatsPoint();

  return (
    <div className={styles.sectionWrapper}>
      <div className={styles.section}>
        <div className={styles.sectionTitle}>
          <div className={styles.sectionTitleItem}>FitStatsポイント</div>
          <div className={styles.sectionTitleItem}>
            {showHistoryLink && (
              <UndraggableLink className={styles.linkText} to="/point_detail">
                履歴を確認
              </UndraggableLink>
            )}
          </div>
        </div>
        <div className={styles.availablePointSection}>
          <div className={styles.availablePointSectionItem}>
            <div className={styles.pointIconWrapper}>
              <PointIcon />
            </div>
            <div className={styles.availablePointNumber}>
              {fitstatsPointResult.isSuccess() &&
                fitstatsPointResult.value.ptsAvailable.toLocaleString()}
            </div>
            <div className={styles.availablePointUnit}>
              {fitstatsPointResult.isSuccess() && <div>pt</div>}
              {fitstatsPointResult.isInProgress() && <div>データ取得中...</div>}
              {fitstatsPointResult.isFailure() && <div>取得に失敗しました</div>}
            </div>
          </div>
          <button
            type="button"
            className={styles.availablePointSectionItem}
            onClick={() => OpenHelpPage(HELP_WHAT_IS_FITSTATS_POINT)}
          >
            FitStatsポイントとは
          </button>
        </div>
        <div className={styles.expirePointSection}>
          <div className={styles.expirePointSectionItem}>
            今月末に失効するポイント
          </div>
          <div className={styles.expirePointSectionItem}>
            {fitstatsPointResult.isSuccess() && (
              <div>
                {fitstatsPointResult.value.ptsExpired.toLocaleString()} pt
              </div>
            )}
            {fitstatsPointResult.isInProgress() && <div>データ取得中...</div>}
            {fitstatsPointResult.isFailure() && <div>取得に失敗しました</div>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FitstatsPoint;
