import { useQuery } from 'react-query';
import { DateTime } from 'luxon';
import { isErrorDTO } from 'data/dto/error-dto';
import { GENERAL_REQUEST_ERROR_MESSAGE } from 'global/constants';
import ClientCompanyRepository, {
  ClientCompanyRepositoryImpl,
} from '../client-company/data/repositories/client-company-repository';
import ClientCompanyDto from '../client-company/data/dto/client-company-dto';
import lastPageLoadAt from '../client-company/hooks/utils/last-page-load-at';

type ReturnType = {
  hasNewClientCompany: boolean;
};

const convertDtoToViewData = (
  dto: ClientCompanyDto,
  lastClientCompanyPageLoadAt: DateTime | undefined,
): boolean => {
  if (lastClientCompanyPageLoadAt) {
    const newClientCompany = dto.clients.find(
      (v) => lastClientCompanyPageLoadAt <= v.registeredAt,
    );

    return !!newClientCompany;
  }

  return true;
};

const useMyDataTop = (
  repository: ClientCompanyRepository = new ClientCompanyRepositoryImpl(),
): ReturnType => {
  const queryResult = useQuery<boolean, Error>(['/mydata_top'], async () => {
    const dto = await repository.fetch().catch((error) => {
      if (isErrorDTO(error)) {
        throw Error(error.error.message);
      }
      throw Error(GENERAL_REQUEST_ERROR_MESSAGE);
    });

    return convertDtoToViewData(dto, lastPageLoadAt());
  });

  const hasNewClientCompany = queryResult.isSuccess ? queryResult.data : false;

  return {
    hasNewClientCompany,
  };
};

export default useMyDataTop;
