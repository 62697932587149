import { Divider } from '@chakra-ui/react';
import FormItemList from 'features/profile/components/FormItemList';
import { UserInputRawData } from 'features/profile/user-input-item';
import {
  defaultParentalConsentStatus,
  isNeedParentalConsent,
} from 'features/profile/utils/function-for-profile-view-data';
import AttentionContainer from 'global/components/AttentionContainer/AttentionContainer';
import BottomContainer from 'global/components/BottomContainer/BottomContainer';
import Button from 'global/components/button/Button';
import Container from 'global/components/Container/Container';
import Dialog from 'global/components/dialog/Dialog';
import useDialog from 'global/components/dialog/use-dialog';
import SquareCheckboxWithLabel from 'global/components/square-checkbox-with-label/SquareCheckboxWithLabel';
import {
  useEffect,
  createRef,
  useRef,
  useState,
  RefObject,
  VFC,
  useMemo,
} from 'react';
import { useForm } from 'react-hook-form';
import { Prompt, useHistory } from 'react-router';
import NoticeContainer from 'global/components/NoticeContainer/NoticeContainer';
import EditPersonalPageViewData, { EditPersonalPageStorageViewData } from '../view-data/edit-personal-page-view-data';
import styles from './PersonalDataEditPage.module.css';

type SectionReference = {
  identifier: string;
  ref: RefObject<HTMLDivElement>;
};

type Props = {
  result: EditPersonalPageViewData;
  submitted: (userInput: UserInputRawData) => void;
  storageData: EditPersonalPageStorageViewData;
  closeButtonTapped: () => void;
};

type ScrollAnchorLocationState = {
  anchor: string;
};

const PersonalDataEditPage: VFC<Props> = ({ result, submitted, storageData, closeButtonTapped }) => {
  const {
    register,
    watch,
    handleSubmit,
    formState: { errors, isValid, isDirty },
    setValue,
    setError,
    clearErrors,
  } = useForm<UserInputRawData>({
    mode: 'onBlur',
    defaultValues: result.userData.userProfile?.reduce(
      (obj, item) => ({ ...obj, [item.name]: item.value }),
      {},
    ),
  });

  const [hasParentalConsent, setParentalConsent] = useState(
    defaultParentalConsentStatus(result.userData.userProfile),
  );

  const [currentAge, setAge] = useState(0);

  const ageChanged = (age: number): void => {
    setAge(age);
  };

  const { isOpen, requestShowing, onClose } = useDialog();

  const shouldBlockTransition = useRef(false);
  const transitionPath = useRef('/');

  const leavePage = () => {
    shouldBlockTransition.current = true;

    return isDirty;
  };

  const history = useHistory();

  const destructiveButtonTapped = () => {
    shouldBlockTransition.current = false;
    history.push(transitionPath.current);
  };

  type DialogProps = {
    title?: string;
    description: string;
    primaryButton: {
      text: string;
      action?: () => void;
    };
    destructiveButton?: {
      text: string;
      action: () => void;
    };
  };

  const dialogProps = useRef<DialogProps | undefined>(undefined);

  const customRequestShowing = (props: DialogProps) => {
    dialogProps.current = {
      title: props.title,
      description: props.description,
      primaryButton: {
        text: props.primaryButton.text,
        action: props.primaryButton.action,
      },
      destructiveButton: props.destructiveButton && {
        text: props.destructiveButton.text,
        action: props.destructiveButton.action,
      },
    };

    requestShowing();
  };

  const submittedAction = () => {
    customRequestShowing({
      title: '確認画面',
      description:
        "入力した内容を最新の\nデータとして更新します。",
      primaryButton: {
        text: '更新する',
        action: handleSubmit(submitted),
      },
    });
  };

  const sectionRefs = useMemo(
    () =>
      result.profileData.map(
        (section): SectionReference => ({
          identifier: section.title,
          ref: createRef<HTMLDivElement>(),
        }),
      ),
    [result.profileData],
  );

  const scrollAnchorLocationState =
    useHistory<ScrollAnchorLocationState | undefined>();

  const isBirthdayValid = (): boolean => {
    if (watch('year') && watch('month') && watch('day') && !errors.birthday) {
      return true;
    }

    return false;
  };

  useEffect(() => {
    const scrollToSection = () => {
      if (!scrollAnchorLocationState.location.state?.anchor) {
        return;
      }
      const ref = sectionRefs.find(
        (sectionRef) =>
          sectionRef.identifier ===
          scrollAnchorLocationState.location.state?.anchor,
      );

      if (ref?.ref.current == null) {
        return;
      }
      ref.ref.current.scrollIntoView({
        behavior: 'auto',
        block: 'start',
      });
    };

    scrollToSection();
  }, [scrollAnchorLocationState.location.state?.anchor, sectionRefs]);

  return (
    <div className={styles.profilePageWrapper}>
      {storageData.isCloseButtonTapped !== true && (
          <Container marginBottom="s" marginLeft="s" marginRight="s">
            <NoticeContainer closeButtonCustomAction={closeButtonTapped}>
              プロフィールや普段の過ごし方など、あなたの基本データを登録しましょう。<br/>
              登録したデータの中から、企業に提供するデータを選ぶことができます。
            </NoticeContainer>
          </Container>
      )}
      <div>
        <form>
          {result.profileData.map(
            (section) =>
              section.formItems.length > 0 && (
                <>
                  <Container marginBottom={22} marginLeft="s" marginRight="s">
                    <div
                      className={styles.sectionTitle}
                      ref={
                        sectionRefs.find(
                          (sectionRef) =>
                            sectionRef.identifier === section.title,
                        )?.ref
                      }
                    >
                      {section.title}
                    </div>
                  </Container>
                  <div className={styles.formItemList}>
                    <FormItemList
                      formItems={section.formItems}
                      register={register}
                      errors={errors}
                      watch={watch}
                      ageChanged={ageChanged}
                      setValue={setValue}
                      setError={setError}
                      clearErrors={clearErrors}
                    />
                  </div>
                </>
              ),
          )}
          <Container marginLeft="s" marginRight="s">
            {isBirthdayValid() && isNeedParentalConsent(currentAge) && (
              <Container marginTop="m" marginBottom="m">
                <AttentionContainer>
                  <Container marginTop={10} marginBottom={10}>
                    <Container marginLeft={32}>
                      <div className={styles.profilePageAttentionDescription}>
                        FitStatsでは、お客様の個人情報を取り扱うため、16歳未満の方は親権者の同意が必要になります。
                      </div>
                    </Container>
                    <Container marginTop={6}>
                      <SquareCheckboxWithLabel
                        name="parental_consent"
                        value="consented"
                        label="親権者の同意を得ている"
                        checked={hasParentalConsent}
                        onClick={() => setParentalConsent(!hasParentalConsent)}
                      />
                    </Container>
                  </Container>
                </AttentionContainer>
              </Container>
            )}
          </Container>
        </form>
      </div>
      <div className={styles.bottomContainer}>
        <BottomContainer>
          <Divider orientation="horizontal" />
          <Container
            marginTop={50}
            marginBottom={50}
            marginLeft="s"
            marginRight="s"
          >
            <Button
              text="更新する"
              type="primary"
              size="large"
              disabled={
                !isValid ||
                (isNeedParentalConsent(currentAge)
                  ? !hasParentalConsent
                  : false)
              }
              onClick={submittedAction}
            />
          </Container>
        </BottomContainer>
      </div>
      <Prompt
        when={leavePage()}
        message={(location) => {
          if (location.state === 'TransitionWithoutBlock') {
            return true;
          }

          transitionPath.current = location.pathname;

          customRequestShowing({
            description:
              'データを編集中です。編集したデータを更新せず終了してよろしいですか？',
            primaryButton: {
              text: '編集に戻る',
            },
            destructiveButton: {
              text: 'はい',
              action: destructiveButtonTapped,
            },
          });

          return !shouldBlockTransition.current;
        }}
      />
      <Dialog
        title={dialogProps.current?.title}
        description={dialogProps.current?.description}
        primaryButton={{
          text: dialogProps.current?.primaryButton.text || '',
          onClick: dialogProps.current?.primaryButton.action,
        }}
        destructiveButton={
          dialogProps.current?.destructiveButton && {
            text: dialogProps.current?.destructiveButton.text,
            onClick: dialogProps.current?.destructiveButton.action,
          }
        }
        isOpen={isOpen}
        onClose={onClose}
      />
    </div>
  );
};

export default PersonalDataEditPage;
