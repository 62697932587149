import { isErrorDTO } from 'data/dto/error-dto';
import Result, { Failure, Success } from 'global/utilities/result';
import EmailResettingsRepository, {
  EmailResettingsRepositoryImpl,
} from '../data/repositories/email-resettings-repository';

type ReturnType = {
  submitted: (currentEmail: string, newEmail: string) => void;
};

const useEmailResettings = (
  saveResult: (result: Result<string, string>) => void,
  repository: EmailResettingsRepository = new EmailResettingsRepositoryImpl(),
): ReturnType => {
  const submitted = (currentEmail: string, newEmail: string): void => {
    // emailにスペースが含まれている場合、バリデーションで弾いてしまうのがアプリの挙動として適切だが、
    // 下記の経緯のため、スペースのトリムによる実装とする。
    // ref. https://dnp-pjm-s0001.cloudmine.jp/issues/4698
    const replacedNewEmail: string = newEmail.replace(/\s+/g, "");
    const _ = repository
      .resetting(currentEmail, replacedNewEmail)
      .then(() => {
        saveResult(new Success(replacedNewEmail));
      })
      .catch((e) => {
        saveResult(
          new Failure(
            isErrorDTO(e)
              ? e.error.message
              : 'メールアドレスの登録に失敗しました',
          ),
        );
      });
  };

  return {
    submitted,
  };
};

export default useEmailResettings;
