import useNavigationBarItemsSetting from 'global/hooks/use-navigation-bar-items-setting';
import { VFC } from 'react';
import ScrollToTopWhenMounted from 'global/components/ScrollToTopWhenMounted/ScrollToTopWhenMounted';
import CampaignPage from '../pages/CampaignPage';

const CampaignContainer: VFC = () => {
  useNavigationBarItemsSetting('登録キャンペーン');

  return (
    <ScrollToTopWhenMounted>
      <CampaignPage />
    </ScrollToTopWhenMounted>
  );
};

export default CampaignContainer;
