import RequestConfig, { URL } from 'data/request-configs/request-config';
import ApiClient from 'data/api-client/api-client';
import PasswordValidityCheckDto from '../dto/password-validity-check-dto';

const createPasswordValidityCheckRequestConfig = (
  props: string,
): RequestConfig => ({
  endpoint: {
    baseUrl: URL.FIT_STATS,
    path: `/users/me/password/validity_check`,
  },
  method: 'post',
  parameters: { password: props },
});

interface PasswordValidityCheckRepository {
  fetch: (password: string) => Promise<PasswordValidityCheckDto>;
}

export class PasswordValidityCheckRepositoryImpl
  implements PasswordValidityCheckRepository
{
  fetch = async (password: string): Promise<PasswordValidityCheckDto> =>
    new ApiClient().connect(createPasswordValidityCheckRequestConfig(password));
}

export default PasswordValidityCheckRepository;
