import { VFC } from 'react';
import { useHistory } from 'react-router';
import styles from './SummaryBoard.module.css';
import useCampaignSummary from '../hooks/use-campaign-summary';

type Props = { onTop: boolean; paddingTop?: boolean };

const SummaryBoard: VFC<Props> = ({ onTop, paddingTop }) => {
  const history = useHistory();

  const {
    summaryBoardVisibleOnTop,
    checkSummaryBoardDisalbe,
    campaignSummaryResult,
  } = useCampaignSummary();

  const linkToCampaign = () => {
    if (onTop) {
      history.push('/campaign');
    }
  };

  // summaryBoardVisibleOnTop: トップ画面でサマリーボードを見せるかどうか
  // トップ画面(onTop)でない場合は表示する
  const summaryBoardVisible = summaryBoardVisibleOnTop || !onTop;

  return (
    <>
      {campaignSummaryResult.isSuccess() &&
        checkSummaryBoardDisalbe(campaignSummaryResult.value)}
      {summaryBoardVisible && (
        <div>
          {campaignSummaryResult.isSuccess() && (
            <div
              className={
                onTop && paddingTop ? styles.containerWithPaddingTop : ''
              }
            >
              {!onTop && (
                <>
                  <div className={styles.topBanner} />
                  <div className={styles.boardDescription}>
                    すべての条件を達成して、人気の電子マネーや共有ポイントに交換できるFitStatsポイント470ptをGETしよう！
                    <br />
                    <br />
                    【達成条件】
                    <br />
                    以下4つの条件すべてを達成するとポイントが進呈されます。
                    <br />
                    ①「郵便番号」「番地」「マンション・建物名」を除くすべての本人情報の登録（回答）
                    <br />
                    ② セルフチェック全問回答
                    <br />
                    ③ すべての企業に対してデータ提供同意
                    <br />
                    ④「郵便番号」「番地」「マンション・建物名」を除くすべてのデータ提供同意
                    <br />
                    <br />
                    ※本登録キャンペーンへのご参加はお1人様1回のみです。同一人物による複数回の参加が発覚した場合、ポイントの進呈を取り消すことがあります。
                    <br />
                    ※既に全項目回答・提供済みでも、再度更新していただく必要がございます（再度更新していただくことで「回答済み」「提供済み」になります）。
                    <br />
                    ※本施策は事前の予告なく終了したり、付与するポイント数を変動することがあります。
                  </div>
                </>
              )}
              {onTop && (
                <div aria-hidden onClick={linkToCampaign}>
                  <img
                    className={styles.onTopImage}
                    src="/banners/registration_campaign/on_top_campaign_banner@3x.png"
                    alt=""
                  />
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </>
  );
};
export default SummaryBoard;
