import {
  ProfilePage,
  ProfileFormItem,
} from 'features/profile/data/dto/profile-dto';
import { isYearMonthDay } from 'global/utilities/year-month-day';
import PersonalDataViewData, {
  PersonalDataItemViewData,
} from '../../view-data/personal-page-view-data';

import PersonalDataDto from '../../data/dto/personal-data-dto';
import DataConsentDto from '../../../data/dto/data-consent-dto';

const PERSONAL_DATA_VALUE_SEPARATOR = '';

const personalDataValue = (
  formItems: ProfileFormItem[],
  personalData: PersonalDataDto,
): string | undefined => {
  const valueForDisplay = (formItem: ProfileFormItem): string | undefined => {
    if (!formItem.manualEntry) {
      return undefined;
    }

    const value = personalData.userProfile.find(
      (v) => v.name === formItem.inputElement.name,
    )?.value;

    if (Array.isArray(value)) {
      return value.join(', ');
    }

    if (isYearMonthDay(value)) {
      return value.separatedByHyphen();
    }

    return value || '';
  };

  const formItemsManualEntry: boolean = formItems.some(
    (formItem) => formItem.manualEntry === true,
  );

  // formItemsが複数あり、かつマイデータで入力できる項目（manualEntry: true）が含まれている場合に入力データを結合
  if (formItems.length > 1 && formItemsManualEntry) {
    return formItems
      .map((item) => valueForDisplay(item))
      .join(PERSONAL_DATA_VALUE_SEPARATOR);
  }

  return valueForDisplay(formItems[0]);
};

const buildPersonalDataItems = (
  formItems: ProfileFormItem[],
  personalDataDto: PersonalDataDto,
  consentDto: DataConsentDto,
): PersonalDataItemViewData[] => {
  const formItemList = formItems.reduce((result: ProfileFormItem[][], item) => {
    const newResult = result;
    const index = result.findIndex((items) =>
      items.some((formItem) => formItem.agreementGroup === item.agreementGroup),
    );

    if (index === -1) {
      newResult.push([item]);
    } else {
      const targetFormItems = result[index];
      targetFormItems.push(item);
      newResult[index] = targetFormItems;
    }

    return newResult;
  }, []);

  return formItemList.map(
    (items): PersonalDataItemViewData => ({
      title: items[0].agreementGroup,
      value: personalDataValue(items, personalDataDto),
      consent: items
        .map((item) =>
          consentDto.consentItems.find(
            (consentItem) => consentItem.name === item.inputElement.name,
          ),
        )
        .every((consentItem) => consentItem?.consent === true),
      properties: items.map((item) => ({ name: item.inputElement.name })),
    }),
  );
};

const buildPersonalDataViewData = (
  profileDto: ProfilePage,
  personalDataDto: PersonalDataDto,
  consentDto: DataConsentDto,
): PersonalDataViewData => ({
  sections: profileDto.sections.map((section) => ({
    title: section.title,
    items: buildPersonalDataItems(
      section.formItems,
      personalDataDto,
      consentDto,
    ),
  })),
});

export default buildPersonalDataViewData;
