import { VFC } from 'react';
import { Switch, Route } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import LandingContainer from 'features/sign-up/landing/containers/index';

const LandingRouter: VFC<RouteComponentProps> = ({ match }) => (
  <Switch>
    <Route exact path={`${match.path}`}>
      <LandingContainer />
    </Route>
  </Switch>
);

export default LandingRouter;

