/* eslint-disable no-param-reassign */
import { useQuery } from 'react-query';
import { useState, useEffect, useRef } from 'react';
import Result, { Failure, Success } from 'global/utilities/result';
import createResult from 'global/utilities/create-result-from-query-result';
import { isErrorDTO } from 'data/dto/error-dto';
import ProfileRepository, {
  ProfileRepositoryImpl,
} from 'features/profile/data/repositories/profile-repository';
import { useHistory } from 'react-router';
import { ProfileSectionViewData } from 'features/profile/view-data/profile-view-data';
import { UserInputRawData } from 'features/profile/user-input-item';
import queryClient from 'global/query-client';
import { GENERAL_REQUEST_ERROR_MESSAGE } from 'global/constants';
import PersonalDataRepository, {
  PersonalDataRepositoryImpl,
} from '../data/repositories/personal-data-repository';
import EditPersonalPageViewData, { EditPersonalPageStorageViewData } from '../view-data/edit-personal-page-view-data';
import convertUserInputRawDataForRequest from './utils/convert-user-input-raw-data-for-request';
import convertPersonalDataDtoToViewData from './utils/convert-personal-data-dto-to-view-data';
import PopupInfoViewData from '../../../profile/view-data/popup-info-view-data';
import PopupInfoViewDto from '../../../profile/data/dto/popup-info-dto';

const PERSONAL_DATA_EDIT_CACHE_KEY = '/edit_personal_data';
const IS_NOTICE_CLOSED_DATA_KEY = '/edit_personal_data_notice_closed';

type ReturnType = {
  fetchResult: Result<EditPersonalPageViewData, Error>;
  submitAction: SubmitDialogActionType | undefined;
  submitted: (userInput: UserInputRawData) => void;
  storageData: EditPersonalPageStorageViewData;
  closeButtonTapped: () => void;
  popupInfoCount: number;
  setPopupInfoCount: (count: number) => void;
};

type SubmitDialogActionType = {
  title?: string;
  description?: string;
  primaryButtonText?: string;
  primaryButtonAction?: () => void;
};

type AchieveActionDetailType = {
  actionId: number;
};

const usePersonalDataEdit = (
  userProfileDataRepository: PersonalDataRepository = new PersonalDataRepositoryImpl(),
  profileDataRepository: ProfileRepository = new ProfileRepositoryImpl(),
  saveResult: (result: Result<PopupInfoViewData, Error>) => void,
): ReturnType => {
  const submitAction = useRef<SubmitDialogActionType | undefined>(undefined);

  useEffect(() => {
    const clearQuery = () => {
      queryClient.removeQueries({
        queryKey: PERSONAL_DATA_EDIT_CACHE_KEY,
      });
    };

    return clearQuery;
  }, []);

  const queryResult = useQuery<EditPersonalPageViewData, Error>(
    PERSONAL_DATA_EDIT_CACHE_KEY,
    async () => {
      try {
        const userProfileDto = await userProfileDataRepository.fetch();
        const profileDto = await profileDataRepository.fetch();

        const targetProfilePage = profileDto.pages.find(
          (page) => page.key === 'mydata',
        );

        const resultProfileData: ProfileSectionViewData[] = [];
        targetProfilePage?.sections.map((section) =>
          resultProfileData.push({
            title: section.title,
            formItems: section.formItems.filter(
              (item) => item.manualEntry === true,
            ),
          }),
        );

        if (targetProfilePage) {
          return {
            userData: convertPersonalDataDtoToViewData(userProfileDto),
            profileData: resultProfileData,
          };
        }
        throw Error(GENERAL_REQUEST_ERROR_MESSAGE);
      } catch (error) {
        if (isErrorDTO(error)) {
          throw Error(error.error.message);
        }
        throw Error(GENERAL_REQUEST_ERROR_MESSAGE);
      }
    },
  );

  const fetchResult = createResult(queryResult);

  const history = useHistory();

  const convertDtoToViewData = (dto: PopupInfoViewDto): PopupInfoViewData =>
    dto;

  // 報酬獲得時のポップアップの表示回数をView側で判断して内容を動的に出し分けるため
  // やりたいことに対して複雑なので一連のポップアップをひとまとめにした方がスッキリするかも
  const [popupInfoCount, updatePopupInfoCount] = useState(0);
  const setPopupInfoCount = (count: number): void => {
    updatePopupInfoCount(() => count);
  };

  const submitted = (userInput: UserInputRawData) => {
    const { year, month, day } = userInput;
    const birthday = `${year as string}-${month as string}-${day as string}`;
    delete userInput.year;
    delete userInput.month;
    delete userInput.day;
    userInput.birthday = birthday;

    const json: string | null = localStorage.getItem('achieveActionDetail');
    const detail =
      json !== null ? (JSON.parse(json) as AchieveActionDetailType) : null;

    const userPostItems = convertUserInputRawDataForRequest(userInput);
    const _ = profileDataRepository
      .save(userPostItems, detail?.actionId)
      .then((value) => {
        const viewData = convertDtoToViewData(value);
        updatePopupInfoCount(() => viewData.popupInfo.length);
        submitAction.current = {
          title: '内容が更新されました。',
          description:
            "企業に提供する\nデータは、あなた自身で\n選ぶことができます。",
          primaryButtonText: '提供するデータを選ぶ',
          primaryButtonAction: () =>
            history.push('/mydata/personal', 'TransitionWithoutBlock'),
        };
        saveResult(new Success(viewData));
      })
      .catch((error) => {
        submitAction.current = {
          title: 'エラー',
          description: isErrorDTO(error)
            ? error.error.message
            : 'プロフィールの編集に失敗しました',
        };
        saveResult(new Failure(Error('プロフィールの編集に失敗しました')));
      })
      .finally(() => localStorage.removeItem('achieveActionDetail'));
  };

  const isCloseButtonTapped = (): boolean => {
    const data: string | null = localStorage.getItem(IS_NOTICE_CLOSED_DATA_KEY);

    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const parsedData = data === null ? undefined : JSON.parse(data);

    return typeof parsedData === 'boolean' ? parsedData : false;
  };

  const closeButtonTapped = () => {
    const formattedValue = JSON.stringify(true);

    localStorage.setItem(IS_NOTICE_CLOSED_DATA_KEY, formattedValue);
  };

  return {
    fetchResult,
    submitAction: submitAction.current,
    submitted,
    storageData: {
      isCloseButtonTapped: isCloseButtonTapped(),
    },
    closeButtonTapped,
    popupInfoCount,
    setPopupInfoCount,
  };
};

export default usePersonalDataEdit;
