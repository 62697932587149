import ApiClient from 'data/api-client/api-client';
import RequestConfig, { URL } from 'data/request-configs/request-config';
import FunctionalStatusDto from '../dto/functional-status-dto';

const createRequestConfig = (serviceName: string): RequestConfig => ({
  endpoint: {
    baseUrl: URL.FIT_STATS,
    path: `/check_functionalities_statuses/${serviceName}`,
  },
  method: 'get',
  parameters: {},
});

interface CheckFunctionalStatusRepository {
  fetch: (
    serviceName: string,
  ) => Promise<FunctionalStatusDto>;
}

export class CheckFunctionalStatusRepositoryImpl
  implements CheckFunctionalStatusRepository {
  fetch = async (serviceName: string): Promise<FunctionalStatusDto> =>
    new ApiClient().connect(createRequestConfig(serviceName));
};

export default CheckFunctionalStatusRepository;
