import ApiClient from 'data/api-client/api-client';
import RequestConfig, { URL } from 'data/request-configs/request-config';
import AdvertisementBannersDto from '../dto/advertisement-banners-dto';

const createFetchAdvertisementBannersRequestConfig = (): RequestConfig => ({
  endpoint: {
    baseUrl: URL.FIT_STATS,
    path: '/users/me/advertisement_banners',
  },
  method: 'get',
  parameters: {},
});

const createAdvertisementBannerRequestConfig = (
  code: string,
): RequestConfig => ({
  endpoint: {
    baseUrl: URL.FIT_STATS,
    path: '/users/me/advertisement_banners',
  },
  method: 'post',
  parameters: { code },
});

interface AdvertisementBannersRepository {
  fetch: () => Promise<AdvertisementBannersDto>;
  create: (code: string) => Promise<void>;
}

export class AdvertisementBannersRepositoryImpl
  implements AdvertisementBannersRepository
{
  fetch = async (): Promise<AdvertisementBannersDto> =>
    new ApiClient().connect(createFetchAdvertisementBannersRequestConfig());

  create = async (code: string): Promise<void> =>
    new ApiClient().connect(createAdvertisementBannerRequestConfig(code));
}

export default AdvertisementBannersRepositoryImpl;
