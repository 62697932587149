/* eslint-disable react/jsx-props-no-spreading */
import { VFC } from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';
import styles from './InputBirthDate.module.css';

export type BirthDateSize = 'large' | 'medium';

export type Props = {
  registerYear?: UseFormRegisterReturn;
  registerMonth?: UseFormRegisterReturn;
  registerDay?: UseFormRegisterReturn;
  watchYear: string;
  watchMonth: string;
  birthDateSize?: BirthDateSize;
};

const InputBirthDate: VFC<Props> = ({
  registerYear,
  registerMonth,
  registerDay,
  watchYear,
  watchMonth,
  birthDateSize,
}) => {
  const createYear = () => {
    const list = [];
    list.push(
      <option key="year_0" value="">
        年
      </option>,
    );
    for (let i = new Date().getFullYear(); i >= 1900; i -= 1) {
      list.push(
        <option key={`year_${i}`} value={i}>
          {i}
        </option>,
      );
    }

    return list;
  };

  const createMonth = () => {
    const list = [];
    list.push(
      <option key="month_0" value="">
        月
      </option>,
    );
    for (let i = 1; i <= 12; i += 1) {
      list.push(
        <option key={`month_${i}`} value={`00${i}`.slice(-2)}>
          {i}
        </option>,
      );
    }

    return list;
  };
  const createDay = () => {
    const list = [];
    list.push(
      <option key="day_0" value="">
        日
      </option>,
    );
    const lastday = new Date(
      Number(watchYear),
      Number(watchMonth),
      0,
    ).getDate();
    for (let i = 1; i <= lastday; i += 1) {
      list.push(
        <option key={`day_${i}`} value={`00${i}`.slice(-2)}>
          {i}
        </option>,
      );
    }

    return list;
  };

  const classNameForBirthDateSize = (size?: BirthDateSize): string => {
    switch (size) {
      case 'large':
        return styles.birthDateLarge;
      case 'medium':
        return styles.birthDateMedium;
      default:
        return styles.birthDateMedium;
    }
  };

  return (
    <>
      <select
        name="year"
        {...registerYear}
        className={[
          styles.selectBirthDate,
          classNameForBirthDateSize(birthDateSize),
        ].join(' ')}
      >
        {createYear()}
      </select>
      <select
        name="month"
        {...registerMonth}
        className={[
          styles.selectBirthDate,
          classNameForBirthDateSize(birthDateSize),
        ].join(' ')}
      >
        {createMonth()}
      </select>
      <select
        name="date"
        {...registerDay}
        className={[
          styles.selectBirthDate,
          classNameForBirthDateSize(birthDateSize),
        ].join(' ')}
      >
        {createDay()}
      </select>
    </>
  );
};
export default InputBirthDate;
