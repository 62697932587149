/* eslint-disable react/jsx-props-no-spreading */
import { useState, VFC } from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';
import styles from './InputPassword.module.css';

type InputPasswordSize = 'large' | 'medium' | 'small';

export type Props = {
  name: string;
  value?: string;
  size?: InputPasswordSize;
  placeholder?: string;
  register?: UseFormRegisterReturn;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  readOnly?: boolean;
  visibleButtonNoColor?: boolean;
};

export const CHARACTER_TYPES = {
  UPPER_CASE_PATTERN: 'A-Z',
  LOWER_CASE_PATTERN: 'a-z',
  NUMBER_PATTERN: '0-9',
  SYMBOL_PATTERN: '!#$%&()^\\\\@?_', // DNPから指定の内容（DNP配信別サービスの「ライフラ」の仕様に準拠している)
};

export const PASSWORD_PATTERN = `^[${CHARACTER_TYPES.UPPER_CASE_PATTERN}${CHARACTER_TYPES.LOWER_CASE_PATTERN}${CHARACTER_TYPES.NUMBER_PATTERN}${CHARACTER_TYPES.SYMBOL_PATTERN}]+$`;

export const PASSWORD_MESSAGES = {
  NOT_MATCH_CONFIRMATION_MSG: '入力したパスワードが「再確認」と一致しません。',
  INVALID_PATTERN_MSG: 'パスワード作成の条件を満たしていません。',
  REQUIRED_MSG: 'パスワードは入力必須の項目です。',
  MAX_LENGTH_MSG: '64文字以下で入力してください。',
  MIN_LENGTH_MSG: '8文字以上で入力してください。',
  NOT_RECOMMENDABLE_PATTERN_MSG:
    '推測されやすい文字列や、一般的な単語などはパスワードに使用できません。（例：abcd1234）\nパスワードを複雑にして、再設定をお願いします。',
};

const classNameForInputPassword = (size?: InputPasswordSize): string => {
  switch (size) {
    case 'large':
      return styles.inputPasswordLarge;
    case 'medium':
      return styles.inputPasswordMedium;
    case 'small':
      return styles.inputPasswordSmall;
    default:
      return styles.inputPasswordLarge;
  }
};

const InputPassword: VFC<Props> = ({
  name,
  value,
  size,
  placeholder,
  register,
  onChange,
  readOnly,
  visibleButtonNoColor,
}) => {
  const [passwordVisible, setPasswordVisible] = useState(false);

  return (
    <>
      <input
        name={name}
        type={passwordVisible ? 'text' : 'password'}
        value={value}
        placeholder={placeholder || '8文字以上のpassword'}
        className={[classNameForInputPassword(size), styles.input].join(' ')}
        {...register}
        onChange={onChange}
        readOnly={readOnly}
        maxLength={64}
      />
      <button
        type="button"
        onClick={() => setPasswordVisible(!passwordVisible)}
        className={[
          styles.visibleButton,
          visibleButtonNoColor ? '' : styles.visibleButtonBlue,
        ].join(' ')}
        tabIndex={0}
      >
        {passwordVisible ? '非表示' : '表示'}
      </button>
    </>
  );
};

export default InputPassword;
