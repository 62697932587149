import RequestConfig, { URL } from 'data/request-configs/request-config';
import ApiClient from 'data/api-client/api-client';
import ReferralCodeDto from '../dto/referral-code-dto';

const createReferralCodePresenceRequestConfig = (props: string): RequestConfig => ({
  endpoint: {
    baseUrl: URL.FIT_STATS,
    path: `/refer_a_friend/referral_code/${props}/presence`,
  },
  method: 'get',
  parameters: {},
});

interface ReferralCodePresenceRepository {
  fetch: (
    myReferralCode: string
  ) => Promise<ReferralCodeDto>;
}

export class ReferralCodePresenceRepositoryImpl implements ReferralCodePresenceRepository {
  fetch = async (referralCode: string): Promise<ReferralCodeDto> =>
    new ApiClient().connect(createReferralCodePresenceRequestConfig(referralCode));
}

export default ReferralCodePresenceRepository;