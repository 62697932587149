abstract class WebCommand {
  abstract path(): string;

  get url(): string {
    return `fitstats-web-command://${this.path()}`;
  }
}

export class OpenExternalWeb extends WebCommand {
  targetUrl: string;

  constructor(targetUrl: string) {
    super();
    this.targetUrl = targetUrl;
  }

  path = (): string =>
    `open_external_browser_without_auth?url=${this.targetUrl}`;
}

export class CloseFitStatsView extends WebCommand {
  path = (): string => 'close_fitstats_view';
}

export class OpenLifelogMealTop extends WebCommand {
  path = (): string => `open_meal_top`;
}

export class OpenLifelogStepTop extends WebCommand {
  path = (): string => 'open_step_top';
}

export class OpenLifelogBodyWeightTop extends WebCommand {
  path = (): string => 'open_body_weight_top';
}

export class OpenLifelogSleepTop extends WebCommand {
  path = (): string => 'open_sleep_top';
}

export class RegistrationCompletion extends WebCommand {
  path = (): string => 'registration_completion';
}

export type NavigationBarTitle = string | NavigationBarTitleLogo;

type NavigationBarTitleLogo = {
  type: 'logo';
};

export type NavigationBarButtonSettings = {
  isCloseButtonVisible: boolean;
  isMenuButtonVisible: boolean;
  isNotificationButtonVisible: boolean;
  isBackButtonVisible: boolean;
};

export class SetNavigationBarItems extends WebCommand {
  title: NavigationBarTitle;
  buttonSettings: NavigationBarButtonSettings;

  constructor(
    title: NavigationBarTitle,
    buttonSettings: NavigationBarButtonSettings,
  ) {
    super();
    this.title = title;
    this.buttonSettings = buttonSettings;
  }

  path = (): string => {
    const type = typeof this.title === 'string' ? 'text' : this.title.type;
    let path = `set_menu_items?title_type=${type}`;

    if (typeof this.title === 'string') {
      path += `&title=${this.title}`;
    } else {
      switch (this.title.type) {
        case 'logo':
          break;
        default: {
          const _: never = this.title.type;

          break;
        }
      }
    }
    path += `&is_close_button_visible=${this.buttonSettings.isCloseButtonVisible.toString()}&is_menu_button_visible=${this.buttonSettings.isMenuButtonVisible.toString()}&is_notification_button_visible=${this.buttonSettings.isNotificationButtonVisible.toString()}&is_back_button_visible=${this.buttonSettings.isBackButtonVisible.toString()}`;

    return path;
  };
}

export class SetUnreadNotificationsCount extends WebCommand {
  count: number;

  constructor(count: number) {
    super();
    this.count = count;
  }

  path = (): string => `set_unread_notifications_count?count=${this.count}`;
}

export class LoadFitStatsServicePage extends WebCommand {
  path = (): string => 'load_fit_stats_service_page';
}

export class ShowShareUi extends WebCommand {
  displayText: string;

  constructor(displayText: string) {
    super();
    this.displayText = displayText;
  }

  path = (): string =>
    `show_share_ui?display_text=${this.displayText}`;
}


class WebCommandHandler {
  private webCommandQueue: WebCommand[] = [];
  private executingWebCommand = false;

  requestWebCommand = (webCommand: WebCommand) => {
    this.webCommandQueue.push(webCommand);
    this.execWebCommand();
  };

  private execWebCommand = () => {
    if (this.executingWebCommand) {
      this.wait(() => {
        this.execWebCommand();
      });
    } else {
      const webCommand = this.webCommandQueue.shift();
      if (webCommand != null) {
        this.executingWebCommand = true;
        this.wait(() => {
          window.location.href = webCommand.url;
          this.executingWebCommand = false;

          if (this.webCommandQueue.length > 0) {
            this.execWebCommand();
          }
        });
      }
    }
  };

  private wait = (executor: () => void) => {
    setTimeout(() => {
      executor();
    }, 50);
  };
}

const webCommandHandler = new WebCommandHandler();

const requestWebCommand = (webCommand: WebCommand): void => {
  webCommandHandler.requestWebCommand(webCommand);
};

export default requestWebCommand;
