import useNavigationBarItemsSetting from 'global/hooks/use-navigation-bar-items-setting';
import { VFC } from 'react';
import Dialog from 'global/components/dialog/Dialog';
import useDialog from 'global/components/dialog/use-dialog';
import ErrorContainer from 'global/components/ErrorContainer/ErrorContainer';
import Loading from 'global/components/Loading/Loading';
import NotFound from 'global/pages/NotFound';
import {useParams} from "react-router";
import useEntryCampaignDetail from '../hooks/use-entry-campaign-detail';
import EntryCampaignDetailPage from '../pages/EntryCampaignDetailPage';
import EntryCampaignCode from "../../program/data/common/entry-campaign-code";

const EntryCampaignDetailContainer: VFC = () => {
  const { isOpen, dialogProps, requestShowing, onClose } = useDialog();
  const { entryCampaignCode } = useParams<{ entryCampaignCode: string }>();

  const { fetchResult, entryButtonTapped } = useEntryCampaignDetail(
    EntryCampaignCode.fromString(entryCampaignCode),
    (errorMessage) => {
      requestShowing({
        description: errorMessage,
        primaryButton: { text: 'OK' },
      });
    },
  );

  useNavigationBarItemsSetting(fetchResult.getValue()?.name ?? 'キャンペーン');
  const entryCampaign = fetchResult.getValue();

  if (entryCampaign) {

    return (
      <>
        <Dialog
          title="エラー"
          description={dialogProps?.description}
          primaryButton={dialogProps?.primaryButton ?? { text: 'OK' }}
          isOpen={isOpen}
          onClose={onClose}
        />
        <EntryCampaignDetailPage
          entryCampaign={entryCampaign}
          entryButtonTapped={() => entryButtonTapped(entryCampaign)}
        />
      </>
    );
  }

  if (fetchResult.isSuccess()) {
    return <NotFound />;
  }

  if (fetchResult.isFailure()) {
    return <ErrorContainer>{fetchResult.error.message}</ErrorContainer>;
  }

  return <Loading />;
};
export default EntryCampaignDetailContainer;
