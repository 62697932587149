import { VFC } from 'react';
import Loading from 'global/components/Loading/Loading';
import useNavigationBarItemsSetting from 'global/hooks/use-navigation-bar-items-setting';
import ErrorContainer from 'global/components/ErrorContainer/ErrorContainer';
import ScrollToTopWhenMounted from 'global/components/ScrollToTopWhenMounted/ScrollToTopWhenMounted';
import ReferralCodePage from '../pages/ReferralCodePage';
import useReferralCode from '../hooks/use-referral-code';

const ReferralCodeContainer: VFC = () => {
  const { fetchResult } = useReferralCode();
  const referralCode = fetchResult.getValue();
  useNavigationBarItemsSetting('友達紹介');

  if (fetchResult.isSuccess() && referralCode) {
    return (
      <ScrollToTopWhenMounted>
        <ReferralCodePage referralCode={referralCode} />
      </ScrollToTopWhenMounted>
    );
  }

  if (fetchResult.isFailure()) {
    return <ErrorContainer>{fetchResult.error.message}</ErrorContainer>;
  }

  return <Loading />;
};

export default ReferralCodeContainer;
