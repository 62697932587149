import { VFC } from 'react';
import styles from './RankDescriptionList.module.css';

type Content = {
  rank: string;
  scoreFrom: number;
  scoreTo: number;
  description: string;
  rewardPoint?: number;
  recurringReward?: number;
};

type Props = {
  content: Content;
  index: number;
};

const RankDescriptionList: VFC<Props> = ({ content, index }) => (
  <>
    <div className={styles.descriptionContainer}>
      <div
        className={`${styles.descriptionTop} ${styles[`content${index + 1}`]}`}
      >
        <p className={styles.rank}>{content.rank}</p>
        <div className={styles.rankDescription}>
          <p
            className={styles.rangeScore}
          >{`スタッツ${content.scoreFrom}~${content.scoreTo}点`}</p>
          <p className={styles.text}>{content.description}</p>
        </div>
      </div>
      {content.rewardPoint && (
        <div className={styles.descriptionBottom}>
          <p className={styles.bonus}>
            初回ランクUPで<span>{`+${content.rewardPoint}pt`}</span>
          </p>
          {content.recurringReward && (
            <p className={styles.bonus}>
              20日間維持で<span>{`+${content.recurringReward}pt`}</span>
            </p>
          )}
        </div>
      )}
    </div>
    <hr />
  </>
);

export default RankDescriptionList;
