import ApiClient from 'data/api-client/api-client';
import RequestConfig, { URL } from 'data/request-configs/request-config';
import QuestionnaireTopDto from '../dto/self-check-dto';

const createSelfCheckRequestConfig = (): RequestConfig => ({
  endpoint: {
    baseUrl: URL.FIT_STATS,
    path: '/questionnaire/top',
  },
  method: 'get',
  parameters: {},
});

interface SelfCheckRepository {
  fetch: () => Promise<QuestionnaireTopDto>;
}

export class SelfCheckRepositoryImpl
  implements SelfCheckRepository
{
  fetch = async (): Promise<QuestionnaireTopDto> =>
    new ApiClient().connect(createSelfCheckRequestConfig());
}

export default SelfCheckRepository;
