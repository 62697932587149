import ApiClient from 'data/api-client/api-client';
import RequestConfig, { URL } from 'data/request-configs/request-config';
import ReferralCodeDto from '../dto/referral-code-dto';

const createQuestionnaireRequestConfig = (): RequestConfig => ({
  endpoint: {
    baseUrl: URL.FIT_STATS,
    path: '/refer_a_friend/referral_code/inquiry',
  },
  method: 'get',
  parameters: {},
});

interface ReferralCodeRepository {
  fetch: () => Promise<ReferralCodeDto>;
}

export class ReferralCodeRepositoryImpl
  implements ReferralCodeRepository
{
  fetch = async (): Promise<ReferralCodeDto> =>
    new ApiClient().connect(createQuestionnaireRequestConfig());
}

export default ReferralCodeRepository;
