/* eslint-disable react/jsx-props-no-spreading */
import { VFC } from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';
import styles from './InputTel.module.css';

type InputTelSize = 'large' | 'medium' | 'small';

export type Props = {
  name: string;
  value?: string;
  size?: InputTelSize;
  placeholder?: string;
  register?: UseFormRegisterReturn;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  readOnly?: boolean;
  hidden?: boolean;
};

const classNameForInputTel = (size?: InputTelSize): string => {
  switch (size) {
    case 'large':
      return styles.inputTelLarge;
    case 'medium':
      return styles.inputTelMedium;
    case 'small':
      return styles.inputTelSmall;
    default:
      return styles.inputTelMedium;
  }
};

const InputTel: VFC<Props> = ({
  name,
  value,
  size,
  placeholder,
  register,
  onChange,
  readOnly,
  hidden,
}) => (
  <input
    name={name}
    type="tel"
    value={value}
    placeholder={placeholder || '入力してください'}
    className={[classNameForInputTel(size), styles.input].join(' ')}
    {...register}
    onChange={onChange}
    readOnly={readOnly}
    hidden={hidden}
  />
);

export default InputTel;
