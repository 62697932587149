import { DataConsentRepositoryImpl } from 'features/my-data/data/repositories/data-consent-repository';
import DialogPrompt from 'global/components/dialog-prompt/DialogPrompt';
import Dialog from 'global/components/dialog/Dialog';
import useDialog from 'global/components/dialog/use-dialog';
import ErrorContainer from 'global/components/ErrorContainer/ErrorContainer';
import Loading from 'global/components/Loading/Loading';
import useNavigationBarItemsSetting from 'global/hooks/use-navigation-bar-items-setting';
import { VFC } from 'react';
import { reveiveFitStatsPointPage } from 'features/questionnaire/containers/show';
import requestWebCommand, {
  OpenExternalWeb,
} from 'global/utilities/web-command';
import { ClientCompanyRepositoryImpl } from '../data/repositories/client-company-repository';
import useClientCompany from '../hooks/use-client-company';
import ClientCompanyPage from '../pages/ClientCompanyPage';
import { PopupInfoDetailViewData } from '../../../profile/view-data/popup-info-view-data';

const ClientCompanyContainer: VFC = () => {
  const { isOpen, dialogProps, requestShowing, onClose } = useDialog();
  useNavigationBarItemsSetting('データ提供する企業を選ぶ');

  let popupInfo: PopupInfoDetailViewData[] = [];

  const showPopup = (setPopupInfoCount: (count: number) => void): void => {
    const info = popupInfo.pop();
    if (info?.title && info?.description) {
      requestShowing({
        title: info.title,
        description: info.description,
        primaryButton: {
          text: 'OK',
          onClick: () =>
            popupInfo.length === 0
              ? setTimeout(() => {
                  setPopupInfoCount(popupInfo.length);
                  requestShowing();
                }, 100)
              : setTimeout(() => {
                  setPopupInfoCount(popupInfo.length);
                  showPopup(setPopupInfoCount);
                }, 100),
        },
      });
    }
  };

  const {
    fetchResult,
    pageLoaded,
    storageData,
    closeButtonTapped,
    didConsentChanged,
    saveButtonTapped,
    isConsentStateChanged,
    popupInfoCount,
    setPopupInfoCount,
    submitAction,
  } = useClientCompany(
    new ClientCompanyRepositoryImpl(),
    new DataConsentRepositoryImpl(),
    (saveResult) => {
      if (saveResult.isSuccess()) {
        popupInfo = saveResult.value.popupInfo;
        if (popupInfo.length !== 0) {
          showPopup(setPopupInfoCount);
        } else {
          requestShowing();
        }
      }
      if (saveResult.isFailure()) {
        requestShowing();
      }
    },
  );

  if (fetchResult.isSuccess()) {
    return (
      <>
        <DialogPrompt
          when={isConsentStateChanged}
          dialogProps={{
            description:
              'データを編集中です。編集したデータを更新せず終了してよろしいですか？',
            primaryButtonTitle: '編集に戻る',
            destructiveButtonTitle: 'はい',
          }}
        />
        {popupInfoCount === 0 ? (
          <Dialog
            title={submitAction?.title}
            description={submitAction?.description}
            primaryButton={submitAction?.primaryButton ?? { text: 'OK' }}
            isOpen={isOpen}
            onClose={onClose}
          />
        ) : (
          <Dialog
            title={dialogProps?.title}
            description={dialogProps?.description}
            imageSrc="/finc_landing_page/content_2-1.png"
            imageSize="large"
            primaryButton={{
              text: '今すぐチェック！',
              onClick: () => {
                requestWebCommand(
                  new OpenExternalWeb(reveiveFitStatsPointPage),
                );
              },
            }}
            isOpen={isOpen}
            onClose={onClose}
          />
        )}
        <ClientCompanyPage
          clientConmapnyData={fetchResult.value}
          pageLoaded={pageLoaded}
          storageData={storageData}
          closeButtonTapped={closeButtonTapped}
          didConsentChanged={didConsentChanged}
          saveButtonTapped={saveButtonTapped}
        />
      </>
    );
  }

  if (fetchResult.isFailure()) {
    return <ErrorContainer>{fetchResult.error.message}</ErrorContainer>;
  }

  return <Loading />;
};

export default ClientCompanyContainer;
