import { VFC } from 'react';
import { DialogUsedSlide } from '../../../global/components/dialog-in-slide-show/DialogInSlideShow';
import OneTimeDialogInSlideShow from '../../../global/one-time-dialog-in-slide-show/OneTimeDialogInSlideShow';

const page1: DialogUsedSlide = {
  kind: 'common',
  imageSrc: '/tutorial/programtutorial_1@3x.png',
  title: '「FitStatsプログラム」とは',
  description:
    '「FitStatsプログラム」とは、ライフスタッツの改善を目的として作成された専門家監修による健康プログラムです。',
};

const page2: DialogUsedSlide = {
  kind: 'common',
  imageSrc: '/tutorial/programtutorial_2@3x.png',
  title: 'プログラムに参加しよう！',
  description:
    '健康増進につながるおすすめの行動を定期的にお届けします。アクションを達成してライフスタッツをUPさせましょう。',
};

const page3: DialogUsedSlide = {
  kind: 'common',
  imageSrc: '/tutorial/programtutorial_3@3x.png',
  title: 'プログラムへの参加方法',
  description:
    'プログラムTOPの「参加可能なプログラム」に配信されているプログラムをタップし、「参加する」ボタンから参加できます。',
};

const ProgramTutorialDialog: VFC = () => (
  <OneTimeDialogInSlideShow
    tutorialKeyName="program"
    slideList={[page1, page2, page3]}
    completedButtonText="始める"
  />
);

export default ProgramTutorialDialog;
