import { useState, useCallback, VFC } from 'react';
import TopPage from 'features/top/pages/TopPage';
import useUserLifelogRecords from 'features/top/hooks/use-user-lifelog-records';
import useTutorialNavigation, {
  TutorialLocationState,
} from 'features/tutorial/hooks/use-tutorial-navigation';
import useDialogInSlideShow from 'global/components/dialog-in-slide-show/use-dialog-in-slide-show';
import DialogInSlideShow, {
  DialogUsedSlide,
} from 'global/components/dialog-in-slide-show/DialogInSlideShow';
import { useLocation } from 'react-router';
import useTop from 'features/top/hooks/use-top';
import CoarchMark, {
  popOverPosition,
} from 'global/components/CoarchMark/CoarchMark';
import useDialog from 'global/components/dialog/use-dialog';
import useNavigationBarItemsSetting from 'global/hooks/use-navigation-bar-items-setting';
import globalNavigationStyle from 'global/components/global-navigation/GlobalNavigation.module.css';
import TutorialOfferRepository from 'features/offer/data/repositories/tutorial-offer-repository';
import { TopDailyScoresRepositoryImpl } from '../data/repositories/top-daily-scores-repository';
import { TopScoresRepositoryImpl } from '../data/repositories/top-scores-repository';

const TutorialTopContainer: VFC = () => {
  useNavigationBarItemsSetting(
    { type: 'logo' },
    {
      isCloseButtonVisible: false,
      isMenuButtonVisible: false,
      isNotificationButtonVisible: false,
      isBackButtonVisible: false,
    },
  );

  useUserLifelogRecords();

  const { navigate } = useTutorialNavigation();
  const { onClose } = useDialog();
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);

  const {
    isOpen: isSlideShowOpen,
    currentPage,
    button,
    requestShowing: requestSlideShowShowing,
    requestSlidePaging,
    requestButtonChanging,
    onClose: closeSlideShow,
  } = useDialogInSlideShow();

  const openTutorialDialog = useCallback(() => {
    requestSlideShowShowing({
      text: '次へ',
      onClick: (page) => {
        requestSlidePaging(page + 1);
      },
    });
  }, [requestSlideShowShowing, requestSlidePaging]);

  const {
    scoresFetchResult,
    dailyScoresFetchResult,
    dailyScoresNextButtonTapped,
    dailyScoresPrevButtonTapped,
    offersFetchResult,
    announcementBannersFetchResult,
    advertisementBannersFetchResult,
    advertisementBannerTapped,
  } = useTop(
    new TopScoresRepositoryImpl(),
    new TopDailyScoresRepositoryImpl(),
    new TutorialOfferRepository(),
  );

  const slide1: DialogUsedSlide = {
    kind: 'common',
    imageSrc: '/tutorial/tutorial_modal01.png',
    title: 'FiNCへのきろくでスコアUP',
    description:
      'FiNCにライフログデータを記録してみましょう。\n記録した内容は、FitStatsのスコアに反映されます。',
  };

  const slide2: DialogUsedSlide = {
    kind: 'common',
    imageSrc: '/tutorial/tutorial_modal02.png',
    title: 'プログラムに挑戦してスコアUP',
    description:
      '楽しみながら健康習慣を身につけられる\nさまざまなプログラムを定期的に配信。\n表示されたアクションを達成するとスコアがUPします。',
  };

  const slide3: DialogUsedSlide = {
    kind: 'common',
    imageSrc: '/tutorial/tutorial_modal03.png',
    title: '「セルフチェック」でスコアUP',
    description:
      '「食事」「運動」「睡眠」「カラダ」「メンタル」それぞれに関する質問に答えると、その回答がスコアに反映されます。定期的な更新を心がけましょう。',
  };

  const elements = [slide1, slide2, slide3];

  const { state } = useLocation<TutorialLocationState | undefined>();

  const globalNavigationContainer = document.getElementsByClassName(
    globalNavigationStyle.globalNavigation,
  )[0];
  if (globalNavigationContainer) {
    globalNavigationContainer.classList.add(
      globalNavigationStyle.tutorial_top_mode,
    );
  }

  const PopOverValue = {
    children: (
      <div>
        パーソナルデータを登録することで、あなたに合ったサービスや商品の案内やお得なクーポンなどの「オファー」を企業から受け取ることができます。
      </div>
    ),
    buttonText: 'オファーをチェック',
    clickOption: {
      clickCustomAction: () => {
        // ハイライト用のスタイルを解除
        globalNavigationContainer.classList.remove(
          globalNavigationStyle.tutorial_top_mode,
        );
        globalNavigationContainer.classList.remove(globalNavigationStyle.extra);
        navigate('/offers');
      },
    },
    position: 'top' as popOverPosition,
    floating: true,
  };

  const voidFunction: (code: string) => () => void = () =>
    // eslint-disable-next-line @typescript-eslint/no-empty-function
     () => {}


  return (
    <>
      <DialogInSlideShow
        isOpen={isSlideShowOpen}
        slideElements={elements}
        selectedPage={currentPage}
        buttonText={button.text}
        onClose={() => {
          // チュートリアル中なので、閉じない
        }}
        onButtonClicked={() => button.onClick(currentPage)}
        onPageChanged={(page) => {
          const highlightedElements = document.getElementsByClassName(
            globalNavigationStyle.highlighted,
          );
          // グロナビハイライトのクリア
          for (let i = 0; i < highlightedElements.length; i += 1) {
            highlightedElements[i].classList.remove(
              globalNavigationStyle.highlighted,
            );
          }
          if (page === 1 || page === 2) {
            // グロナビのプログラムをハイライト
            let menu;
            if (page === 1) {
              menu = document.getElementById('global_navigation_program');
            }
            if (menu) {
              menu.classList.add(globalNavigationStyle.highlighted);
            }
          }
          if (page >= elements.length - 1) {
            requestButtonChanging({
              text: 'OK',
              onClick: () => {
                // グロナビハイライトのクリア
                for (let i = 0; i < highlightedElements.length; i += 1) {
                  highlightedElements[i].classList.remove(
                    globalNavigationStyle.highlighted,
                  );
                }
                if (globalNavigationContainer) {
                  // バルーンチップ表示用のスタイルあて
                  globalNavigationContainer.classList.add(
                    globalNavigationStyle.extra,
                  );
                }
                onClose();
                closeSlideShow();
                // 吹き出し表示する
                setIsTooltipVisible(true);
              },
            });
          }
          if (page < elements.length - 1) {
            requestButtonChanging({
              text: '次へ',
              onClick: () => {
                requestSlidePaging(page + 1);
              },
            });
          }
          if (page !== currentPage) {
            requestSlidePaging(page);
          }
        }}
      />
      <TopPage
        scoresFetchResult={scoresFetchResult}
        dailyScoresFetchResult={dailyScoresFetchResult}
        dailyScoresNextButtonTapped={dailyScoresNextButtonTapped}
        dailyScoresPrevButtonTapped={dailyScoresPrevButtonTapped}
        didLoad={openTutorialDialog}
        locationState={state}
        offersFetchResult={offersFetchResult}
        entryCampaigns={null}
        entryCampaignBannerTapped={voidFunction}
        announcementBannersFetchResult={announcementBannersFetchResult}
        advertisementBannersFetchResult={advertisementBannersFetchResult}
        advertisementBannerTapped={advertisementBannerTapped}
      />
      {isTooltipVisible && (
        <CoarchMark
          elementId="global_navigation_offer"
          popOverOption={PopOverValue}
        />
      )}
    </>
  );
};

export default TutorialTopContainer;
