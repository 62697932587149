import { useState, useRef, VFC } from 'react';
import { Divider } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';

import AttentionContainer from 'global/components/AttentionContainer/AttentionContainer';
import SquareCheckboxWithLabel from 'global/components/square-checkbox-with-label/SquareCheckboxWithLabel';
import BottomContainer from 'global/components/BottomContainer/BottomContainer';
import Button from 'global/components/button/Button';
import Container from 'global/components/Container/Container';

import {
  defaultParentalConsentStatus,
  isNeedParentalConsent,
} from 'features/profile/utils/function-for-profile-view-data';
import { ProfileSinglePageViewData } from '../../../profile/view-data/profile-view-data';

import { UserInputRawData } from '../../../profile/user-input-item';
import ProfileNew from '../components/ProfileNew';

import styles from './ProfilePage.module.css';

type Props = {
  inputProfileItems: ProfileSinglePageViewData;
  submitted: (userInput: UserInputRawData) => void;
};

const ProfileNewPage: VFC<Props> = ({
  inputProfileItems,
  submitted,
}) => {
  const {
    register,
    watch,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    setError,
    clearErrors,
  } = useForm<UserInputRawData>({
    mode: 'onBlur',
    defaultValues: inputProfileItems.userInputItems?.reduce(
      (obj, item) => ({ ...obj, [item.name]: item.value }),
      {},
    ),
  });

  const pageTopRef = useRef<HTMLDivElement>(null);

  const [currentAge, setAge] = useState(0);

  const ageChanged = (age: number): void => {
    setAge(age);
  };

  const [hasParentalConsent, setParentalConsent] = useState(
    defaultParentalConsentStatus(inputProfileItems.userInputItems),
  );

  const isBirthdayValid = (): boolean => {
    if (watch('year') && watch('month') && watch('day') && !errors.birthday) {
      return true;
    }

    return false;
  };

  return(
    <>
      <div className={styles.profilePageWrapper} ref={pageTopRef}>
        <form>
          <Container marginTop="m" marginBottom="m">
            <div className={styles.profilePageDescription}>
              FitStatsは大日本印刷が運営する健康サービス(無料)です。
              <br />
              FitStatsのご利用には会員登録が必要です。
              <br />
              <br />
              あなたについて教えてください。
              <br />
              登録されたプロフィールは無断で
              <br />
              企業に公開されることはありません。
              <br />
              <span className={styles.profilePageDescriptionWarning}>
                このページは全て必須項目です。
              </span>
            </div>
          </Container>
          <ProfileNew
            inputProfileItems={inputProfileItems}
            register={register}
            errors={errors}
            watch={watch}
            ageChanged={ageChanged}
            setValue={setValue}
            setError={setError}
            clearErrors={clearErrors}
          />
          {isBirthdayValid() && isNeedParentalConsent(currentAge) && (
            <Container marginTop="m" marginBottom="m">
              <AttentionContainer>
                <Container marginTop={10} marginBottom={10}>
                  <Container marginLeft={32}>
                    <div className={styles.profilePageAttentionDescription}>
                      FitStatsでは、あなたの個人情報を取り扱うため、
                      <br />
                      16歳未満の方は保護者の同意が必要になります
                    </div>
                  </Container>
                  <Container marginTop={6}>
                    <SquareCheckboxWithLabel
                      name="parental_consent"
                      value="consented"
                      label="保護者の同意を得ている"
                      checked={hasParentalConsent}
                      onClick={() => setParentalConsent(!hasParentalConsent)}
                    />
                  </Container>
                </Container>
              </AttentionContainer>
            </Container>
          )}
        </form>
      </div>
      <BottomContainer>
        <Divider orientation="horizontal" />
        <Container
          marginTop={50}
          marginBottom={50}
          marginLeft="s"
          marginRight="s"
        >
          <Button
            text="登録する"
            type="primary"
            size="large"
            disabled={
              !isValid ||
              (isNeedParentalConsent(currentAge)
                ? !hasParentalConsent
                : false)
            }
            onClick={handleSubmit(submitted)}
          />
        </Container>
      </BottomContainer>
    </>
  );
};

export default ProfileNewPage;
