import { VFC } from 'react';
import { useHistory } from 'react-router-dom';
import QuestionSection from 'global/components/QuestionSection/QuestionSection';
import Container from 'global/components/Container/Container';
import styles from './QuestionsList.module.css';
import {
  QuestionViewData,
  PathViewData,
} from '../view-data/questions-list-view-data';

type Props = { question: QuestionViewData };

const QuestionsList: VFC<Props> = ({ question }) => {
  const history = useHistory();

  const moveToQuestionaire = (path: PathViewData): void => {
    switch (path.type) {
      case 'personal_info':
        history.push('/mydata/personal/edit', { anchor: path.ref });
        break;
      case 'questionnaire':
        history.push(`/questionnaires/categories/${path.ref}`);
        break;
      case 'client_company':
        history.push('/mydata/client_company');
        break;
      case 'personal_data':
        history.push('/mydata/personal');
        break;
      default:
        break;
    }
  };

  const buttonText = (path: PathViewData): string => {
    switch (path.type) {
      case 'personal_info':
      case 'questionnaire':
        return '回答';
      case 'client_company':
      case 'personal_data':
        return '提供';
      default:
        return '回答';
    }
  };

  return (
    <div>
      <div className={styles.questionTitle}>{question.name}</div>
      {question.questions.map((q) => (
        <Container marginTop={12} marginBottom={12}>
          <QuestionSection
            key={q.id}
            imgSrc={`/campaign/campaign_${q.iconName}.svg`}
            title={q.name}
            text={buttonText(q.path)}
            isAchieve={q.isAchieve}
            buttonTappedAction={() => moveToQuestionaire(q.path)}
          />
        </Container>
      ))}
    </div>
  );
};
export default QuestionsList;
