import PhoneNumberRegistrationRepository, {
  PhoneNumberRegistrationRepositoryImpl,
} from 'features/sign-up/phone-number/registration/data/repositories/phone-number-registration-repository';
import { isErrorDTO } from 'data/dto/error-dto';
import Result, { Failure, Success } from 'global/utilities/result';
import { useRef } from 'react';

type ReturnType = {
  submitErrorMessage?: string;
  submitted: (phoneNumber: string) => void;
};

const usePhoneNumberRegistration = (
  repository: PhoneNumberRegistrationRepository = new PhoneNumberRegistrationRepositoryImpl(),
  saveResult: (result: Result<string, void>) => void,
): ReturnType => {
  const submitErrorMessage = useRef<string | undefined>(undefined);

  const submitted = async (phoneNumber: string): Promise<void> => {
    try {
      await repository.save(phoneNumber);
      saveResult(new Success(phoneNumber));
    } catch (error) {
      submitErrorMessage.current = isErrorDTO(error)
        ? error.error.message
        : '電話番号の登録に失敗しました';
      saveResult(new Failure(undefined));
    }
  };

  return {
    submitErrorMessage: submitErrorMessage.current,
    submitted,
  };
};

export default usePhoneNumberRegistration;
