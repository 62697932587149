/* eslint-disable react/jsx-props-no-spreading */
import { VFC } from 'react';
import { useHistory } from 'react-router';
import Button from 'global/components/button/Button';
import styles from './WelcomeFirstPage.module.css';

const WelcomeFirstPage: VFC = () => {
  const imageUrl = `${process.env.PUBLIC_URL}/welcome/bg_welcome1.png`;
  const history = useHistory();

  return (
    <>
      <div
        className={styles.imageArea}
        style={{ backgroundImage: `url(${imageUrl})`}}
      />
      <div className={styles.buttonArea}>
        <div className={styles.buttonContainer}>
          <Button
            text='はじめる'
            type='default'
            size='large'
            onClick={() => { history.push('/sign_up/welcome/second'); }}
          />
        </div>
      </div>
    </>
  );
};

export default WelcomeFirstPage;
