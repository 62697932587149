import ApiClient from 'data/api-client/api-client';
import AuthorizationDto from 'features/sign-up/privacy-policy/data/dto/authorization-dto';
import RequestConfig, { URL } from 'data/request-configs/request-config';

const createAuthorizationRequestConfig = (): RequestConfig => ({
  endpoint: { baseUrl: URL.FIT_STATS, path: '/finc_users/authorize2' },
  method: 'post',
  parameters: {},
});

class AuthorizationRepository {
  authorize = async (): Promise<AuthorizationDto> =>
    new ApiClient().connect(createAuthorizationRequestConfig());
}

export default AuthorizationRepository;
