import { VFC } from 'react';
import { GLOBAL_NAVIGATION_HEIGHT_PX } from 'global/constants';
import style from './GlobalNavigation.module.css';
import GlobalNavigationItem, {
  GlobalNavigationBadgeState,
  GlobalNavigationItemType,
} from './global-navigation-item';

type Props = {
  navigationItems: GlobalNavigationItem[];
  badgeStates: GlobalNavigationBadgeState[];
  selectedItemType?: GlobalNavigationItemType;
  onClick: (navigationItem: GlobalNavigationItem) => void;
  isTapEnabled: boolean;
};

const GlobalNavigation: VFC<Props> = ({
  navigationItems,
  badgeStates,
  selectedItemType,
  onClick,
  isTapEnabled,
}) => {
  const iconClassName = (type: GlobalNavigationItemType): string => {
    switch (type) {
      case 'home':
        return style.home;
      case 'offer':
        return style.offer;
      case 'program':
        return style.program;
      case 'mydata':
        return style.mydata;
      case 'point':
        return style.point;
      default:
        return '';
    }
  };

  return (
    <div
      className={style.globalNavigation}
      style={{ height: `${GLOBAL_NAVIGATION_HEIGHT_PX}px` }}
    >
      <div
        className={style.globalNavigationOverlay}
        style={{ height: `${GLOBAL_NAVIGATION_HEIGHT_PX}px` }}
      />
      {navigationItems.map((item) => {
        const badgeNumber = badgeStates.find(
          (bs) => bs.type === item.type,
        )?.badgeNumber;

        return (
          <button
            type="button"
            className={[
              style.navigationItem,
              item.type === selectedItemType ? style.itemSelected : '',
            ].join(' ')}
            id={`global_navigation_${item.type}`}
            onClick={() => {
              if (item.type !== selectedItemType) {
                onClick(item);
              }
            }}
            key={item.type}
            disabled={!isTapEnabled}
          >
            <div className={[style.icon, iconClassName(item.type)].join(' ')} />
            <div className={style.title}>{item.title}</div>
            {badgeNumber != null && badgeNumber !== 0 && (
              <div
                className={[
                  style.batch,
                  badgeNumber >= 100 ? style.largeNumber : '',
                ].join(' ')}
              >
                {badgeNumber >= 100 ? '99+' : badgeNumber}
              </div>
            )}
          </button>
        );
      })}
    </div>
  );
};

export default GlobalNavigation;
