import {
  Divider,
  Flex,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from '@chakra-ui/react';
import BottomContainer from 'global/components/BottomContainer/BottomContainer';
import Button from 'global/components/button/Button';
import Container from 'global/components/Container/Container';
import Dialog from 'global/components/dialog/Dialog';
import useDialog from 'global/components/dialog/use-dialog';
import NoticeContainer from 'global/components/NoticeContainer/NoticeContainer';
import ToggleSwitch from 'global/components/toggle-switch/ToggleSwitch';
import {
  createRef,
  RefObject,
  useEffect,
  useRef,
  useCallback,
  VFC,
} from 'react';
import { TutorialLocationState } from 'features/tutorial/hooks/use-tutorial-navigation';
import { useLocation } from 'react-router-dom';
import SquareCheckboxWithLabel from 'global/components/square-checkbox-with-label/SquareCheckboxWithLabel';
import PersonalDataViewData, {
  ConsentElement,
  StorageViewData,
} from '../view-data/personal-page-view-data';
import styles from './PersonalData.module.css';

type SectionReference = {
  identifier: string;
  ref: RefObject<HTMLDivElement>;
};

type Props = {
  personalData: PersonalDataViewData;
  storageData: StorageViewData;
  closeButtonTapped: () => void;
  didConsentChanged: (element: ConsentElement) => void;
  saveButtonTapped: () => void;
  locationState?: TutorialLocationState | undefined;
};

type TargetItemParams = {
  anchor: string;
};

const PersonalDataPage: VFC<Props> = ({
  personalData,
  storageData,
  closeButtonTapped,
  didConsentChanged,
  saveButtonTapped,
  locationState,
}) => {
  const { isOpen, requestShowing, onClose } = useDialog();

  const sectionRefs = personalData.sections.map(
    (section): SectionReference => ({
      identifier: section.title,
      ref: createRef<HTMLDivElement>(),
    }),
  );

  const scrollToSection = (identifier: string) => {
    const ref = sectionRefs.find(
      (sectionRef) => sectionRef.identifier === identifier,
    );
    if (ref?.ref.current == null) {
      return;
    }
    ref.ref.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  };

  const AfterTransExecFlag = useRef(true);
  const targetItemParams = useLocation<TargetItemParams>();
  useEffect(() => {
    if (targetItemParams.state?.anchor && AfterTransExecFlag.current === true) {
      scrollToSection(targetItemParams.state.anchor);
    }
    AfterTransExecFlag.current = false;
  });

  const pageTopRef = useRef<HTMLDivElement>(null);
  const scrollToTop = useCallback(() => {
    pageTopRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  }, []);

  const valueForDisplay = (value: string | undefined): string => {
    if (value == null) {
      return '';
    }

    return value.length > 0 ? value : '未登録';
  };

  const toggleSwitchOnOffText = { onText: '提供', offText: '拒否' };

  return (
    <>
      <div className={styles.personalDataContainer} ref={pageTopRef}>
        {!locationState?.isTutorial &&
          storageData.isCloseButtonTapped !== true && (
            <Container marginBottom="s" marginLeft="s" marginRight="s">
              <NoticeContainer closeButtonCustomAction={closeButtonTapped}>
                登録したデータのうち、「提供」を選択した項目のみが企業に提供されます。
                <br />
                提供されたデータが多いほど、あなたによりマッチした情報が企業から届きます。
              </NoticeContainer>
            </Container>
          )}
        {locationState?.isTutorial ? (
          <div id="tutorial-mydata-personal">
            <Container marginTop="s" marginBottom="s">
              <div
                className={[
                  styles.sectionWrapper,
                  styles.sectionWrapperHighlight,
                ].join(' ')}
              >
                <div
                  id="all-items"
                  className={[
                    styles.sectionTitle,
                    styles.sectionTitleHighlight,
                  ].join(' ')}
                >
                  <Flex alignItems="center" justifyContent="space-between">
                    推奨：登録したデータを全て提供選択する
                    <ToggleSwitch
                      onOffText={toggleSwitchOnOffText}
                      isOn={personalData.sections.every((section) =>
                        section.items.every((item) => item.consent),
                      )}
                      switchSize="large"
                      onClick={(isChecked) =>
                        didConsentChanged({
                          type: 'all',
                          title: 'all-items',
                          isChecked,
                        })
                      }
                    />
                  </Flex>
                </div>
              </div>
            </Container>
          </div>
        ) : (
          <div id="mydata-personal">
            <Container
              marginTop="s"
              marginBottom="s"
              marginLeft="s"
              marginRight="s"
            >
              <div className={styles.checkboxWrapper}>
                <div id="all-items">
                  <Flex alignItems="center" justifyContent="center">
                    <SquareCheckboxWithLabel
                      name="all_items_consent"
                      value="consented"
                      label=""
                      checked={personalData.sections.every((section) =>
                        section.items.every((item) => item.consent),
                      )}
                      onClick={() =>
                        didConsentChanged({
                          type: 'all',
                          title: 'all-items',
                          isChecked: !personalData.sections.every((section) =>
                            section.items.every((item) => item.consent),
                          ),
                        })
                      }
                    />
                    <div className={styles.checkboxLabel}>
                      全てのデータ項目を選択
                    </div>
                  </Flex>
                </div>
              </div>
            </Container>
          </div>
        )}

        <Accordion
          allowMultiple
          defaultIndex={
            locationState?.isTutorial
              ? [...Array(personalData.sections.length).keys()]
              : undefined
          }
        >
          {personalData.sections.map((section) => (
            <>
              <AccordionItem>
                {({ isExpanded }) => (
                  <>
                    {section.items.length > 1 && (
                      <div className={styles.sectionWrapper}>
                        <div
                          id={section.title}
                          className={styles.sectionTitle}
                          ref={
                            sectionRefs.find(
                              (sectionRef) =>
                                sectionRef.identifier === section.title,
                            )?.ref
                          }
                        >
                          <Flex
                            alignItems="center"
                            justifyContent="space-between"
                          >
                            <div className={styles.accordionButtonWrapper}>
                              <AccordionButton
                                _hover={{ background: 'none' }}
                                _focus={{ boxShadow: 'none' }}
                                padding={0}
                              >
                                <AccordionIcon
                                  color="var(--color-base-dark-sub)"
                                  fontSize="1.75em"
                                />
                                <div className={styles.accordionText}>
                                  {section.title}
                                </div>
                              </AccordionButton>
                            </div>
                            <Flex
                              alignItems="center"
                              justifyContent="space-between"
                            >
                              <div className={styles.toggleSwitchText}>
                                一括選択
                              </div>
                              <ToggleSwitch
                                onOffText={toggleSwitchOnOffText}
                                isOn={section.items.every(
                                  (item) => item.consent,
                                )}
                                switchSize="large"
                                onClick={(isChecked) =>
                                  didConsentChanged({
                                    type: 'section',
                                    title: section.title,
                                    isChecked,
                                  })
                                }
                              />
                            </Flex>
                          </Flex>
                        </div>
                      </div>
                    )}
                    {section.items.map((item) => {
                      const titleAndValue =
                        // データ編集の対象でない「総合スタッツ」などの項目は値の表示領域をタイトルの表示に使うことで折返しを防ぐ
                        // これらの項目はvalueがundefinedで、編集対象だが未入力の項目は''なので、これを利用して判別する
                        typeof item.value === 'string' ? (
                          <>
                            <div className={styles.itemTitle}>{item.title}</div>
                            <div
                              className={[
                                styles.item,
                                item.value != null &&
                                  item.value.length === 0 &&
                                  styles.noItem,
                              ].join(' ')}
                            >
                              {valueForDisplay(item.value)}
                            </div>
                          </>
                        ) : (
                          <>
                            <div
                              className={
                                section.items.length > 1
                                  ? styles.itemTitleLong
                                  : ''
                              }
                            >
                              {item.title}
                            </div>
                          </>
                        );

                      return (
                        <>
                          {section.items.length > 1 ? (
                            <AccordionPanel padding={0}>
                              <div className={styles.itemWrapper}>
                                <Flex
                                  alignItems="center"
                                  justifyContent="space-between"
                                >
                                  {titleAndValue}
                                  <ToggleSwitch
                                    onOffText={toggleSwitchOnOffText}
                                    isOn={item.consent}
                                    onClick={(isChecked) =>
                                      didConsentChanged({
                                        type: 'item',
                                        title: item.title,
                                        isChecked,
                                      })
                                    }
                                  />
                                </Flex>
                              </div>
                            </AccordionPanel>
                          ) : (
                            <div className={styles.sectionWrapper}>
                              <div
                                id={item.title}
                                className={styles.sectionTitle}
                                ref={
                                  sectionRefs.find(
                                    (sectionRef) =>
                                      sectionRef.identifier === section.title,
                                  )?.ref
                                }
                              >
                                <Flex
                                  alignItems="center"
                                  justifyContent="space-between"
                                >
                                  {titleAndValue}
                                  <ToggleSwitch
                                    onOffText={toggleSwitchOnOffText}
                                    isOn={item.consent}
                                    switchSize="large"
                                    onClick={(isChecked) =>
                                      didConsentChanged({
                                        type: 'item',
                                        title: item.title,
                                        isChecked,
                                      })
                                    }
                                  />
                                </Flex>
                              </div>
                            </div>
                          )}
                        </>
                      );
                    })}
                    {
                      // アコーディオンメニュー内部の下線と2重にならないよう閉じているときだけ表示
                      !isExpanded && (
                        <div className={styles.accordionItemBorder} />
                      )
                    }
                  </>
                )}
              </AccordionItem>
            </>
          ))}
        </Accordion>
        <button
          type="button"
          className={styles.backToTopButton}
          onClick={scrollToTop}
        >
          <img src="/mydata/back_to_top_button.svg" alt="TOP" />
        </button>
        <BottomContainer>
          <Divider orientation="horizontal" marginTop={0} />
          <Container
            marginTop="s"
            marginBottom="s"
            marginLeft="s"
            marginRight="s"
          >
            <Button
              text={
                locationState?.isTutorial
                  ? '提供するデータを確定する'
                  : '更新する'
              }
              type="primary"
              size="large"
              onClick={requestShowing}
            />
          </Container>
        </BottomContainer>
      </div>
      <Dialog
        title="確認画面"
        description={
          locationState?.isTutorial
            ? '提供するデータの選択が完了しました。次に、選んだデータを提供する企業を設定しましょう。'
            : "あなたが選んだ企業に\n提供されるデータ項目を\n更新します。"
        }
        primaryButton={{
          text: locationState?.isTutorial ? '次へ' : '同意する',
          onClick: saveButtonTapped,
        }}
        isOpen={isOpen}
        onClose={onClose}
      />
    </>
  );
};

export default PersonalDataPage;
